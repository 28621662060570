import moment, { Moment, unitOfTime } from "moment";

import { ReportTimeRange, ReportPredefinedTimeRange } from "models/Report";

import { UseReportReturn } from "../useReport";

interface UseFiltersReturn {
    handleTimeRangeChange: (args: Partial<ReportTimeRange>) => void
}

export function useFilters({ onTimeRangeChange, timeRange }: Pick<UseReportReturn, "onTimeRangeChange" | "timeRange">): UseFiltersReturn {
    function handleTimeRangeChange({ from, isCustom, predefinedTimeRangeType, to }: Partial<ReportTimeRange>): void {
        // on isCustom change
        if (typeof isCustom !== "undefined") {
            if (isCustom) {
                return onTimeRangeChange({
                    // set init customRange based on predefined range
                    ...!timeRange.customRange.from && !timeRange.customRange.to ? { customRange: { from: timeRange.from, to: timeRange.to } } : {},
                    isCustom,
                });
            }
            return handleSetPredefinedTimeRange(timeRange.predefinedTimeRangeType);
        }

        if (timeRange.isCustom) {
            return onTimeRangeChange({ customRange: { from: from ?? null, to: to ?? null } });
        }

        if (typeof predefinedTimeRangeType !== "undefined") {
            handleSetPredefinedTimeRange(predefinedTimeRangeType);
        }
    }

    function handleSetPredefinedTimeRange(type: ReportPredefinedTimeRange): void {
        if (type === ReportPredefinedTimeRange.NONE) {
            return onTimeRangeChange({ from: null, isCustom: false, predefinedTimeRangeType: type, to: null });
        }
        const unit = getUnitByPredefinedType(type);
        onTimeRangeChange({ from: getDateByPredefinedRange("start", unit), isCustom: false, predefinedTimeRangeType: type, to: getDateByPredefinedRange("end", unit) });
    }

    function getUnitByPredefinedType(type: ReportPredefinedTimeRange): unitOfTime.DurationConstructor {
        switch (type) {
            case ReportPredefinedTimeRange.PREV_WEEK:
                return "week";

            case ReportPredefinedTimeRange.PREV_MONTH:
                return "month";

            default:
                return "week";
        }
    }

    function getDateByPredefinedRange(type: "start" | "end", unit: unitOfTime.DurationConstructor): Moment {
        return moment().startOf("day").subtract(1, unit)[type === "start" ? "startOf" : "endOf"](unit);
    }

    return { handleTimeRangeChange };
}