import * as yup from "yup";

import { ReservationInput } from "models/Reservation";

export const reservationFormValidationSchema: yup.ObjectSchema<ReservationInput> = yup.object({
    amountOfPeople: yup.number().required("common.required").typeError("common.required"),
    end: yup.string().required("common.required").test("", "", function(value) {
        if (!value) {
            return this.createError({ message: "common.required", path: "start" });
        }
        return true;
    }),
    inGarden: yup.bool().required(),
    instagram: yup.string().nullable().trim(),
    isConfirmed: yup.bool().required(),
    isFirstTime: yup.bool().required(),
    isRegularGuest: yup.bool().required(),
    message: yup.string().nullable().trim(),
    name: yup.string().nullable().trim(),
    phone: yup.string().nullable().trim(),
    start: yup.string().required("common.required"),
    tables: yup.array().of(yup.string().required()).required(),
    tablesNums: yup.string().required("common.required"),
    thisTable: yup.bool().required(),
}).test("", "", function(value) {
    if (!value.name && !value.instagram) {
        return this.createError({ message: "modules.reservations.requiredOrInstagram", path: "name" });
    }
    return true;
});