import moment, { Moment, utc } from "moment";

import { ValuesForHourPicker } from "models/Date";

export function getInitStartDate(): Moment {
    if (moment().isBefore(moment().set({ hour: 17 }))) {
        return moment().set({ hour: 17, minute: 0 }).second(0);
    }
    if (moment().minute() >= 30) {
        return moment().minute(30).second(0);
    }
    return moment().minute(0).second(0);
}

export function generateHoursForPicker(start: Moment, amount = 48): ValuesForHourPicker {
    const startOfDay = start.clone().startOf("day");
    return Array(amount).fill(null).map((_, i) => {
        const date = startOfDay.clone().add(i * 30, "minutes");
        return {
            date,
            disabled: false,
            formatted: date.format("HH:mm"),
        };
    }).filter(({ date }) => date.isAfter(startOfDay.clone().set({ hour: 8, minute: 59 })));
}

export function getUtcDateFormatted(date: Moment) {
    return utc(date).format();
}