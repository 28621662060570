import { Box, Typography, capitalize } from "@mui/material";
import { ReactElement } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { TextInput } from "common/components/form/TextInput";
import { AuthFormProps } from "models/Auth";
import { Button } from "ui/components/Button";
import { globalStyles } from "ui/globalStyles";

import * as Styled from "../styles/Auth.styled";

export function PasswordResetForm({ isError, isLoading, isSuccess }: AuthFormProps): ReactElement {
    const { t } = useTranslation();
    const { trigger } = useFormContext();
    const passwordMatchValue = useWatch({ name: "passwordMatch" });

    return (
        <>
            <Styled.AuthContent>
                <TextInput
                    field="password"
                    helperText={t("modules.auth.newPassword")}
                    inputProps={globalStyles.authTextInputProps}
                    inputlabelstyles={globalStyles.authTextInputLabelStyles}
                    label={t("modules.auth.password")}
                    type="password"
                    variant="standard"
                    onChange={() => {
                        if (passwordMatchValue) {
                            trigger("passwordMatch");
                        }
                    }}
                />
                <TextInput
                    field="passwordMatch"
                    helperText={t("modules.auth.repeatNewPassword")}
                    inputProps={globalStyles.authTextInputProps}
                    inputlabelstyles={globalStyles.authTextInputLabelStyles}
                    label={t("modules.auth.repeatedPassword")}
                    sx={{ mt: 1 }}
                    type="password"
                    variant="standard"
                />
            </Styled.AuthContent>

            {isSuccess &&
                <Typography sx={{ color: "success.main" }}>
                    {capitalize(t("modules.auth.changePasswordSuccess"))}!
                </Typography>
            }
            {isError &&
                <Typography sx={{ color: "error.main" }}>
                    {capitalize(t("modules.auth.changePasswordFail"))}
                </Typography>
            }

            <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
                <Button
                    isLoading={isLoading}
                    sx={{ fontWeight: 600, width: "50%", ...globalStyles.buttonBlack }}
                    type="submit"
                >
                    {capitalize(t("modules.auth.changePassword"))}
                </Button>
            </Box>
        </>
    );
}
