import { Box, CircularProgress, Typography, capitalize } from "@mui/material";
import { ReactElement, useContext } from "react";
import { useTranslation } from "react-i18next";

import { getTable } from "assets/tables/tables";
import { globalStyles } from "ui/globalStyles";


import { getReservationsSortedByStartDate } from "../../helpers/reservations";
import { ReservationsContext } from "../Reservations";

import { ReservationsInfo } from "./ReservationsInfo";
import restaurantMap from "./restaurantMap.json";
import { useMap } from "./useMap";

export function Map(): ReactElement {
    const { t } = useTranslation();
    const { reservationsControl: { isErrorDailyMessagePrintable, isErrorTables, tablesState } } = useContext(ReservationsContext);

    const { rooms } = restaurantMap;
    const { onReservationClick, onTableClick } = useMap();

    if (isErrorTables || isErrorDailyMessagePrintable) {
        return (
            <Box sx={{ border: globalStyles.border, display: "flex", flexBasis: "66.7%", height: "100vh", justifyContent: "center", pt: 2 }}>
                <Typography sx={{ color: "error.main" }}>{capitalize(t("common.fetchError"))}
                </Typography>
            </Box>
        );
    }

    const tablesStateForMap = tablesState?.sort((a, b) => a.tableNum - b.tableNum)?.map((tableState, index, arr) => {
        const reservationsFiltered = tableState.reservations.map(reservation => {
            const previousTablesState = arr.slice(0, index);
            const isReservationShownAtPreviousTable = previousTablesState.some(previousTableState => previousTableState.reservations.some(previousReservation => previousReservation.id === reservation.id));
            return { ...reservation, showable: !isReservationShownAtPreviousTable };
        });

        return { ...tableState, reservations: getReservationsSortedByStartDate(reservationsFiltered) };
    });

    return (
        <Box
            sx={{
                "@media print": { borderBottom: globalStyles.border, borderLeft: globalStyles.border, flexBasis: "100%", height: "75vh", mx: 2 },
                display: "flex",
                flexBasis: "66.7%",
                flexWrap: "wrap",
            }}
        >
            {Object.entries(rooms).map(
                (
                    [ _, { areaGridTemplateColumns, areaGridTemplateRows, areas, gridTemplateColumns, gridTemplateRows, height, isBorderBottom, isBorderTop, roomPaddingBottom, roomPaddingTop, title, top, width } ],
                ) =>
                    <Box
                        key={title.name}
                        sx={{
                            borderBottom: isBorderBottom ? globalStyles.border : "none",
                            borderRight: globalStyles.border,
                            borderTop: isBorderTop ? globalStyles.border : "none",
                            boxSizing: "border-box",
                            display: "grid",
                            gridTemplateColumns,
                            gridTemplateRows,
                            height,
                            overflow: "hidden",
                            pb: roomPaddingBottom,
                            position: "relative",
                            pt: roomPaddingTop,
                            top,
                            width,
                        }}
                    >
                        <Typography sx={{ ...title.position, fontSize: 14.5, fontWeight: "bold", position: "absolute", userSelect: "none" }}>
                            {capitalize(title.name)}
                        </Typography>
                        {areas.map((area, index) => {
                            const isBar = title.name === "bar";

                            if (!tablesState) {
                                return <CircularProgress key={index} sx={{ justifySelf: "center" }} />;
                            }

                            return (
                                <Box key={index} sx={{ cursor: "pointer", display: "grid", gridTemplateColumns: areaGridTemplateColumns, gridTemplateRows: areaGridTemplateRows }}>
                                    {Object.values(area).map(({ tableNum, type }, index) => {
                                        const tableState = tablesStateForMap?.find((state) => state.tableNum === tableNum);

                                        if (!tableState) {
                                            return <></>;
                                        }

                                        if (type === "table") {
                                            return (
                                                <Box
                                                    key={index}
                                                    sx={{ "& svg > *": { userSelect: "none" }, ml: 1.4, pb: 1.25 }}
                                                    onClick={() => onTableClick(tableState)}>
                                                    {getTable(tableNum, tableState.status)}
                                                </Box>);
                                        }

                                        return (
                                            <ReservationsInfo
                                                key={index}
                                                ml={1.4}
                                                mr={!isBar ? 1.4 : 0}
                                                reservations={tableState.reservations}
                                                wrapInfo={isBar}
                                                onReservationClick={onReservationClick}
                                            />);
                                    })}
                                </Box>
                            );
                        })}
                    </Box>,
            )}
        </Box>
    );
}