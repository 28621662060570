import { List as ListMui, ListItem, ListItemText, Typography } from "@mui/material";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

import { globalStyles } from "ui/globalStyles";

import { COLORS } from "../../variables";


interface ListProps<T> {
    data?: Array<T>
    headers: Array<string>
    isLoading: boolean
    onClick?: (item: T) => void
    renderItem: (item: T) => ReactElement
}


export function List<T extends {id: string}>({ data, headers, isLoading, onClick, renderItem }: ListProps<T>): ReactElement {
    const { t } = useTranslation();

    return (
        <ListMui
            subheader={
                <ListItem sx={{ "& .MuiTypography-root": { fontSize: 9.6, fontWeight: "bold" }, alignItems: "flex-start" }}>
                    {headers.map((header, index, arr) =>
                        <ListItemText key={header} sx={index === arr.length - 1 ? { textAlign: "right" } : undefined}>
                            <Typography>{t(header).toUpperCase()}</Typography>
                        </ListItemText>,
                    )}
                </ListItem>
            }
            sx={{
                "& .MuiListItem-root": {
                    borderBottom: globalStyles.border,
                    maxHeight: 68,
                    mx: "auto",
                    overflow: "hidden",
                    px: 0,
                    textAlign: "center",
                    width: "calc(100% - 16px)",
                },
                "& .MuiListItem-root .MuiListItemText-root": { display: "flex", justifyContent: "center", maxWidth: `${ 100 / headers.length }%` },
                "& .MuiListItem-root .MuiListItemText-root:first-of-type": { justifyContent: "start" },
                "& .MuiListItem-root .MuiListItemText-root:last-of-type": { justifyContent: "end" },
                "& .MuiTypography-root": { maxWidth: "140px", overflow: "hidden", textAlign: "center", textOverflow: "ellipsis" },
                height: "90%",
                overflow: "scroll",
            }}
        >
            {data?.map((item) =>
                <ListItem key={item.id}
                    sx={{
                        "& .MuiTypography-root": { color: COLORS[isLoading ? "lightGray" : "black"] },
                        alignItems: "flex-start",
                        border: "none !important",
                        cursor: typeof onClick !== "undefined" ? "pointer" : "normal",
                    }}
                    onClick={() => onClick?.(item)}
                >
                    {renderItem(item)}
                </ListItem>)
            }
        </ListMui>
    );
}