import { Box, FormControlLabel, Typography } from "@mui/material";
import moment from "moment";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

import { ReportPredefinedTimeRange } from "models/Report";
import { Checkbox } from "ui/components/Checkbox";
import { DateRangePicker } from "ui/components/date/DateRangePicker";
import { Dropdown } from "ui/components/Dropdown";
import { globalStyles } from "ui/globalStyles";

import { UseReportReturn } from "../useReport";

import { useFilters } from "./useFilters";

export function Filters({ onTimeRangeChange, timeRange }: Pick<UseReportReturn, "onTimeRangeChange" | "timeRange">): ReactElement {
    const { t } = useTranslation();
    const { handleTimeRangeChange } = useFilters({ onTimeRangeChange, timeRange });

    return (
        <Box sx={globalStyles.sidePanelControlWrapper}>
            <Box sx={{ alignItems: "center", display: "flex", justifyContent: "space-between" }}>
                <Typography sx={{ fontWeight: "bold" }} variant="h3">
                    {t("modules.reservations.timeRange").toUpperCase()}
                </Typography>
                <FormControlLabel
                    control={
                        <Checkbox
                            value={timeRange.isCustom}
                            onChange={(isCustom) => handleTimeRangeChange({ isCustom })}
                        />
                    }
                    label={t("modules.reservations.report.timeRange.custom")}
                    sx={{ userSelect: "none" }}
                />
            </Box>
            {!timeRange.isCustom
                ?
                <Dropdown
                    data={[
                        { name: "none", value: ReportPredefinedTimeRange.NONE },
                        { name: "prevWeek", value: ReportPredefinedTimeRange.PREV_WEEK },
                        { name: "prevMonth", value: ReportPredefinedTimeRange.PREV_MONTH },
                    ].map((d) => ({ ...d, name: d.name === "none" ? t("common.hyphen") : t(`modules.reservations.report.timeRange.${ d.name }`) }))}
                    sx={{ my: 1 }}
                    value={timeRange.predefinedTimeRangeType}
                    onChange={(type) => handleTimeRangeChange({ predefinedTimeRangeType: type as ReportPredefinedTimeRange })}
                />
                :
                <DateRangePicker
                    defaultValues={{ from: timeRange.customRange.from ? moment(timeRange.customRange.from) : null, to: timeRange.customRange.to ? moment(timeRange.customRange.to) : null }}
                    sx={{ my: 1 }}
                    onDateRangeChange={handleTimeRangeChange}
                />
            }
        </Box>
    );
}