import { useDebounce } from "@uidotdev/usehooks";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";

import { DEBOUNCE_DEFAULT_VALUE, PAGINATION_LIMIT } from "config/constants";
import { BrowsingAllDayTime, Mode } from "models/Mode";
import { PaginatedResponse } from "models/Repository";
import { Reservation, TimeRange } from "models/Reservation";
import { TableState } from "models/Table";

import * as repository from "../repositories/reservations";

import { UseTablesReturn, useTables } from "./useTables";

interface UseReservationsInterface {
    queryData: TimeRange
}

export interface UseReservationsReturn extends UseTablesReturn {
    browsingAllDayTime: BrowsingAllDayTime
    exitFormWarning: {modeToBeSet: Mode, reservationId?: string, show: boolean, tableState?: Partial<TableState>},
    isErrorReservation: boolean,
    isErrorReservations: boolean,
    isFetchingReservation: boolean,
    isFetchingReservations: boolean,
    isFormDirty: boolean,
    isLoadingTables: boolean,
    mode: Mode,
    onBrowsingAllDayTimeChange: (time: BrowsingAllDayTime) => void
    onExitFormWarningChange: (state: {modeToBeSet?: Mode, reservationId?: string, show: boolean, tableState?: Partial<TableState>}) => void,
    onFormDirtyChange: (state: boolean) => void,
    onModeChange: (mode: Mode) => void,
    onPreviousModeChange: (mode: Mode) => void,
    onReservationIdChange: ({ id, shouldRefetch }: { id?: string, shouldRefetch: boolean }) => void,
    onSearchChange: (type: "page" | "query", value: string | number) => void,
    previousMode?: Mode,
    reservation?: Reservation,
    reservationId?: string,
    reservations?: PaginatedResponse<Reservation>,
    search: { page: number, query: string }
}

export function useReservations({ queryData }: UseReservationsInterface): UseReservationsReturn {
    const [ mode, setMode ] = useState<Mode>(Mode.BROWSING);
    const [ browsingAllDayTime, setBrowsingAllDayTime ] = useState<BrowsingAllDayTime>(BrowsingAllDayTime.MORNING);
    const [ previousMode, setPreviousMode ] = useState<Mode | undefined>();
    const [ reservationId, setReservationId ] = useState<string | undefined>();
    const [ search, setSearch ] = useState<{ page: number, query: string }>({ page: 1, query: "" });
    const [ exitFormWarning, setExitFormWarning ] = useState<{ modeToBeSet: Mode, reservationId?: string, show: boolean, tableState?: Partial<TableState> }>({ modeToBeSet: Mode.BROWSING, show: false });
    const [ isFormDirty, setIsFormDirty ] = useState<boolean>(false);

    const searchQuery = useDebounce(search.query, DEBOUNCE_DEFAULT_VALUE);

    const { data: dataReservation, isError: isErrorReservation, isFetching: isFetchingReservation, refetch: refetchReservation } = useQuery({
        enabled: typeof reservationId !== "undefined",
        queryFn: () => repository.getReservation(reservationId),
        queryKey: [ "reservation", reservationId ],
        refetchOnWindowFocus: false,
    });

    const { data: dataReservations, isError: isErrorReservations, isFetching: isFetchingReservations } = useQuery({
        enabled: mode === Mode.SEARCHING,
        queryFn: () => repository.fetchReservations({ paginate: { limit: PAGINATION_LIMIT, page: search.page }, search: searchQuery }),
        queryKey: [ "reservations", searchQuery, search.page ],
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        if (mode !== Mode.SEARCHING && mode !== Mode.RESERVATION_PREVIEW_FROM_SEARCH && mode !== Mode.PRINTING) {
            onSearchChange("query", "");
        }
        if (mode === Mode.BROWSING) {
            onExitFormWarningChange({ modeToBeSet: Mode.BROWSING, show: false });
        }
    }, [ mode ]);

    useEffect(() => {
        setSearch({ ...search, page: 1 });
    }, [ searchQuery ]);

    const { dailyMessagePrintable, isErrorDailyMessagePrintable, isErrorTables, isErrorTablesTimeRanges, isLoadingTables, onSelectedTablesChange, onTablesStateChange, selectedTableIds, tablesState, tablesTimeRanges } = useTables({ browsingAllDayTime, mode, onModeChange, previousMode, queryData, reservation: dataReservation, reservationId, selectedStartDate: queryData.start });

    function onModeChange(mode: Mode): void {
        setMode(mode);
    }

    function onBrowsingAllDayTimeChange(time: BrowsingAllDayTime): void {
        setBrowsingAllDayTime(time);
    }

    function onSearchChange(type: "page" | "query", value: string | number): void {
        setSearch({ ...search, [type]: value });
    }

    function onReservationIdChange({ id, shouldRefetch }: {id?: string, shouldRefetch: boolean}): void {
        setReservationId(id);
        if (!shouldRefetch) {
            return;
        }
        if (id === reservationId) {
            refetchReservation();
        }
        // dont mark tables as selected in preview when browsing all day or there are no reservations infos in searching/report browsing mode
        if ([ Mode.BROWSING_ALL_DAY, Mode.SEARCHING, Mode.REPORT_BROWSING ].some(m => m === mode)) {
            return;
        }
        onSelectedTablesChange(tablesState?.filter(({ reservations }) => reservations[0]?.id === id).map(({ tableNum }) => tableNum) ?? []);
    }

    function onExitFormWarningChange(state: {modeToBeSet?: Mode, reservationId?: string, show: boolean, tableState?: Partial<TableState>}): void {
        setExitFormWarning({ ...exitFormWarning, ...state });
    }

    function onFormDirtyChange(state: boolean): void {
        setIsFormDirty(state);
    }

    function onPreviousModeChange(mode: Mode): void {
        setPreviousMode(mode);
    }

    return {
        browsingAllDayTime,
        dailyMessagePrintable,
        exitFormWarning,
        isErrorDailyMessagePrintable,
        isErrorReservation,
        isErrorReservations,
        isErrorTables,
        isErrorTablesTimeRanges,
        isFetchingReservation,
        isFetchingReservations,
        isFormDirty,
        isLoadingTables,
        mode,
        onBrowsingAllDayTimeChange,
        onExitFormWarningChange,
        onFormDirtyChange,
        onModeChange,
        onPreviousModeChange,
        onReservationIdChange,
        onSearchChange,
        onSelectedTablesChange,
        onTablesStateChange,
        previousMode,
        reservation: dataReservation,
        reservationId,
        reservations: dataReservations,
        search,
        selectedTableIds,
        tablesState,
        tablesTimeRanges,
    };
}