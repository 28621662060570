export enum Mode {
    BROWSING,
    DAILY_MESSAGE_EDIT,
    RESERVATION_CREATION,
    RESERVATION_PREVIEW,
    RESERVATION_EDIT,
    BROWSING_ALL_DAY,
    SEARCHING,
    REPORT_BROWSING,
    RESERVATION_PREVIEW_FROM_SEARCH,
    PRINTING
}

export enum BrowsingAllDayTime {
    MORNING,
    EVENING,
}