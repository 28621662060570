import { Box, ButtonProps, SxProps, Theme } from "@mui/material";
import { ReactElement } from "react";

import { Button } from "ui/components/Button";

interface ActionButtonsProps {
    buttons: Array<{
        isLoading?: boolean,
        onClick?: () => void;
        sx?: SxProps<Theme>,
        text: string;
        type: ButtonProps["type"]
    }>;
}

export function ActionButtons({ buttons }: ActionButtonsProps): ReactElement {
    return (
        <Box sx={{ display: "flex", height: 60, justifyContent: "space-around", mt: 5.6, width: "100%" }}>
            {buttons.map((button) =>
                <Button key={button.text} {...button} sx={{ width: "45%", ...button.sx, fontWeight: 600 }} >
                    {button.text}
                </Button>,
            )}
        </Box>
    );
}
