import { Checkbox as CheckboxMui, SxProps, Theme, checkboxClasses } from "@mui/material";
import { ReactElement } from "react";
import { ControllerRenderProps, FieldValues } from "react-hook-form";

import { COLORS } from "ui/variables";

export interface CheckboxProps {
    controllerField?: ControllerRenderProps<FieldValues, string>,
    disabled?: boolean,
    onChange?: (state: boolean) => void,
    sx?: SxProps<Theme>;
    value?: boolean
}

export function Checkbox({ controllerField, disabled, onChange, sx, value }: CheckboxProps): ReactElement {
    return (
        <CheckboxMui
            {...controllerField}
            checked={controllerField?.value ?? value ?? false}
            disabled={disabled}
            sx={{
                [`&.${ checkboxClasses.checked }`]: { color: COLORS.selected },
                color: COLORS.black,
                ...sx,
            }}
            onChange={(e) => {
                controllerField?.onChange?.(e.target.checked);
                onChange?.(e.target.checked);
            }}
        />
    );
}