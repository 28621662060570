import { Box, Typography } from "@mui/material";
import { ReactElement, useContext } from "react";

import { Mode } from "models/Mode";
import { Reservation, ReservationIcons } from "models/Reservation";


import { getHoursFormatted } from "../../helpers/format";
import { ReservationsContext } from "../Reservations";


interface ReservationsInfoProps {
    ml: number,
    mr: number,
    onReservationClick: (id: string) => void,
    reservations: Array<Reservation>,
    wrapInfo: boolean
}

export function ReservationsInfo({ ml, mr, onReservationClick, reservations, wrapInfo }: ReservationsInfoProps): ReactElement {
    const { reservationsControl: { mode } } = useContext(ReservationsContext);

    function getFontSize(): number {
        switch (mode) {
            case Mode.BROWSING_ALL_DAY:
                return 9;

            case Mode.PRINTING:
                return 12;
            default:
                return 11.2;
        }
    }

    function getInfo(text: string, fontWeight: string, shouldWrap = false): ReactElement {
        const sxWrap = { WebkitBoxOrient: "vertical", WebkitLineClamp: 2, display: "-webkit-box", fontWeight, overflow: "hidden" };
        const sxNoWrap = { fontWeight, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" };
        const lineHeight = mode === Mode.BROWSING_ALL_DAY ? 1.0 : 1.3;

        return (
            <Typography
                fontSize={getFontSize()}
                sx={{ ...shouldWrap ? sxWrap : sxNoWrap, lineHeight }}
            >
                {text}
            </Typography>
        );
    }

    return (
        <Box sx={{
            "&::-webkit-scrollbar": { display: "none" },
            "@media print": { maxHeight: "unset" },
            maxWidth: "100%",
            ml,
            mr,
            overflow: "scroll",
        }}>
            {reservations.map(({ amountOfPeople, end, id, inGarden, instagram, isFirstTime, isRegularGuest, name, showable, start, thisTable }) => {
                const guestInfo: string = `${ isFirstTime ? `${ ReservationIcons.IS_FIRST_TIME } ` : "" }${ thisTable ? `${ ReservationIcons.THIS_TABLE } ` : "" }${ inGarden ? `${ ReservationIcons.IN_GARDEN } ` : "" }${ isRegularGuest ? `${ ReservationIcons.IS_REGULAR_GUEST } ` : "" }${ name || `@${ instagram }` }`.toUpperCase();
                const reservationInfo: string = `${ getHoursFormatted(start, end) } (${ amountOfPeople })`;

                if (typeof showable !== "undefined" && !showable) {
                    return null;
                }

                return (
                    <Box
                        key={id}
                        sx={{ mb: 0.35 }}
                        onClick={() => onReservationClick(id)}
                    >
                        {getInfo(reservationInfo, "bold", wrapInfo)}
                        {getInfo(guestInfo, "normal", wrapInfo)}
                    </Box>); })}
        </Box>);
}