import { Box, List, ListItemButton, ListItemText } from "@mui/material";
import { ReactElement, useContext } from "react";


import { HandlerType } from "models/Date";
import { globalStyles } from "ui/globalStyles";
import { COLORS } from "ui/variables";

import { generateHoursForPicker } from "../../../helpers/date";
import { ReservationsContext } from "../../Reservations";

export function HourPicker(): ReactElement {
    const { datePanelControl: { onHandlerTypeChange, onSelectedEndDateChange, onSelectedStartDateChange, selectedStartDate } } = useContext(ReservationsContext);

    return (
        <Box sx={{ bgcolor: COLORS.main, height: "60vh", left: "calc(70% - 2px)", position: "absolute", top: 98, width: "calc(30% + 2px)", zIndex: 999 }}>
            <Box sx={{ border: globalStyles.border, maxHeight: "100%", overflow: "scroll" }}>
                <List>
                    {generateHoursForPicker(selectedStartDate)?.map(({ date, disabled, formatted }) =>
                        <ListItemButton
                            key={formatted}
                            disabled={disabled}
                            sx={{ "&:hover": { bgcolor: COLORS.selectedLight } }}
                            onClick={() => {
                                onSelectedStartDateChange(date);
                                onSelectedEndDateChange(date.clone().add(30, "m"));
                                onHandlerTypeChange?.(HandlerType.BUTTONS);
                            }}
                        >
                            <ListItemText primary={formatted} sx={{ "& span": { fontSize: 28.8 }, display: "flex", justifyContent: "center" }} />
                        </ListItemButton>)}
                </List>
            </Box>
        </Box>
    );
}