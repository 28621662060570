import { PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import moment, { Moment } from "moment";
import { ReactElement } from "react";

import { COLORS } from "../../variables";

export function getDayFormatted(args: PickersDayProps<Moment>): ReactElement {
    const formatDate = moment().startOf("day").isAfter(args.day) || !args.day.isSame(new Date(), "month") || [ 0, 1 ].some(day => day === args.day.day());

    return (
        <PickersDay
            {...args}
            sx={{ color: formatDate ? COLORS.selected : undefined }}
        />
    );
}