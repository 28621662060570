import { Box, Typography, capitalize } from "@mui/material";
import { ReactElement, useContext } from "react";
import { useTranslation } from "react-i18next";

import { getHoursFormatted } from "../../helpers/format";
import { ReservationsContext } from "../Reservations";


export function Messages(): ReactElement {
    const { t } = useTranslation();
    const { reservationsControl: { tablesState } } = useContext(ReservationsContext);

    const bookedTablesWithMessages = tablesState?.sort((a, b) => a.tableNum - b.tableNum).map(table => ({ ...table, reservations: table.reservations.filter(({ message }) => message?.length) })).filter(({ reservations }) => reservations.length);

    if (!bookedTablesWithMessages?.length) {
        return <></>;
    }

    return (
        <>
            {bookedTablesWithMessages?.map(({ reservations, tableNum }) =>
                <Box key={tableNum} sx={{ my: 1 }}>
                    <Typography sx={{ fontWeight: "bold" }} variant="h3">{capitalize(t("modules.reservations.table"))} {tableNum}:</Typography>
                    {reservations.map(({ end, id, instagram, message, name, start }) =>
                        <Box key={id} sx={{ my: .5 }}>
                            <Typography>
                                <span style={{ fontWeight: "bold" }}>{capitalize(t("modules.reservations.hours"))}:</span> {getHoursFormatted(start, end)} / <span style={{ fontWeight: "bold" }}>{capitalize(t("modules.reservations.nameAndSurname"))}:</span> {name || `@${ instagram }`} / <span style={{ fontWeight: "bold" }}>{capitalize(t("modules.reservations.comment"))}:</span> {message}
                            </Typography>
                        </Box>,
                    )}
                </Box>,
            )}
        </>
    );
}