import { COLORS } from "./variables";

const BORDER = `2px solid ${ COLORS.black }`;

export const globalMuiStyles = {
    body: {
        "@media print": { "@page": { size: "A3" } },
        backgroundColor: COLORS.main,
        margin: 0,
        overflow: "hidden",
    },
};

export const globalStyles = {
    authTextInputLabelStyles: { fontSize: 19.2, mt: "-10px" },
    authTextInputProps: { style: { fontSize: 19.2 } },
    border: BORDER,
    button: {
        "&:hover": {
            bgcolor: COLORS.selected,
            border: BORDER,
        },
        border: BORDER,
        borderRadius: 10,
        py: 2,
        textTransform: "none",
    },
    buttonBlack: {
        "&:hover": {
            bgcolor: COLORS.selected,
            border: BORDER,
            color: COLORS.black,
            svg: { stroke: COLORS.black },
        },
        ":disabled": { bgcolor: COLORS.lightGray, color: COLORS.black },
        bgcolor: COLORS.black,
        color: COLORS.white,
    },
    buttonControl: { fontSize: 25, fontWeight: "bold", px: 4, py: 1 },
    buttonControlForm: { fontSize: 17.5, fontWeight: "bold", height: 40, py: 0 },
    buttonDate: { "& .MuiTypography-root": { fontWeight: 600 }, borderRadius: 0 },
    buttonWhite: {
        "&:hover": {
            bgcolor: COLORS.selected,
            border: BORDER,
            color: COLORS.black,
        },
        bgcolor: "transparent",
        border: `2px solid ${ COLORS.white }`,
        color: COLORS.white,
    },
    calendarDay: {
        "& .MuiButtonBase-root.MuiPickersDay-root.Mui-selected": { bgcolor: COLORS.selected, fontWeight: "bold" },
        "& .MuiButtonBase-root.MuiPickersDay-root.Mui-selected.MuiPickersDay-today": { bgcolor: COLORS.selected, fontWeight: "bold" },
        "& .MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-today": { bgcolor: "transparent" },
    },
    calendarLayout: {
        "& .MuiPickersCalendarHeader-label": { fontSize: 14.4 },
        "& .MuiPickersYear-yearButton.Mui-selected": { bgcolor: `${ COLORS.selected } !important` },
        "& button": { fontSize: 14.4 },
        "& span": { color: COLORS.black, fontSize: 14.4, fontWeight: "bold" },
        "& span:last-of-type": { fontWeight: "normal" },
        "& svg": { color: COLORS.black, fontSize: 14.4, stroke: COLORS.black, strokeWidth: "2px" },
    },
    checkboxDisabledFormatted: { "&.Mui-disabled": { color: COLORS.black } },
    multilineTextInput: {
        "& .MuiInputBase-root.MuiOutlinedInput-root": { border: `2px dotted ${ COLORS.black }`, borderRadius: 0 },
        "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused": { border: `2px solid ${ COLORS.black }` },
        "& .MuiOutlinedInput-notchedOutline": { border: "none" },
        flexBasis: "100%",
    },
    sidePanelControlWrapper: { border: BORDER, display: "flex", flexDirection: "column", minHeight: 116, overflow: "hidden", px: 1 },
    sidePanelWrapper: { "@media print": { display: "none" }, borderTop: BORDER, display: "flex", flexBasis: "33.3%", flexDirection: "column", overflow: "scroll", p: 1.25 },
    textInput: {
        "& .MuiFormHelperText-root": { color: COLORS.black, fontWeight: "bold" },
        "& .MuiFormHelperText-root.Mui-error": { color: COLORS.error },
        "& .MuiInput-root.Mui-error::before": { borderBottom: `2px dotted ${ COLORS.error }` },
        "& .MuiInput-root::before": { borderBottom: `2px dotted ${ COLORS.black }` },
        "& input:-webkit-autofill": { WebkitBackgroundClip: "text" },
    },
    textInputDisabledFormatted: {
        "& input.Mui-disabled": { WebkitTextFillColor: COLORS.black },
        "& p.Mui-disabled": { color: COLORS.black },
        "& textarea.Mui-disabled": { WebkitTextFillColor: COLORS.black },
    },
    topMenuButton: { fontSize: 17.5, fontWeight: "bold", height: 35, py: 0 },
};
