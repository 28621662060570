
import { storageService, tokenService } from "common/services";
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from "config/token";
import { AuthStatus } from "models/Auth";
import { TokensInterface } from "models/Token";

import * as repository from "../repositories/tokens";

export async function getAuthStatus(tempToken: string | null): Promise<AuthStatus> {
    if (tempToken) {
        if (!tokenService.isExpired(tempToken)) {
            return AuthStatus.LOGGED_OUT_AUTH;
        }
        return AuthStatus.LOGGED_OUT_INAUTH;
    }

    const accessToken = storageService.getItem(ACCESS_TOKEN_KEY);
    const refreshToken = storageService.getItem(REFRESH_TOKEN_KEY);

    if (!accessToken || !refreshToken) {
        return AuthStatus.LOGGED_OUT_INAUTH;
    }

    if (!tokenService.isExpired(accessToken)) {
        return AuthStatus.LOGGED_IN;
    }

    try {
        await refreshAccess(refreshToken);
        return AuthStatus.LOGGED_IN;
    } catch (err) {
        return AuthStatus.LOGGED_OUT_INAUTH;
    }
}

export function login(tokens: TokensInterface): void {
    saveTokens(tokens);
    reloadApp();
}

function saveTokens(tokens: TokensInterface): void {
    for (const [ key, value ] of Object.entries(tokens)) {
        if (key === "tokenType") {
            return;
        }
        storageService.setItem({ key, value });
    }
}


export async function refreshAccess(refreshToken: string): Promise<void> {
    try {
        saveTokens(await repository.refreshToken({ refreshToken }));
    } catch {
        throw new Error();
    }
}


export function logout(): void {
    for (const token of [ ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY ]) {
        storageService.removeItem(token);
    }
    reloadApp();
}

export function reloadApp(): void {
    window.location.reload();
    window.location.replace("/");
}