import { Box, CircularProgress, GlobalStyles, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment-timezone";
import { ReactElement } from "react";
import { QueryClientProvider } from "react-query";
import { RouterProvider } from "react-router";


import { httpService } from "common/services";
import { AuthStatus } from "models/Auth";
import { useAuthStatus } from "modules/auth/hooks/useAuthStatus";
import { globalMuiStyles } from "ui/globalStyles";
import { theme } from "ui/theme";

import { getRouter } from "../routes/config";
import "moment/locale/pl";

import "libs/i18n";

moment.tz.setDefault("Poland");

export function App(): ReactElement {
    const authStatus = useAuthStatus();

    if (typeof authStatus === "undefined") {
        return <Box sx={{ alignItems: "center", display: "flex", height: "100vh", justifyContent: "center" }}><CircularProgress/></Box>;
    }

    return (
        <QueryClientProvider client={httpService.queryClient}>
            <GlobalStyles styles={globalMuiStyles} />
            <ThemeProvider theme={theme}>
                <LocalizationProvider adapterLocale="pl" dateAdapter={AdapterMoment}>
                    <RouterProvider router={getRouter(authStatus ?? AuthStatus.LOGGED_OUT_INAUTH)} />
                </LocalizationProvider>
            </ThemeProvider>
        </QueryClientProvider>
    );
}
