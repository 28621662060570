import { Autocomplete, SxProps, TextField } from "@mui/material";
import { HTMLAttributes, JSXElementConstructor, ReactElement } from "react";
import { ControllerRenderProps, FieldError, FieldValues } from "react-hook-form";

export interface AutoCompleteProps<T> {
    PaperComponent?: JSXElementConstructor<HTMLAttributes<HTMLElement>>,
    controllerField?: ControllerRenderProps<FieldValues, string>
    disabled?: boolean,
    error?: FieldError
    getOptionDisabled?: (option: T) => boolean,
    getOptionLabel?: (option: T) => string,
    isOptionEqualToValue?: (option: T, value: T) => boolean
    noOptionsText?: string,
    onChange?: (value: T | null) => void,
    options: Array<T>,
    sx?: SxProps,
    textFieldSX?: SxProps,
    value?: T | null
}

export function AutoComplete<T>(props: AutoCompleteProps<T>): ReactElement {
    const { controllerField, textFieldSX, ...restProps } = props;

    return (
        <Autocomplete
            {...controllerField}
            {...restProps}
            disablePortal
            renderInput={(params) =>
                <TextField
                    {...params}
                    error={!!props.error}
                    sx={textFieldSX}
                />
            }
            onChange={(_, value) => {
                props.controllerField?.onChange(value);
                props.onChange?.(value);
            }}
        />
    );
}