import { PropsWithChildren, ReactElement } from "react";
import { FieldValues, FormProvider, SubmitHandler, UseFormReturn } from "react-hook-form";

interface FormProps<T extends FieldValues> {
    form: UseFormReturn<T>;
    onSubmit: SubmitHandler<T>;
}

export function Form<T extends FieldValues>({
    children,
    form,
    onSubmit,
}: PropsWithChildren<FormProps<T>>): ReactElement {
    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>{children}</form>
        </FormProvider>
    );
}
