import { Box } from "@mui/material";
import { ReactElement, useContext } from "react";


import { HandlerType } from "models/Date";
import { DateCalendar } from "ui/components/date/DateCalendar";
import { globalStyles } from "ui/globalStyles";
import { COLORS } from "ui/variables";

import { ReservationsContext } from "../../Reservations";

export function Calendar(): ReactElement {
    const { datePanelControl: { onHandlerTypeChange, onSelectedStartDateChange, selectedStartDate } } = useContext(ReservationsContext);

    return (
        <Box sx={{ bgcolor: COLORS.main, border: globalStyles.border, borderTop: "none", boxSizing: "border-box", position: "absolute", top: 100, width: "100%", zIndex: 999 }}>
            <DateCalendar
                value={selectedStartDate}
                onDateChange={(date) => {
                    if (date) {
                        onSelectedStartDateChange(date);
                        onHandlerTypeChange?.(HandlerType.BUTTONS);
                    }
                }}
            />
        </Box>
    );
}