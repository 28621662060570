import { ReactElement } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { TextField, TextFieldProps as TextFieldPropsUI } from "ui/components/TextField";

interface TextInputProps extends TextFieldPropsUI {
    field: string
}

export function TextInput(props: TextInputProps): ReactElement {
    const { control } = useFormContext();

    return (
        <Controller
            control={control}
            name={props.field}
            render={({ field, fieldState: { error } }) =>
                <TextField
                    {...props}
                    controllerField={field}
                    error={error}
                />
            }
        />
    );
}
