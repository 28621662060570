import { HIGHLIGHT_COLOR, Status } from "models/Table";

export function getTable(id: number, status: Status) {
    const fill: string = HIGHLIGHT_COLOR[status];

    return {
        1: <svg height="45.467" viewBox="0 0 55.253 51.881" width="48.304" xmlns="http://www.w3.org/2000/svg">
            <path d="M-1952.245,1493.668a26.589,26.589,0,0,1-10.414-2.1,26.67,26.67,0,0,1-8.5-5.734,26.665,26.665,0,0,1-5.734-8.5,26.589,26.589,0,0,1-2.1-10.415,26.859,26.859,0,0,1,.812-6.566,26.616,26.616,0,0,1,2.3-5.973A26.984,26.984,0,0,1-1967.6,1445h30.713a26.984,26.984,0,0,1,8.285,9.373,26.616,26.616,0,0,1,2.3,5.973,26.853,26.853,0,0,1,.812,6.566,26.588,26.588,0,0,1-2.1,10.415,26.666,26.666,0,0,1-5.734,8.5,26.669,26.669,0,0,1-8.5,5.734A26.591,26.591,0,0,1-1952.245,1493.668Z" data-name="Subtraction 98" fill={`${ fill }`} id="Subtraction_98" transform="translate(1980.053 -1443.57)"/>
            <g data-name="Group 1300" id="Group_1300" transform="translate(1.001 1.043)">
                <g data-name="Group 1108" id="Group_1108" transform="translate(0 0)">
                    <g data-name="Group 988" id="Group_988" transform="translate(0 0)">
                        <path d="M64.657,73.609h-.013a26.621,26.621,0,0,1-13.094-49.8.274.274,0,0,1,.27.478,26.083,26.083,0,1,0,25.7.012.274.274,0,1,1,.271-.477A26.621,26.621,0,0,1,64.657,73.609Z" data-name="Path 15153" fill="none" id="Path_15153" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(-38.032 -23.771)"/>
                        <path d="M105.211,24.309H79.234a.274.274,0,0,1,0-.549h25.976a.274.274,0,1,1,0,.549Z" data-name="Path 15154" fill="none" id="Path_15154" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(-65.597 -23.76)"/>
                    </g>
                </g>
                <text data-name="27" fontFamily="Poppins-Bold, Poppins" fontSize="14.5" fontWeight="700" id="_1" transform="translate(22.925 28.419)"><tspan x="0" y="0">1</tspan></text>
            </g>
        </svg>
        ,
        10: <svg height="45.467" viewBox="0 0 55.253 51.881" width="48.304" xmlns="http://www.w3.org/2000/svg">
            <path d="M-1952.245,1493.668a26.589,26.589,0,0,1-10.414-2.1,26.67,26.67,0,0,1-8.5-5.734,26.665,26.665,0,0,1-5.734-8.5,26.589,26.589,0,0,1-2.1-10.415,26.859,26.859,0,0,1,.812-6.566,26.616,26.616,0,0,1,2.3-5.973A26.984,26.984,0,0,1-1967.6,1445h30.713a26.984,26.984,0,0,1,8.285,9.373,26.616,26.616,0,0,1,2.3,5.973,26.853,26.853,0,0,1,.812,6.566,26.588,26.588,0,0,1-2.1,10.415,26.666,26.666,0,0,1-5.734,8.5,26.669,26.669,0,0,1-8.5,5.734A26.591,26.591,0,0,1-1952.245,1493.668Z" data-name="Subtraction 98" fill={`${ fill }`} id="Subtraction_98" transform="translate(1980.053 -1443.57)"/>
            <g data-name="Group 1300" id="Group_1300" transform="translate(1.001 1.043)">
                <g data-name="Group 1108" id="Group_1108" transform="translate(0 0)">
                    <g data-name="Group 988" id="Group_988" transform="translate(0 0)">
                        <path d="M64.657,73.609h-.013a26.621,26.621,0,0,1-13.094-49.8.274.274,0,0,1,.27.478,26.083,26.083,0,1,0,25.7.012.274.274,0,1,1,.271-.477A26.621,26.621,0,0,1,64.657,73.609Z" data-name="Path 15153" fill="none" id="Path_15153" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(-38.032 -23.771)"/>
                        <path d="M105.211,24.309H79.234a.274.274,0,0,1,0-.549h25.976a.274.274,0,1,1,0,.549Z" data-name="Path 15154" fill="none" id="Path_15154" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(-65.597 -23.76)"/>
                    </g>
                </g>
                <text data-name="27" fontFamily="Poppins-Bold, Poppins" fontSize="14.5" fontWeight="700" id="_10" transform="translate(19 29.419)"><tspan x="0" y="0">10</tspan></text>
            </g>
        </svg>
        ,
        11: <svg height="48" viewBox="0 0 77.572 79.572"width="48" xmlns="http://www.w3.org/2000/svg">
            <path d="M36.5,0A36.5,36.5,0,1,1,0,36.5,36.5,36.5,0,0,1,36.5,0Z" data-name="Path 175" fill={`${ fill }`} id="Path_175" transform="translate(2 2.252)"/>
            <g data-name="Group 1297" id="Group_1297" transform="translate(-17.5 -749)">
                <path d="M82.046,1565.452a37.115,37.115,0,0,1-20.847-6.368,37.394,37.394,0,0,1-13.509-16.4,37.237,37.237,0,0,1,3.438-35.361,37.4,37.4,0,0,1,16.4-13.509,37.237,37.237,0,0,1,35.361,3.438,37.395,37.395,0,0,1,13.509,16.4,37.237,37.237,0,0,1-3.438,35.361,37.393,37.393,0,0,1-16.4,13.509A37.051,37.051,0,0,1,82.046,1565.452Zm0-74.157a36.7,36.7,0,0,0-20.615,6.3,36.98,36.98,0,0,0-13.359,16.222,36.821,36.821,0,0,0,3.4,34.966,36.979,36.979,0,0,0,16.222,13.359,36.822,36.822,0,0,0,34.966-3.4,36.979,36.979,0,0,0,13.359-16.222,36.822,36.822,0,0,0-3.4-34.966A36.978,36.978,0,0,0,96.4,1494.193,36.641,36.641,0,0,0,82.046,1491.3Z" data-name="Path 15133" fill="none" id="Path_15133" stroke="#000" strokeLinecap="round" strokeWidth="3" transform="translate(-26.26 -739.88)"/>
                <text data-name="11" fontFamily="Poppins-Bold, Poppins" fontSize="21.6" fontWeight="700" id="_11" transform="translate(46.786 796.786)"><tspan x="0" y="0">11</tspan></text>
            </g>
        </svg>,
        12: <svg height="45.467" viewBox="0 0 55.253 51.881" width="48.304" xmlns="http://www.w3.org/2000/svg">
            <path d="M-1952.245,1493.668a26.589,26.589,0,0,1-10.414-2.1,26.67,26.67,0,0,1-8.5-5.734,26.665,26.665,0,0,1-5.734-8.5,26.589,26.589,0,0,1-2.1-10.415,26.859,26.859,0,0,1,.812-6.566,26.616,26.616,0,0,1,2.3-5.973A26.984,26.984,0,0,1-1967.6,1445h30.713a26.984,26.984,0,0,1,8.285,9.373,26.616,26.616,0,0,1,2.3,5.973,26.853,26.853,0,0,1,.812,6.566,26.588,26.588,0,0,1-2.1,10.415,26.666,26.666,0,0,1-5.734,8.5,26.669,26.669,0,0,1-8.5,5.734A26.591,26.591,0,0,1-1952.245,1493.668Z" data-name="Subtraction 98" fill={`${ fill }`} id="Subtraction_98" transform="translate(1980.053 -1443.57)"/>
            <g data-name="Group 1300" id="Group_1300" transform="translate(1.001 1.043)">
                <g data-name="Group 1108" id="Group_1108" transform="translate(0 0)">
                    <g data-name="Group 988" id="Group_988" transform="translate(0 0)">
                        <path d="M64.657,73.609h-.013a26.621,26.621,0,0,1-13.094-49.8.274.274,0,0,1,.27.478,26.083,26.083,0,1,0,25.7.012.274.274,0,1,1,.271-.477A26.621,26.621,0,0,1,64.657,73.609Z" data-name="Path 15153" fill="none" id="Path_15153" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(-38.032 -23.771)"/>
                        <path d="M105.211,24.309H79.234a.274.274,0,0,1,0-.549h25.976a.274.274,0,1,1,0,.549Z" data-name="Path 15154" fill="none" id="Path_15154" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(-65.597 -23.76)"/>
                    </g>
                </g>
                <text data-name="27" fontFamily="Poppins-Bold, Poppins" fontSize="14.5" fontWeight="700" id="_12" transform="translate(20 29.419)"><tspan x="0" y="0">12</tspan></text>
            </g>
        </svg>,
        13: <svg height="48.378" viewBox="0 0 55.285 55.303" width="48.332" xmlns="http://www.w3.org/2000/svg">
            <path d="M-1937,1528h-53v-53h11.005a26.309,26.309,0,0,0,15.494,5,26.311,26.311,0,0,0,15.5-5H-1937v53Z" data-name="Subtraction 96" fill={`${ fill }`} id="Subtraction_96" transform="translate(1991.285 -1473.943)"/>
            <g data-name="Group 1292" id="Group_1292" transform="translate(-17.408 -192.117)">
                <g data-name="Group 1110" id="Group_1110" transform="translate(18.408 193.174)">
                    <path d="M88.371,167.315a30.481,30.481,0,0,1-14.991-3.949.274.274,0,1,1,.27-.477,29.875,29.875,0,0,0,29.441,0,.274.274,0,1,1,.27.477A30.481,30.481,0,0,1,88.371,167.315Z" data-name="Path 15155" fill="none" id="Path_15155" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(-61.669 -162.849)"/>
                    <path d="M90.811,216.086H38.074a.274.274,0,0,1-.274-.274v-52.7a.274.274,0,0,1,.274-.274H49.632a.274.274,0,1,1,0,.549H38.349v52.149H90.536V163.389H79.331a.274.274,0,1,1,0-.549h11.48a.274.274,0,0,1,.274.274v52.7A.274.274,0,0,1,90.811,216.086Z" data-name="Path 15156" fill="none" id="Path_15156" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(-37.8 -162.84)"/>
                </g>
                <text data-name="23" fontFamily="Poppins-Bold, Poppins" fontSize="14.5" fontWeight="700" id="_13" transform="translate(38.551 226.297)"><tspan x="0" y="0">13</tspan></text>
            </g>
        </svg>
        ,
        14: <svg height="45.467" viewBox="0 0 55.253 51.881" width="48.304" xmlns="http://www.w3.org/2000/svg">
            <path d="M-1952.245,1493.668a26.589,26.589,0,0,1-10.414-2.1,26.67,26.67,0,0,1-8.5-5.734,26.665,26.665,0,0,1-5.734-8.5,26.589,26.589,0,0,1-2.1-10.415,26.859,26.859,0,0,1,.812-6.566,26.616,26.616,0,0,1,2.3-5.973A26.984,26.984,0,0,1-1967.6,1445h30.713a26.984,26.984,0,0,1,8.285,9.373,26.616,26.616,0,0,1,2.3,5.973,26.853,26.853,0,0,1,.812,6.566,26.588,26.588,0,0,1-2.1,10.415,26.666,26.666,0,0,1-5.734,8.5,26.669,26.669,0,0,1-8.5,5.734A26.591,26.591,0,0,1-1952.245,1493.668Z" data-name="Subtraction 98" fill={`${ fill }`} id="Subtraction_98" transform="translate(1980.053 -1443.57)"/>
            <g data-name="Group 1300" id="Group_1300" transform="translate(1.001 1.043)">
                <g data-name="Group 1108" id="Group_1108" transform="translate(0 0)">
                    <g data-name="Group 988" id="Group_988" transform="translate(0 0)">
                        <path d="M64.657,73.609h-.013a26.621,26.621,0,0,1-13.094-49.8.274.274,0,0,1,.27.478,26.083,26.083,0,1,0,25.7.012.274.274,0,1,1,.271-.477A26.621,26.621,0,0,1,64.657,73.609Z" data-name="Path 15153" fill="none" id="Path_15153" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(-38.032 -23.771)"/>
                        <path d="M105.211,24.309H79.234a.274.274,0,0,1,0-.549h25.976a.274.274,0,1,1,0,.549Z" data-name="Path 15154" fill="none" id="Path_15154" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(-65.597 -23.76)"/>
                    </g>
                </g>
                <text data-name="27" fontFamily="Poppins-Bold, Poppins" fontSize="14.5" fontWeight="700" id="_14" transform="translate(20 29.419)"><tspan x="0" y="0">14</tspan></text>
            </g>
        </svg>,
        15: <svg height="48.378" viewBox="0 0 55.285 55.303" width="48.332" xmlns="http://www.w3.org/2000/svg">
            <path d="M-1937,1528h-53v-53h11.005a26.309,26.309,0,0,0,15.494,5,26.311,26.311,0,0,0,15.5-5H-1937v53Z" data-name="Subtraction 96" fill={`${ fill }`} id="Subtraction_96" transform="translate(1991.285 -1473.943)"/>
            <g data-name="Group 1292" id="Group_1292" transform="translate(-17.408 -192.117)">
                <g data-name="Group 1110" id="Group_1110" transform="translate(18.408 193.174)">
                    <path d="M88.371,167.315a30.481,30.481,0,0,1-14.991-3.949.274.274,0,1,1,.27-.477,29.875,29.875,0,0,0,29.441,0,.274.274,0,1,1,.27.477A30.481,30.481,0,0,1,88.371,167.315Z" data-name="Path 15155" fill="none" id="Path_15155" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(-61.669 -162.849)"/>
                    <path d="M90.811,216.086H38.074a.274.274,0,0,1-.274-.274v-52.7a.274.274,0,0,1,.274-.274H49.632a.274.274,0,1,1,0,.549H38.349v52.149H90.536V163.389H79.331a.274.274,0,1,1,0-.549h11.48a.274.274,0,0,1,.274.274v52.7A.274.274,0,0,1,90.811,216.086Z" data-name="Path 15156" fill="none" id="Path_15156" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(-37.8 -162.84)"/>
                </g>
                <text data-name="23" fontFamily="Poppins-Bold, Poppins" fontSize="14.5" fontWeight="700" id="_15" transform="translate(38.551 226.297)"><tspan x="0" y="0">15</tspan></text>
            </g>
        </svg>,
        16: <svg height="45.467" viewBox="0 0 55.253 51.881" width="48.304" xmlns="http://www.w3.org/2000/svg">
            <path d="M-1952.245,1493.668a26.589,26.589,0,0,1-10.414-2.1,26.67,26.67,0,0,1-8.5-5.734,26.665,26.665,0,0,1-5.734-8.5,26.589,26.589,0,0,1-2.1-10.415,26.859,26.859,0,0,1,.812-6.566,26.616,26.616,0,0,1,2.3-5.973A26.984,26.984,0,0,1-1967.6,1445h30.713a26.984,26.984,0,0,1,8.285,9.373,26.616,26.616,0,0,1,2.3,5.973,26.853,26.853,0,0,1,.812,6.566,26.588,26.588,0,0,1-2.1,10.415,26.666,26.666,0,0,1-5.734,8.5,26.669,26.669,0,0,1-8.5,5.734A26.591,26.591,0,0,1-1952.245,1493.668Z" data-name="Subtraction 98" fill={`${ fill }`} id="Subtraction_98" transform="translate(1980.053 -1443.57)"/>
            <g data-name="Group 1300" id="Group_1300" transform="translate(1.001 1.043)">
                <g data-name="Group 1108" id="Group_1108" transform="translate(0 0)">
                    <g data-name="Group 988" id="Group_988" transform="translate(0 0)">
                        <path d="M64.657,73.609h-.013a26.621,26.621,0,0,1-13.094-49.8.274.274,0,0,1,.27.478,26.083,26.083,0,1,0,25.7.012.274.274,0,1,1,.271-.477A26.621,26.621,0,0,1,64.657,73.609Z" data-name="Path 15153" fill="none" id="Path_15153" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(-38.032 -23.771)"/>
                        <path d="M105.211,24.309H79.234a.274.274,0,0,1,0-.549h25.976a.274.274,0,1,1,0,.549Z" data-name="Path 15154" fill="none" id="Path_15154" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(-65.597 -23.76)"/>
                    </g>
                </g>
                <text data-name="27" fontFamily="Poppins-Bold, Poppins" fontSize="14.5" fontWeight="700" id="_16" transform="translate(20 29.419)"><tspan x="0" y="0">16</tspan></text>
            </g>
        </svg>,
        17: <svg height="48.378" viewBox="0 0 55.285 55.303" width="48.332" xmlns="http://www.w3.org/2000/svg">
            <path d="M-1937,1528h-53v-53h11.005a26.309,26.309,0,0,0,15.494,5,26.311,26.311,0,0,0,15.5-5H-1937v53Z" data-name="Subtraction 96" fill={`${ fill }`} id="Subtraction_96" transform="translate(1991.285 -1473.943)"/>
            <g data-name="Group 1292" id="Group_1292" transform="translate(-17.408 -192.117)">
                <g data-name="Group 1110" id="Group_1110" transform="translate(18.408 193.174)">
                    <path d="M88.371,167.315a30.481,30.481,0,0,1-14.991-3.949.274.274,0,1,1,.27-.477,29.875,29.875,0,0,0,29.441,0,.274.274,0,1,1,.27.477A30.481,30.481,0,0,1,88.371,167.315Z" data-name="Path 15155" fill="none" id="Path_15155" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(-61.669 -162.849)"/>
                    <path d="M90.811,216.086H38.074a.274.274,0,0,1-.274-.274v-52.7a.274.274,0,0,1,.274-.274H49.632a.274.274,0,1,1,0,.549H38.349v52.149H90.536V163.389H79.331a.274.274,0,1,1,0-.549h11.48a.274.274,0,0,1,.274.274v52.7A.274.274,0,0,1,90.811,216.086Z" data-name="Path 15156" fill="none" id="Path_15156" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(-37.8 -162.84)"/>
                </g>
                <text data-name="23" fontFamily="Poppins-Bold, Poppins" fontSize="14.5" fontWeight="700" id="_17" transform="translate(38.551 226.297)"><tspan x="0" y="0">17</tspan></text>
            </g>
        </svg>,
        2: <svg height="48.378" viewBox="0 0 55.285 55.303" width="48.332" xmlns="http://www.w3.org/2000/svg">
            <path d="M-1937,1528h-53v-53h11.005a26.309,26.309,0,0,0,15.494,5,26.311,26.311,0,0,0,15.5-5H-1937v53Z" data-name="Subtraction 96" fill={`${ fill }`} id="Subtraction_96" transform="translate(1991.285 -1473.943)"/>
            <g data-name="Group 1292" id="Group_1292" transform="translate(-17.408 -192.117)">
                <g data-name="Group 1110" id="Group_1110" transform="translate(18.408 193.174)">
                    <path d="M88.371,167.315a30.481,30.481,0,0,1-14.991-3.949.274.274,0,1,1,.27-.477,29.875,29.875,0,0,0,29.441,0,.274.274,0,1,1,.27.477A30.481,30.481,0,0,1,88.371,167.315Z" data-name="Path 15155" fill="none" id="Path_15155" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(-61.669 -162.849)"/>
                    <path d="M90.811,216.086H38.074a.274.274,0,0,1-.274-.274v-52.7a.274.274,0,0,1,.274-.274H49.632a.274.274,0,1,1,0,.549H38.349v52.149H90.536V163.389H79.331a.274.274,0,1,1,0-.549h11.48a.274.274,0,0,1,.274.274v52.7A.274.274,0,0,1,90.811,216.086Z" data-name="Path 15156" fill="none" id="Path_15156" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(-37.8 -162.84)"/>
                </g>
                <text data-name="23" fontFamily="Poppins-Bold, Poppins" fontSize="14.5" fontWeight="700" id="_2" transform="translate(39.851 226.297)"><tspan x="0" y="0">2</tspan></text>
            </g>
        </svg>,
        20: <svg height="45.467" viewBox="0 0 55.253 51.881" width="48.304" xmlns="http://www.w3.org/2000/svg">
            <path d="M-1952.245,1493.668a26.589,26.589,0,0,1-10.414-2.1,26.67,26.67,0,0,1-8.5-5.734,26.665,26.665,0,0,1-5.734-8.5,26.589,26.589,0,0,1-2.1-10.415,26.859,26.859,0,0,1,.812-6.566,26.616,26.616,0,0,1,2.3-5.973A26.984,26.984,0,0,1-1967.6,1445h30.713a26.984,26.984,0,0,1,8.285,9.373,26.616,26.616,0,0,1,2.3,5.973,26.853,26.853,0,0,1,.812,6.566,26.588,26.588,0,0,1-2.1,10.415,26.666,26.666,0,0,1-5.734,8.5,26.669,26.669,0,0,1-8.5,5.734A26.591,26.591,0,0,1-1952.245,1493.668Z" data-name="Subtraction 98" fill={`${ fill }`} id="Subtraction_98" transform="translate(1980.053 -1443.57)"/>
            <g data-name="Group 1300" id="Group_1300" transform="translate(1.001 1.043)">
                <g data-name="Group 1108" id="Group_1108" transform="translate(0 0)">
                    <g data-name="Group 988" id="Group_988" transform="translate(0 0)">
                        <path d="M64.657,73.609h-.013a26.621,26.621,0,0,1-13.094-49.8.274.274,0,0,1,.27.478,26.083,26.083,0,1,0,25.7.012.274.274,0,1,1,.271-.477A26.621,26.621,0,0,1,64.657,73.609Z" data-name="Path 15153" fill="none" id="Path_15153" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(-38.032 -23.771)"/>
                        <path d="M105.211,24.309H79.234a.274.274,0,0,1,0-.549h25.976a.274.274,0,1,1,0,.549Z" data-name="Path 15154" fill="none" id="Path_15154" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(-65.597 -23.76)"/>
                    </g>
                </g>
                <text data-name="27" fontFamily="Poppins-Bold, Poppins" fontSize="14.5" fontWeight="700" id="_20" transform="translate(17.5 29.419)"><tspan x="0" y="0">20</tspan></text>
            </g>
        </svg>,
        21: <svg height="48.378" viewBox="0 0 55.285 55.303" width="48.332" xmlns="http://www.w3.org/2000/svg">
            <path d="M-1937,1528h-53v-53h11.005a26.309,26.309,0,0,0,15.494,5,26.311,26.311,0,0,0,15.5-5H-1937v53Z" data-name="Subtraction 96" fill={`${ fill }`} id="Subtraction_96" transform="translate(1991.285 -1473.943)"/>
            <g data-name="Group 1292" id="Group_1292" transform="translate(-17.408 -192.117)">
                <g data-name="Group 1110" id="Group_1110" transform="translate(18.408 193.174)">
                    <path d="M88.371,167.315a30.481,30.481,0,0,1-14.991-3.949.274.274,0,1,1,.27-.477,29.875,29.875,0,0,0,29.441,0,.274.274,0,1,1,.27.477A30.481,30.481,0,0,1,88.371,167.315Z" data-name="Path 15155" fill="none" id="Path_15155" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(-61.669 -162.849)"/>
                    <path d="M90.811,216.086H38.074a.274.274,0,0,1-.274-.274v-52.7a.274.274,0,0,1,.274-.274H49.632a.274.274,0,1,1,0,.549H38.349v52.149H90.536V163.389H79.331a.274.274,0,1,1,0-.549h11.48a.274.274,0,0,1,.274.274v52.7A.274.274,0,0,1,90.811,216.086Z" data-name="Path 15156" fill="none" id="Path_15156" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(-37.8 -162.84)"/>
                </g>
                <text data-name="23" fontFamily="Poppins-Bold, Poppins" fontSize="14.5" fontWeight="700" id="_21" transform="translate(36.551 226.297)"><tspan x="0" y="0">21</tspan></text>
            </g>
        </svg>,
        22: <svg height="45.467" viewBox="0 0 55.253 51.881" width="48.304" xmlns="http://www.w3.org/2000/svg">
            <path d="M-1952.245,1493.668a26.589,26.589,0,0,1-10.414-2.1,26.67,26.67,0,0,1-8.5-5.734,26.665,26.665,0,0,1-5.734-8.5,26.589,26.589,0,0,1-2.1-10.415,26.859,26.859,0,0,1,.812-6.566,26.616,26.616,0,0,1,2.3-5.973A26.984,26.984,0,0,1-1967.6,1445h30.713a26.984,26.984,0,0,1,8.285,9.373,26.616,26.616,0,0,1,2.3,5.973,26.853,26.853,0,0,1,.812,6.566,26.588,26.588,0,0,1-2.1,10.415,26.666,26.666,0,0,1-5.734,8.5,26.669,26.669,0,0,1-8.5,5.734A26.591,26.591,0,0,1-1952.245,1493.668Z" data-name="Subtraction 98" fill={`${ fill }`} id="Subtraction_98" transform="translate(1980.053 -1443.57)"/>
            <g data-name="Group 1300" id="Group_1300" transform="translate(1.001 1.043)">
                <g data-name="Group 1108" id="Group_1108" transform="translate(0 0)">
                    <g data-name="Group 988" id="Group_988" transform="translate(0 0)">
                        <path d="M64.657,73.609h-.013a26.621,26.621,0,0,1-13.094-49.8.274.274,0,0,1,.27.478,26.083,26.083,0,1,0,25.7.012.274.274,0,1,1,.271-.477A26.621,26.621,0,0,1,64.657,73.609Z" data-name="Path 15153" fill="none" id="Path_15153" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(-38.032 -23.771)"/>
                        <path d="M105.211,24.309H79.234a.274.274,0,0,1,0-.549h25.976a.274.274,0,1,1,0,.549Z" data-name="Path 15154" fill="none" id="Path_15154" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(-65.597 -23.76)"/>
                    </g>
                </g>
                <text data-name="27" fontFamily="Poppins-Bold, Poppins" fontSize="14.5" fontWeight="700" id="_22" transform="translate(17.5 29.419)"><tspan x="0" y="0">22</tspan></text>
            </g>
        </svg>,
        23: <svg height="48.378" viewBox="0 0 55.285 55.303" width="48.332" xmlns="http://www.w3.org/2000/svg">
            <path d="M-1937,1528h-53v-53h11.005a26.309,26.309,0,0,0,15.494,5,26.311,26.311,0,0,0,15.5-5H-1937v53Z" data-name="Subtraction 96" fill={`${ fill }`} id="Subtraction_96" transform="translate(1991.285 -1473.943)"/>
            <g data-name="Group 1292" id="Group_1292" transform="translate(-17.408 -192.117)">
                <g data-name="Group 1110" id="Group_1110" transform="translate(18.408 193.174)">
                    <path d="M88.371,167.315a30.481,30.481,0,0,1-14.991-3.949.274.274,0,1,1,.27-.477,29.875,29.875,0,0,0,29.441,0,.274.274,0,1,1,.27.477A30.481,30.481,0,0,1,88.371,167.315Z" data-name="Path 15155" fill="none" id="Path_15155" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(-61.669 -162.849)"/>
                    <path d="M90.811,216.086H38.074a.274.274,0,0,1-.274-.274v-52.7a.274.274,0,0,1,.274-.274H49.632a.274.274,0,1,1,0,.549H38.349v52.149H90.536V163.389H79.331a.274.274,0,1,1,0-.549h11.48a.274.274,0,0,1,.274.274v52.7A.274.274,0,0,1,90.811,216.086Z" data-name="Path 15156" fill="none" id="Path_15156" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(-37.8 -162.84)"/>
                </g>
                <text data-name="23" fontFamily="Poppins-Bold, Poppins" fontSize="14.5" fontWeight="700" id="_23" transform="translate(35.551 226.297)"><tspan x="0" y="0">23</tspan></text>
            </g>
        </svg>,
        24: <svg height="48.378" viewBox="0 0 55.285 55.303" width="48.332" xmlns="http://www.w3.org/2000/svg">
            <path d="M-1937,1528h-53v-53h11.005a26.309,26.309,0,0,0,15.494,5,26.311,26.311,0,0,0,15.5-5H-1937v53Z" data-name="Subtraction 96" fill={`${ fill }`} id="Subtraction_96" transform="translate(1991.285 -1473.943)"/>
            <g data-name="Group 1292" id="Group_1292" transform="translate(-17.408 -192.117)">
                <g data-name="Group 1110" id="Group_1110" transform="translate(18.408 193.174)">
                    <path d="M88.371,167.315a30.481,30.481,0,0,1-14.991-3.949.274.274,0,1,1,.27-.477,29.875,29.875,0,0,0,29.441,0,.274.274,0,1,1,.27.477A30.481,30.481,0,0,1,88.371,167.315Z" data-name="Path 15155" fill="none" id="Path_15155" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(-61.669 -162.849)"/>
                    <path d="M90.811,216.086H38.074a.274.274,0,0,1-.274-.274v-52.7a.274.274,0,0,1,.274-.274H49.632a.274.274,0,1,1,0,.549H38.349v52.149H90.536V163.389H79.331a.274.274,0,1,1,0-.549h11.48a.274.274,0,0,1,.274.274v52.7A.274.274,0,0,1,90.811,216.086Z" data-name="Path 15156" fill="none" id="Path_15156" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(-37.8 -162.84)"/>
                </g>
                <text data-name="23" fontFamily="Poppins-Bold, Poppins" fontSize="14.5" fontWeight="700" id="_24" transform="translate(35.551 226.297)"><tspan x="0" y="0">24</tspan></text>
            </g>
        </svg>,
        25: <svg height="45.467" viewBox="0 0 55.253 51.881" width="48.304" xmlns="http://www.w3.org/2000/svg">
            <path d="M-1952.245,1493.668a26.589,26.589,0,0,1-10.414-2.1,26.67,26.67,0,0,1-8.5-5.734,26.665,26.665,0,0,1-5.734-8.5,26.589,26.589,0,0,1-2.1-10.415,26.859,26.859,0,0,1,.812-6.566,26.616,26.616,0,0,1,2.3-5.973A26.984,26.984,0,0,1-1967.6,1445h30.713a26.984,26.984,0,0,1,8.285,9.373,26.616,26.616,0,0,1,2.3,5.973,26.853,26.853,0,0,1,.812,6.566,26.588,26.588,0,0,1-2.1,10.415,26.666,26.666,0,0,1-5.734,8.5,26.669,26.669,0,0,1-8.5,5.734A26.591,26.591,0,0,1-1952.245,1493.668Z" data-name="Subtraction 98" fill={`${ fill }`} id="Subtraction_98" transform="translate(1980.053 -1443.57)"/>
            <g data-name="Group 1300" id="Group_1300" transform="translate(1.001 1.043)">
                <g data-name="Group 1108" id="Group_1108" transform="translate(0 0)">
                    <g data-name="Group 988" id="Group_988" transform="translate(0 0)">
                        <path d="M64.657,73.609h-.013a26.621,26.621,0,0,1-13.094-49.8.274.274,0,0,1,.27.478,26.083,26.083,0,1,0,25.7.012.274.274,0,1,1,.271-.477A26.621,26.621,0,0,1,64.657,73.609Z" data-name="Path 15153" fill="none" id="Path_15153" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(-38.032 -23.771)"/>
                        <path d="M105.211,24.309H79.234a.274.274,0,0,1,0-.549h25.976a.274.274,0,1,1,0,.549Z" data-name="Path 15154" fill="none" id="Path_15154" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(-65.597 -23.76)"/>
                    </g>
                </g>
                <text data-name="27" fontFamily="Poppins-Bold, Poppins" fontSize="14.5" fontWeight="700" id="_25" transform="translate(17.5 29.419)"><tspan x="0" y="0">25</tspan></text>
            </g>
        </svg>,
        26: <svg height="48.378" viewBox="0 0 55.285 55.303" width="48.332" xmlns="http://www.w3.org/2000/svg">
            <path d="M-1937,1528h-53v-53h11.005a26.309,26.309,0,0,0,15.494,5,26.311,26.311,0,0,0,15.5-5H-1937v53Z" data-name="Subtraction 96" fill={`${ fill }`} id="Subtraction_96" transform="translate(1991.285 -1473.943)"/>
            <g data-name="Group 1292" id="Group_1292" transform="translate(-17.408 -192.117)">
                <g data-name="Group 1110" id="Group_1110" transform="translate(18.408 193.174)">
                    <path d="M88.371,167.315a30.481,30.481,0,0,1-14.991-3.949.274.274,0,1,1,.27-.477,29.875,29.875,0,0,0,29.441,0,.274.274,0,1,1,.27.477A30.481,30.481,0,0,1,88.371,167.315Z" data-name="Path 15155" fill="none" id="Path_15155" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(-61.669 -162.849)"/>
                    <path d="M90.811,216.086H38.074a.274.274,0,0,1-.274-.274v-52.7a.274.274,0,0,1,.274-.274H49.632a.274.274,0,1,1,0,.549H38.349v52.149H90.536V163.389H79.331a.274.274,0,1,1,0-.549h11.48a.274.274,0,0,1,.274.274v52.7A.274.274,0,0,1,90.811,216.086Z" data-name="Path 15156" fill="none" id="Path_15156" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(-37.8 -162.84)"/>
                </g>
                <text data-name="23" fontFamily="Poppins-Bold, Poppins" fontSize="14.5" fontWeight="700" id="_26" transform="translate(35.551 226.297)"><tspan x="0" y="0">26</tspan></text>
            </g>
        </svg>,
        27: <svg height="45.467" viewBox="0 0 55.253 51.881" width="48.304" xmlns="http://www.w3.org/2000/svg">
            <path d="M-1952.245,1493.668a26.589,26.589,0,0,1-10.414-2.1,26.67,26.67,0,0,1-8.5-5.734,26.665,26.665,0,0,1-5.734-8.5,26.589,26.589,0,0,1-2.1-10.415,26.859,26.859,0,0,1,.812-6.566,26.616,26.616,0,0,1,2.3-5.973A26.984,26.984,0,0,1-1967.6,1445h30.713a26.984,26.984,0,0,1,8.285,9.373,26.616,26.616,0,0,1,2.3,5.973,26.853,26.853,0,0,1,.812,6.566,26.588,26.588,0,0,1-2.1,10.415,26.666,26.666,0,0,1-5.734,8.5,26.669,26.669,0,0,1-8.5,5.734A26.591,26.591,0,0,1-1952.245,1493.668Z" data-name="Subtraction 98" fill={`${ fill }`} id="Subtraction_98" transform="translate(1980.053 -1443.57)"/>
            <g data-name="Group 1300" id="Group_1300" transform="translate(1.001 1.043)">
                <g data-name="Group 1108" id="Group_1108" transform="translate(0 0)">
                    <g data-name="Group 988" id="Group_988" transform="translate(0 0)">
                        <path d="M64.657,73.609h-.013a26.621,26.621,0,0,1-13.094-49.8.274.274,0,0,1,.27.478,26.083,26.083,0,1,0,25.7.012.274.274,0,1,1,.271-.477A26.621,26.621,0,0,1,64.657,73.609Z" data-name="Path 15153" fill="none" id="Path_15153" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(-38.032 -23.771)"/>
                        <path d="M105.211,24.309H79.234a.274.274,0,0,1,0-.549h25.976a.274.274,0,1,1,0,.549Z" data-name="Path 15154" fill="none" id="Path_15154" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(-65.597 -23.76)"/>
                    </g>
                </g>
                <text data-name="27" fontFamily="Poppins-Bold, Poppins" fontSize="14.5" fontWeight="700" id="_27" transform="translate(17.5 29.419)"><tspan x="0" y="0">27</tspan></text>
            </g>
        </svg>,
        28: <svg height="48.378" viewBox="0 0 55.285 55.303" width="48.332" xmlns="http://www.w3.org/2000/svg">
            <path d="M-1937,1528h-53v-53h11.005a26.309,26.309,0,0,0,15.494,5,26.311,26.311,0,0,0,15.5-5H-1937v53Z" data-name="Subtraction 96" fill={`${ fill }`} id="Subtraction_96" transform="translate(1991.285 -1473.943)"/>
            <g data-name="Group 1292" id="Group_1292" transform="translate(-17.408 -192.117)">
                <g data-name="Group 1110" id="Group_1110" transform="translate(18.408 193.174)">
                    <path d="M88.371,167.315a30.481,30.481,0,0,1-14.991-3.949.274.274,0,1,1,.27-.477,29.875,29.875,0,0,0,29.441,0,.274.274,0,1,1,.27.477A30.481,30.481,0,0,1,88.371,167.315Z" data-name="Path 15155" fill="none" id="Path_15155" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(-61.669 -162.849)"/>
                    <path d="M90.811,216.086H38.074a.274.274,0,0,1-.274-.274v-52.7a.274.274,0,0,1,.274-.274H49.632a.274.274,0,1,1,0,.549H38.349v52.149H90.536V163.389H79.331a.274.274,0,1,1,0-.549h11.48a.274.274,0,0,1,.274.274v52.7A.274.274,0,0,1,90.811,216.086Z" data-name="Path 15156" fill="none" id="Path_15156" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(-37.8 -162.84)"/>
                </g>
                <text data-name="23" fontFamily="Poppins-Bold, Poppins" fontSize="14.5" fontWeight="700" id="_28" transform="translate(35.551 226.297)"><tspan x="0" y="0">28</tspan></text>
            </g>
        </svg>,
        29: <svg height="45.467" viewBox="0 0 55.253 51.881" width="48.304" xmlns="http://www.w3.org/2000/svg">
            <path d="M-1952.245,1493.668a26.589,26.589,0,0,1-10.414-2.1,26.67,26.67,0,0,1-8.5-5.734,26.665,26.665,0,0,1-5.734-8.5,26.589,26.589,0,0,1-2.1-10.415,26.859,26.859,0,0,1,.812-6.566,26.616,26.616,0,0,1,2.3-5.973A26.984,26.984,0,0,1-1967.6,1445h30.713a26.984,26.984,0,0,1,8.285,9.373,26.616,26.616,0,0,1,2.3,5.973,26.853,26.853,0,0,1,.812,6.566,26.588,26.588,0,0,1-2.1,10.415,26.666,26.666,0,0,1-5.734,8.5,26.669,26.669,0,0,1-8.5,5.734A26.591,26.591,0,0,1-1952.245,1493.668Z" data-name="Subtraction 98" fill={`${ fill }`} id="Subtraction_98" transform="translate(1980.053 -1443.57)"/>
            <g data-name="Group 1300" id="Group_1300" transform="translate(1.001 1.043)">
                <g data-name="Group 1108" id="Group_1108" transform="translate(0 0)">
                    <g data-name="Group 988" id="Group_988" transform="translate(0 0)">
                        <path d="M64.657,73.609h-.013a26.621,26.621,0,0,1-13.094-49.8.274.274,0,0,1,.27.478,26.083,26.083,0,1,0,25.7.012.274.274,0,1,1,.271-.477A26.621,26.621,0,0,1,64.657,73.609Z" data-name="Path 15153" fill="none" id="Path_15153" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(-38.032 -23.771)"/>
                        <path d="M105.211,24.309H79.234a.274.274,0,0,1,0-.549h25.976a.274.274,0,1,1,0,.549Z" data-name="Path 15154" fill="none" id="Path_15154" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(-65.597 -23.76)"/>
                    </g>
                </g>
                <text data-name="27" fontFamily="Poppins-Bold, Poppins" fontSize="14.5" fontWeight="700" id="_29" transform="translate(17.5 29.419)"><tspan x="0" y="0">29</tspan></text>
            </g>
        </svg>,
        3: <svg height="45.467" viewBox="0 0 55.253 51.881" width="48.304" xmlns="http://www.w3.org/2000/svg">
            <path d="M-1952.245,1493.668a26.589,26.589,0,0,1-10.414-2.1,26.67,26.67,0,0,1-8.5-5.734,26.665,26.665,0,0,1-5.734-8.5,26.589,26.589,0,0,1-2.1-10.415,26.859,26.859,0,0,1,.812-6.566,26.616,26.616,0,0,1,2.3-5.973A26.984,26.984,0,0,1-1967.6,1445h30.713a26.984,26.984,0,0,1,8.285,9.373,26.616,26.616,0,0,1,2.3,5.973,26.853,26.853,0,0,1,.812,6.566,26.588,26.588,0,0,1-2.1,10.415,26.666,26.666,0,0,1-5.734,8.5,26.669,26.669,0,0,1-8.5,5.734A26.591,26.591,0,0,1-1952.245,1493.668Z" data-name="Subtraction 98" fill={`${ fill }`} id="Subtraction_98" transform="translate(1980.053 -1443.57)"/>
            <g data-name="Group 1300" id="Group_1300" transform="translate(1.001 1.043)">
                <g data-name="Group 1108" id="Group_1108" transform="translate(0 0)">
                    <g data-name="Group 988" id="Group_988" transform="translate(0 0)">
                        <path d="M64.657,73.609h-.013a26.621,26.621,0,0,1-13.094-49.8.274.274,0,0,1,.27.478,26.083,26.083,0,1,0,25.7.012.274.274,0,1,1,.271-.477A26.621,26.621,0,0,1,64.657,73.609Z" data-name="Path 15153" fill="none" id="Path_15153" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(-38.032 -23.771)"/>
                        <path d="M105.211,24.309H79.234a.274.274,0,0,1,0-.549h25.976a.274.274,0,1,1,0,.549Z" data-name="Path 15154" fill="none" id="Path_15154" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(-65.597 -23.76)"/>
                    </g>
                </g>
                <text data-name="27" fontFamily="Poppins-Bold, Poppins" fontSize="14.5" fontWeight="700" id="_3" transform="translate(21.025 28.419)"><tspan x="0" y="0">3</tspan></text>
            </g>
        </svg>,
        30: <svg height="48.305" viewBox="0 0 54.619 54.031" width="48.851" xmlns="http://www.w3.org/2000/svg">
            <path d="M0,0H35A17,17,0,0,1,52,17V52a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z" data-name="Rectangle 734" fill={`${ fill }`} id="Rectangle_734" transform="translate(1 0.982)"/>
            <g data-name="Group 1298" id="Group_1298" transform="translate(-214 -760)">
                <g data-name="Group 1099" id="Group_1099" transform="translate(215 761)">
                    <path d="M819.786,1649.32a.274.274,0,0,1-.274-.274,17.323,17.323,0,0,0-17.337-17.337.274.274,0,0,1,0-.549,17.871,17.871,0,0,1,17.886,17.886A.274.274,0,0,1,819.786,1649.32Z" data-name="Path 15134" fill="none" id="Path_15134" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(-767.441 -1631.16)"/>
                    <path d="M748.7,1683.191h-52.07a.274.274,0,0,1-.274-.274v-51.483a.274.274,0,0,1,.274-.274h34.439a.274.274,0,0,1,0,.549H696.909v50.934H748.43v-33.577a.274.274,0,1,1,.549,0v33.851A.274.274,0,0,1,748.7,1683.191Z" data-name="Path 15135" fill="none" id="Path_15135" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(-696.36 -1631.16)"/>
                </g>
                <text data-name="30" fontFamily="Poppins-Bold, Poppins" fontSize="14.5" fontWeight="700" id="_30" transform="translate(243 792)"><tspan x="-11.313" y="0">30</tspan></text>
            </g>
        </svg>,
        31: <svg height="48.305" viewBox="0 0 54.658 54.031" width="48.851" xmlns="http://www.w3.org/2000/svg">
            <rect data-name="Rectangle 735" fill={`${ fill }`} height="52" id="Rectangle_735" transform="translate(2 0.982)" width="52"/>
            <g data-name="Group 1299" id="Group_1299" transform="translate(-155.335 -760)">
                <g data-name="Group 1100" id="Group_1100" transform="translate(156.335 761)">
                    <path d="M537.034,1683.191a.274.274,0,0,1-.274-.274v-51.483a.274.274,0,0,1,.274-.274h52.109a.274.274,0,1,1,0,.549H537.309v51.208A.274.274,0,0,1,537.034,1683.191Z" data-name="Path 15136" fill="none" id="Path_15136" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(-536.76 -1631.16)"/>
                    <path d="M52.024.189H-.086A.274.274,0,0,1-.36-.086.274.274,0,0,1-.086-.36H52.024a.274.274,0,0,1,.274.274A.274.274,0,0,1,52.024.189Z" data-name="Line 24" fill="none" id="Line_24" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(0.36 51.843)"/>
                    <path d="M-.086,51.671A.274.274,0,0,1-.36,51.4V-.086A.274.274,0,0,1-.086-.36a.274.274,0,0,1,.274.274V51.4A.274.274,0,0,1-.086,51.671Z" data-name="Line 25" fill="none" id="Line_25" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(52.469 0.36)"/>
                </g>
                <text data-name="31" fontFamily="Poppins-Bold, Poppins" fontSize="14.5" fontWeight="700" id="_31" transform="translate(183.664 792)"><tspan x="-8.829" y="0">31</tspan></text>
            </g>
        </svg>,
        4: <svg height="45.467" viewBox="0 0 55.253 51.881" width="48.304" xmlns="http://www.w3.org/2000/svg">
            <path d="M-1952.245,1493.668a26.589,26.589,0,0,1-10.414-2.1,26.67,26.67,0,0,1-8.5-5.734,26.665,26.665,0,0,1-5.734-8.5,26.589,26.589,0,0,1-2.1-10.415,26.859,26.859,0,0,1,.812-6.566,26.616,26.616,0,0,1,2.3-5.973A26.984,26.984,0,0,1-1967.6,1445h30.713a26.984,26.984,0,0,1,8.285,9.373,26.616,26.616,0,0,1,2.3,5.973,26.853,26.853,0,0,1,.812,6.566,26.588,26.588,0,0,1-2.1,10.415,26.666,26.666,0,0,1-5.734,8.5,26.669,26.669,0,0,1-8.5,5.734A26.591,26.591,0,0,1-1952.245,1493.668Z" data-name="Subtraction 98" fill={`${ fill }`} id="Subtraction_98" transform="translate(1980.053 -1443.57)"/>
            <g data-name="Group 1300" id="Group_1300" transform="translate(1.001 1.043)">
                <g data-name="Group 1108" id="Group_1108" transform="translate(0 0)">
                    <g data-name="Group 988" id="Group_988" transform="translate(0 0)">
                        <path d="M64.657,73.609h-.013a26.621,26.621,0,0,1-13.094-49.8.274.274,0,0,1,.27.478,26.083,26.083,0,1,0,25.7.012.274.274,0,1,1,.271-.477A26.621,26.621,0,0,1,64.657,73.609Z" data-name="Path 15153" fill="none" id="Path_15153" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(-38.032 -23.771)"/>
                        <path d="M105.211,24.309H79.234a.274.274,0,0,1,0-.549h25.976a.274.274,0,1,1,0,.549Z" data-name="Path 15154" fill="none" id="Path_15154" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(-65.597 -23.76)"/>
                    </g>
                </g>
                <text data-name="27" fontFamily="Poppins-Bold, Poppins" fontSize="14.5" fontWeight="700" id="_4" transform="translate(21.025 28.419)"><tspan x="0" y="0">4</tspan></text>
            </g>
        </svg>,
        5: <svg height="48.378" viewBox="0 0 55.285 55.303" width="48.332" xmlns="http://www.w3.org/2000/svg">
            <path d="M-1937,1528h-53v-53h11.005a26.309,26.309,0,0,0,15.494,5,26.311,26.311,0,0,0,15.5-5H-1937v53Z" data-name="Subtraction 96" fill={`${ fill }`} id="Subtraction_96" transform="translate(1991.285 -1473.943)"/>
            <g data-name="Group 1292" id="Group_1292" transform="translate(-17.408 -192.117)">
                <g data-name="Group 1110" id="Group_1110" transform="translate(18.408 193.174)">
                    <path d="M88.371,167.315a30.481,30.481,0,0,1-14.991-3.949.274.274,0,1,1,.27-.477,29.875,29.875,0,0,0,29.441,0,.274.274,0,1,1,.27.477A30.481,30.481,0,0,1,88.371,167.315Z" data-name="Path 15155" fill="none" id="Path_15155" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(-61.669 -162.849)"/>
                    <path d="M90.811,216.086H38.074a.274.274,0,0,1-.274-.274v-52.7a.274.274,0,0,1,.274-.274H49.632a.274.274,0,1,1,0,.549H38.349v52.149H90.536V163.389H79.331a.274.274,0,1,1,0-.549h11.48a.274.274,0,0,1,.274.274v52.7A.274.274,0,0,1,90.811,216.086Z" data-name="Path 15156" fill="none" id="Path_15156" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(-37.8 -162.84)"/>
                </g>
                <text data-name="23" fontFamily="Poppins-Bold, Poppins" fontSize="14.5" fontWeight="700" id="_5" transform="translate(38.851 226.297)"><tspan x="0" y="0">5</tspan></text>
            </g>
        </svg>,
        6: <svg height="45.467" viewBox="0 0 55.253 51.881" width="48.304" xmlns="http://www.w3.org/2000/svg">
            <path d="M-1952.245,1493.668a26.589,26.589,0,0,1-10.414-2.1,26.67,26.67,0,0,1-8.5-5.734,26.665,26.665,0,0,1-5.734-8.5,26.589,26.589,0,0,1-2.1-10.415,26.859,26.859,0,0,1,.812-6.566,26.616,26.616,0,0,1,2.3-5.973A26.984,26.984,0,0,1-1967.6,1445h30.713a26.984,26.984,0,0,1,8.285,9.373,26.616,26.616,0,0,1,2.3,5.973,26.853,26.853,0,0,1,.812,6.566,26.588,26.588,0,0,1-2.1,10.415,26.666,26.666,0,0,1-5.734,8.5,26.669,26.669,0,0,1-8.5,5.734A26.591,26.591,0,0,1-1952.245,1493.668Z" data-name="Subtraction 98" fill={`${ fill }`} id="Subtraction_98" transform="translate(1980.053 -1443.57)"/>
            <g data-name="Group 1300" id="Group_1300" transform="translate(1.001 1.043)">
                <g data-name="Group 1108" id="Group_1108" transform="translate(0 0)">
                    <g data-name="Group 988" id="Group_988" transform="translate(0 0)">
                        <path d="M64.657,73.609h-.013a26.621,26.621,0,0,1-13.094-49.8.274.274,0,0,1,.27.478,26.083,26.083,0,1,0,25.7.012.274.274,0,1,1,.271-.477A26.621,26.621,0,0,1,64.657,73.609Z" data-name="Path 15153" fill="none" id="Path_15153" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(-38.032 -23.771)"/>
                        <path d="M105.211,24.309H79.234a.274.274,0,0,1,0-.549h25.976a.274.274,0,1,1,0,.549Z" data-name="Path 15154" fill="none" id="Path_15154" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(-65.597 -23.76)"/>
                    </g>
                </g>
                <text data-name="27" fontFamily="Poppins-Bold, Poppins" fontSize="14.5" fontWeight="700" id="_6" transform="translate(21.625 28.419)"><tspan x="0" y="0">6</tspan></text>
            </g>
        </svg>,
        7: <svg height="45.467" viewBox="0 0 55.253 51.881" width="48.304" xmlns="http://www.w3.org/2000/svg">
            <path d="M-1952.245,1493.668a26.589,26.589,0,0,1-10.414-2.1,26.67,26.67,0,0,1-8.5-5.734,26.665,26.665,0,0,1-5.734-8.5,26.589,26.589,0,0,1-2.1-10.415,26.859,26.859,0,0,1,.812-6.566,26.616,26.616,0,0,1,2.3-5.973A26.984,26.984,0,0,1-1967.6,1445h30.713a26.984,26.984,0,0,1,8.285,9.373,26.616,26.616,0,0,1,2.3,5.973,26.853,26.853,0,0,1,.812,6.566,26.588,26.588,0,0,1-2.1,10.415,26.666,26.666,0,0,1-5.734,8.5,26.669,26.669,0,0,1-8.5,5.734A26.591,26.591,0,0,1-1952.245,1493.668Z" data-name="Subtraction 98" fill={`${ fill }`} id="Subtraction_98" transform="translate(1980.053 -1443.57)"/>
            <g data-name="Group 1300" id="Group_1300" transform="translate(1.001 1.043)">
                <g data-name="Group 1108" id="Group_1108" transform="translate(0 0)">
                    <g data-name="Group 988" id="Group_988" transform="translate(0 0)">
                        <path d="M64.657,73.609h-.013a26.621,26.621,0,0,1-13.094-49.8.274.274,0,0,1,.27.478,26.083,26.083,0,1,0,25.7.012.274.274,0,1,1,.271-.477A26.621,26.621,0,0,1,64.657,73.609Z" data-name="Path 15153" fill="none" id="Path_15153" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(-38.032 -23.771)"/>
                        <path d="M105.211,24.309H79.234a.274.274,0,0,1,0-.549h25.976a.274.274,0,1,1,0,.549Z" data-name="Path 15154" fill="none" id="Path_15154" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(-65.597 -23.76)"/>
                    </g>
                </g>
                <text data-name="27" fontFamily="Poppins-Bold, Poppins" fontSize="14.5" fontWeight="700" id="7" transform="translate(22.5 29.419)"><tspan x="0" y="0">7</tspan></text>
            </g>
        </svg>,
        8: <svg height="45.467" viewBox="0 0 55.253 51.881" width="48.304" xmlns="http://www.w3.org/2000/svg">
            <path d="M-1952.245,1493.668a26.589,26.589,0,0,1-10.414-2.1,26.67,26.67,0,0,1-8.5-5.734,26.665,26.665,0,0,1-5.734-8.5,26.589,26.589,0,0,1-2.1-10.415,26.859,26.859,0,0,1,.812-6.566,26.616,26.616,0,0,1,2.3-5.973A26.984,26.984,0,0,1-1967.6,1445h30.713a26.984,26.984,0,0,1,8.285,9.373,26.616,26.616,0,0,1,2.3,5.973,26.853,26.853,0,0,1,.812,6.566,26.588,26.588,0,0,1-2.1,10.415,26.666,26.666,0,0,1-5.734,8.5,26.669,26.669,0,0,1-8.5,5.734A26.591,26.591,0,0,1-1952.245,1493.668Z" data-name="Subtraction 98" fill={`${ fill }`} id="Subtraction_98" transform="translate(1980.053 -1443.57)"/>
            <g data-name="Group 1300" id="Group_1300" transform="translate(1.001 1.043)">
                <g data-name="Group 1108" id="Group_1108" transform="translate(0 0)">
                    <g data-name="Group 988" id="Group_988" transform="translate(0 0)">
                        <path d="M64.657,73.609h-.013a26.621,26.621,0,0,1-13.094-49.8.274.274,0,0,1,.27.478,26.083,26.083,0,1,0,25.7.012.274.274,0,1,1,.271-.477A26.621,26.621,0,0,1,64.657,73.609Z" data-name="Path 15153" fill="none" id="Path_15153" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(-38.032 -23.771)"/>
                        <path d="M105.211,24.309H79.234a.274.274,0,0,1,0-.549h25.976a.274.274,0,1,1,0,.549Z" data-name="Path 15154" fill="none" id="Path_15154" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(-65.597 -23.76)"/>
                    </g>
                </g>
                <text data-name="27" fontFamily="Poppins-Bold, Poppins" fontSize="14.5" fontWeight="700" id="8" transform="translate(21.5 29.419)"><tspan x="0" y="0">8</tspan></text>
            </g>
        </svg>,
        9: <svg height="45.467" viewBox="0 0 55.253 51.881" width="48.304" xmlns="http://www.w3.org/2000/svg">
            <path d="M-1952.245,1493.668a26.589,26.589,0,0,1-10.414-2.1,26.67,26.67,0,0,1-8.5-5.734,26.665,26.665,0,0,1-5.734-8.5,26.589,26.589,0,0,1-2.1-10.415,26.859,26.859,0,0,1,.812-6.566,26.616,26.616,0,0,1,2.3-5.973A26.984,26.984,0,0,1-1967.6,1445h30.713a26.984,26.984,0,0,1,8.285,9.373,26.616,26.616,0,0,1,2.3,5.973,26.853,26.853,0,0,1,.812,6.566,26.588,26.588,0,0,1-2.1,10.415,26.666,26.666,0,0,1-5.734,8.5,26.669,26.669,0,0,1-8.5,5.734A26.591,26.591,0,0,1-1952.245,1493.668Z" data-name="Subtraction 98" fill={`${ fill }`} id="Subtraction_98" transform="translate(1980.053 -1443.57)"/>
            <g data-name="Group 1300" id="Group_1300" transform="translate(1.001 1.043)">
                <g data-name="Group 1108" id="Group_1108" transform="translate(0 0)">
                    <g data-name="Group 988" id="Group_988" transform="translate(0 0)">
                        <path d="M64.657,73.609h-.013a26.621,26.621,0,0,1-13.094-49.8.274.274,0,0,1,.27.478,26.083,26.083,0,1,0,25.7.012.274.274,0,1,1,.271-.477A26.621,26.621,0,0,1,64.657,73.609Z" data-name="Path 15153" fill="none" id="Path_15153" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(-38.032 -23.771)"/>
                        <path d="M105.211,24.309H79.234a.274.274,0,0,1,0-.549h25.976a.274.274,0,1,1,0,.549Z" data-name="Path 15154" fill="none" id="Path_15154" stroke="#000" strokeLinecap="round" strokeWidth="2" transform="translate(-65.597 -23.76)"/>
                    </g>
                </g>
                <text data-name="27" fontFamily="Poppins-Bold, Poppins" fontSize="14.5" fontWeight="700" id="9" transform="translate(21.5 29.419)"><tspan x="0" y="0">9</tspan></text>
            </g>
        </svg>,
    }[id];
}