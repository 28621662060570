import { Box, Typography, capitalize } from "@mui/material";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

import { HourPickerInterface, ValuesForHourPicker } from "models/Date";
import { COLORS } from "ui/variables";

import { HourPicker } from "./HourPicker";

export interface HourPickers {
    end: HourPickerInterface,
    start: HourPickerInterface
}

interface TimeRangeProps {
    hourPickers: HourPickers,
    hourPickersValues: {end: ValuesForHourPicker, start: ValuesForHourPicker},
    isAnyTableSelected: boolean,
    isErrorTablesTimeRanges: boolean,
    isPreview: boolean,
    isStartSelected: boolean,
    isTimeRangeError: boolean
}

export function TimeRange({ hourPickers, hourPickersValues, isAnyTableSelected, isErrorTablesTimeRanges, isPreview, isStartSelected, isTimeRangeError }: TimeRangeProps): ReactElement {
    const { t } = useTranslation();

    if (isErrorTablesTimeRanges) {
        return (
            <Box sx={{ alignItems: "center", display: "flex", flexBasis: "35%", justifyContent: "center" }}>
                <Typography sx={{ color: "error.main" }}>{capitalize(t("common.fetchError"))}</Typography>
            </Box>
        );
    }
    return (
        <Box sx={{ display: "flex", flexBasis: "35%", flexDirection: "column", mt: "-6px" }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                {Object.entries(hourPickers).map(([ key, orgProps ]) => {
                    const props = {
                        disabled: {
                            end: !isStartSelected || isPreview,
                            start: !isAnyTableSelected || isPreview,
                        }[key],
                        field: {
                            end: "end",
                            start: "start",
                        }[key],
                        values: {
                            end: hourPickersValues.end,
                            start: hourPickersValues.start,
                        }[key],
                    };

                    return (
                        <HourPicker
                            key={key}
                            {...orgProps}
                            {...props}
                        />
                    );
                })}
            </Box>
            <Typography sx={{ color: isTimeRangeError ? COLORS.error : COLORS.black, fontSize: 9.6, fontWeight: "bold", mt: .2 }}>
                {capitalize(t("modules.reservations.timeRange"))}{`${ isTimeRangeError ? `: ${ t("common.required") }` : "" }`}
            </Typography>
        </Box>
    );
}
