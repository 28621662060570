import { Box, ListItemText, Typography, capitalize } from "@mui/material";
import moment from "moment";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

import { ReportItem } from "models/Report";
import { List } from "ui/components/list/List";
import { Pagination } from "ui/components/list/Pagination";
import { globalStyles } from "ui/globalStyles";

import { Filters } from "./filters/Filters";
import { useReport } from "./useReport";

const HEADERS = [ "modules.reservations.date", "modules.reservations.amountOfReservations", "modules.reservations.amountOfPeople" ];

export function Report(): ReactElement {
    const { t } = useTranslation();
    const { count, isError, isFetching, onPageChange, onTimeRangeChange, page, report, timeRange } = useReport();

    function renderRow(reportItem: ReportItem): ReactElement {
        return (
            <>
                {([ "date", "people", "reservations" ] as const).map((key, index) => {
                    function getValue(): string {
                        const value = reportItem[key];
                        if (key === "date") {
                            return moment(value).format("DD.MM.YYYY");
                        }
                        return value;
                    }

                    return (
                        <ListItemText
                            key={index}
                            sx={{ "& .MuiTypography-root": key === "date" ? { textAlign: "left !important" } : {} }}>
                            <Typography sx={{ fontWeight: key === "date" ? "bold" : "normal" }}>
                                {getValue()}
                            </Typography>
                        </ListItemText>);
                },
                )}
            </>
        );
    }

    return (
        <Box
            sx={globalStyles.sidePanelWrapper}
        >
            <Filters
                timeRange={timeRange}
                onTimeRangeChange={onTimeRangeChange}
            />
            {isError ?
                <Typography sx={{ alignSelf: "center", color: "error.main", mt: 3 }}>
                    {capitalize(t("modules.reservations.report.fetchError"))}
                </Typography>
                :
                <Box sx={{ border: globalStyles.border, borderTop: "none", flexGrow: 1 }}>
                    <List
                        data={report}
                        headers={HEADERS}
                        isLoading={isFetching}
                        renderItem={renderRow}
                    />

                    {typeof report !== "undefined" &&
                        <Pagination
                            count={count}
                            page={page}
                            onPageChange={onPageChange}
                        />
                    }
                </Box>
            }
        </Box>
    );
}