import { Paper } from "@mui/material";
import moment from "moment";
import { PropsWithChildren, ReactElement } from "react";
import { useFormContext } from "react-hook-form";

import { AutoComplete } from "common/components/form/AutoComplete";
import { HourPickerInterface, ValueForHourPicker } from "models/Date";
import { globalStyles } from "ui/globalStyles";
import { COLORS } from "ui/variables";

export function HourPicker({ disabled, field, onChange, values }: HourPickerInterface): ReactElement {
    const { getValues } = useFormContext();
    const value: string = getValues(field ?? "");

    function getValueForPicker(): ValueForHourPicker | null {
        if (!value || typeof values === "undefined") {
            return null;
        }
        const hourForPicker = values.find(({ date }) => {
            const dateHours = date.hour();
            const dateMinutes = date.minute();
            return dateHours === moment(value).hour() && dateMinutes === moment(value).minute();
        });
        if (typeof hourForPicker === "undefined") {
            return null;
        }
        return hourForPicker;
    }

    return (
        <AutoComplete<ValueForHourPicker>
            PaperComponent={PaperComponent}
            disabled={disabled}
            field={field ?? ""}
            getOptionDisabled={option => option.disabled}
            getOptionLabel={(option) => option.formatted}
            isOptionEqualToValue={(option, value) => moment(value.date).isSame(option.date)}
            noOptionsText="—"
            options={values ?? []}
            sx={{
                "& .Mui-disabled": { WebkitTextFillColor: "rgba(0,0,0,1) !important" },
                "& .Mui-disabled .MuiOutlinedInput-notchedOutline ": { border: `2px dotted ${ COLORS.black } !important` },
                "& .Mui-error .MuiOutlinedInput-notchedOutline ": { border: `2px dotted ${ COLORS.error } !important` },
                "& .MuiOutlinedInput-notchedOutline": { border: `2px dotted ${ COLORS.black } !important`, maxHeight: "40px" },
                "& svg": { display: "none" },
                "&.MuiAutocomplete-root .MuiOutlinedInput-root": { paddingRight: "8px !important", paddingTop: "0px" },
                "&.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input": { paddingBottom: "0px" },
                flexBasis: "45%",
                maxHeight: "40px",
            }}
            textFieldSX={{ input: { cursor: "pointer", fontSize: 16, textAlign: "center" } }}
            value={getValueForPicker()}
            onChange={(value) => {
                if (!value) {
                    return;
                }
                onChange(value.date);
            }}
        />);
}

function PaperComponent({ children }: PropsWithChildren) {
    return (
        <Paper
            sx={{
                "& .MuiAutocomplete-listbox": {
                    "& .MuiAutocomplete-option[aria-selected='true']": {
                        "&.Mui-focused": { bgcolor: COLORS.selected },
                        bgcolor: COLORS.selected,
                    },
                },
                "& .MuiAutocomplete-listbox .MuiAutocomplete-option": { justifyContent: "center" },
                "& .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused": { bgcolor: COLORS.selectedLight },
                bgcolor: COLORS.main,
                border: globalStyles.border,
                display: "flex",
                fontFamily: "Poppins",
                fontSize: 16,
                justifyContent: "center",
            }}
        >
            {children}
        </Paper>
    );
}