import { RequestStatus } from "common/services/HttpService";

export enum AuthStatus {
    LOGGED_IN,
    LOGGED_OUT_AUTH,
    LOGGED_OUT_INAUTH
}

export interface AuthFormProps extends RequestStatus {
    onClick?: () => void
}


export interface LoginValues {
    email: string;
    password: string;
    user: string;
}

export interface CredentialsInterface {
    email: string
    password: string
}

export interface PasswordResetInterface {
    password: string
    passwordMatch: string
}