import { httpService } from "common/services";
import { API_URL } from "config/constants";
import { PaginatedResponse, PaginatedQueryData } from "models/Repository";
import { Reservation, ReservationInput } from "models/Reservation";

let searchQuery: string = "";
export async function fetchReservations(data: PaginatedQueryData): Promise<PaginatedResponse<Reservation> | undefined> {
    if (data.search !== searchQuery && data.paginate.page !== 1) {
        return;
    }
    searchQuery = data.search || "";
    const res = await httpService.request({ data, method: "POST", url: `${ API_URL }reservations` });
    return res.data as PaginatedResponse<Reservation>;
}

export async function postReservation(data: ReservationInput): Promise<any> {
    const res = await httpService.request({ data, method: "POST", url: `${ API_URL }reservation` });
    return res.data;
}

export async function getReservation(id?: string): Promise<Reservation | undefined> {
    if (typeof id === "undefined") {
        return;
    }
    const res = await httpService.request({ method: "GET", url: `${ API_URL }reservation/${ id }` });
    return res.data as Reservation;
}

export async function editReservation(data: ReservationInput, id?: string): Promise<any> {
    if (typeof id === "undefined") {
        return;
    }
    const res = await httpService.request({ data, method: "PUT", url: `${ API_URL }reservation/${ id }` });
    return res.data;
}

export async function deleteReservation(id?: string): Promise<any> {
    if (typeof id === "undefined") {
        return;
    }
    const res = await httpService.request({ method: "DELETE", url: `${ API_URL }reservation/${ id }` });
    return res.data;
}

