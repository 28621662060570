import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { useMutation } from "react-query";

import { LoginValues } from "models/Auth";

import * as controller from "../../controllers/auth";
import * as repository from "../../repositories/tokens";
import { loginValuesTransformer } from "../../transformers/loginValuesTransformer";
import { getLoginValidationSchema } from "../../validators/loginValidationSchema";

interface UseLoginReturn {
    form: UseFormReturn<Partial<LoginValues>>;
    isErrorLogin: boolean,
    isErrorSendPasswordReset: boolean,
    isLoadingLogin: boolean,
    isLoadingSendPasswordReset: boolean,
    isPasswordForgotten: boolean;
    isSuccessSendPasswordReset: boolean,
    onPasswordForgotten: (state: boolean) => void;
    onSubmit: (data: Partial<LoginValues>) => void;
}

export function useLogin(): UseLoginReturn {
    const [ isPasswordForgotten, setIsPasswordForgotten ] = useState(false);
    const { data: tokens, isError: isErrorFetchTokens, isLoading: isLoadingFetchTokens, mutate: mutateFetchTokens } = useMutation(repository.fetchTokens);
    const { isError: isErrorSendPasswordReset, isLoading: isLoadingSendPasswordReset, isSuccess: isSuccessSendPasswordReset, mutate: mutateSendPasswordReset } = useMutation(repository.sendPasswordReset);

    useEffect(() => {
        if (tokens) {
            controller.login(tokens);
        }
    }, [ tokens ]);

    const form = useForm({
        defaultValues: {
            email: "",
            password: "",
            user: "",
        },
        resolver: yupResolver(getLoginValidationSchema(isPasswordForgotten)),
    });

    function onSubmit(formValues: Partial<LoginValues>): void {
        const data = loginValuesTransformer(formValues, isPasswordForgotten);
        if (!isPasswordForgotten) {
            return mutateFetchTokens(data);
        }
        mutateSendPasswordReset(data);
    }

    return {
        form,
        isErrorLogin: isErrorFetchTokens,
        isErrorSendPasswordReset,
        isLoadingLogin: isLoadingFetchTokens,
        isLoadingSendPasswordReset,
        isPasswordForgotten,
        isSuccessSendPasswordReset,
        onPasswordForgotten: (state) => setIsPasswordForgotten(state),
        onSubmit,
    };
}
