import { Button as ButtonMui, ButtonProps as ButtonMuiProps, CircularProgress, SxProps, Theme } from "@mui/material";
import { PropsWithChildren, ReactElement } from "react";

import { globalStyles } from "../globalStyles";
import { COLORS } from "../variables";


interface ButtonProps extends ButtonMuiProps {
    isLoading?: boolean
    onClick?: () => void;
    sx?: SxProps<Theme>;
    type?: ButtonMuiProps["type"];
    variant?: ButtonMuiProps["variant"];
}

export function Button({
    children,
    disabled,
    isLoading,
    onClick,
    sx,
    type = "button",
    variant = "outlined",
}: PropsWithChildren<ButtonProps>): ReactElement {
    return (
        <ButtonMui
            color="primary"
            disabled={disabled}
            sx={{ ...globalStyles.button, ...sx } as SxProps<Theme>}
            type={type}
            variant={variant}
            onClick={onClick}
        >
            {children}
            {isLoading && <CircularProgress size={20} sx={{ color: COLORS.white, ml: 1 }} />}
        </ButtonMui>
    );
}
