import { ReactElement, useContext } from "react";


import { HandlerType } from "models/Date";

import { ReservationsContext } from "../../Reservations";

import { Buttons } from "./Buttons";
import { Calendar } from "./Calendar";
import { HourPicker } from "./HourPicker";

export function DateControl(): ReactElement {
    const { datePanelControl: { handlerType } } = useContext(ReservationsContext);
    function renderHandler(): ReactElement {
        if (handlerType === HandlerType.DATE) {
            return <Calendar/>;
        }
        if (handlerType === HandlerType.HOUR) {
            return <HourPicker/>;
        }
        return <></>;
    }


    return (
        <>
            <Buttons/>
            {renderHandler()}
        </>
    );
}