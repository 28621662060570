import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, Typography, capitalize } from "@mui/material";
import moment, { Moment } from "moment";
import { ReactElement, useContext } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "ui/components/Button";
import { globalStyles } from "ui/globalStyles";
import { COLORS } from "ui/variables";

import { ReservationsContext } from "../../Reservations";


export function Buttons(): ReactElement {
    const { t } = useTranslation();
    const { datePanelControl: { onSelectedEndDateChange, onSelectedStartDateChange, selectedStartDate } } = useContext(ReservationsContext);
    function getNewDate(operation: "add" | "subtract" | "today", unit: "d" | "h"): Moment {
        if (operation === "today") {
            const now: Moment = moment();

            return selectedStartDate.clone()
                .year(now.year())
                .month(now.month())
                .date(now.date());
        }

        const amount = unit === "d" ? 1 : 0.5;
        if (operation === "subtract") {
            return selectedStartDate.clone().subtract(amount, unit);
        }
        return selectedStartDate.clone().add(amount, unit);
    }

    return (
        <Box sx={{ display: "flex", flexBasis: "100%", height: 50 }}>
            {[
                { icon: <ChevronLeftIcon />, operation: "subtract" as const, text: capitalize(t("modules.reservations.previousDay")) },
                { operation: "today" as const, text: t("modules.reservations.today").toUpperCase() },
                { icon: <ChevronRightIcon />, operation: "add" as const, text: capitalize(t("modules.reservations.nextDay")) },
            ].map(({ icon, operation, text }) => {
                const isAdd: boolean = operation === "add";
                const isToday: boolean = operation === "today";

                return <Button key={operation}
                    sx={{
                        ...globalStyles.buttonDate,
                        "&:hover": {
                            bgcolor: COLORS.selected,
                            border: globalStyles.border,
                            borderRight: isAdd ? globalStyles.border : "none",
                            borderTop: "none",
                        },
                        alignItems: "center",
                        borderRight: isAdd ? globalStyles.border : "none",
                        borderTop: "none",
                        display: "flex",
                        flexBasis: isToday ? "15%" : "27.5%",
                        justifyContent: isToday ? "center" : "space-between",
                        textAlign: "right",
                    }}
                    onClick={() => {
                        const newDate: Moment = getNewDate(operation, "d");
                        onSelectedStartDateChange(newDate);
                        onSelectedEndDateChange(newDate.clone().add(30, "m"));
                    }}
                >
                    {icon}
                    <Typography>{text}</Typography>
                </Button>; },
            )}

            {[
                { icon: <KeyboardArrowUpIcon/>, operation: "subtract" as const },
                { icon: <KeyboardArrowDownIcon/>, operation: "add" as const },
            ].map(({ icon, operation }) =>
                <Button
                    key={operation}
                    sx={{
                        ...globalStyles.buttonDate,
                        "&:hover": {
                            bgcolor: COLORS.selected,
                            border: globalStyles.border,
                            borderLeft: "none",
                            borderTop: "none",
                        },
                        borderLeft: "none",
                        borderTop: "none",
                        flexBasis: "15%",
                    }}
                    onClick={() => {
                        const newDate: Moment = getNewDate(operation, "h");

                        if (newDate.hour() < 9 || newDate.isAfter(selectedStartDate.clone().endOf("day"))) {
                            return;
                        }
                        onSelectedStartDateChange(newDate);
                        onSelectedEndDateChange(newDate.clone().add(30, "m"));
                    }}>
                    {icon}
                </Button>,
            )}
        </Box>
    );
}