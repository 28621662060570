import * as yup from "yup";

import { PasswordResetInterface } from "models/Auth";

export const passwordResetValidationSchema: yup.ObjectSchema<PasswordResetInterface> = yup.object({
    password: yup
        .string()
        .required("common.required")
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/, "modules.auth.passwordConstraints"),

    passwordMatch: yup
        .string()
        .required("common.required")
        .oneOf([ yup.ref("password"), "" ], "modules.auth.passwordsDontMatch"),
});