import { Login } from "modules/auth/components/login/Login";
import { PasswordReset } from "modules/auth/components/passwordReset/PasswordReset";
import { Reservations } from "modules/reservations/components/Reservations";

export const ROUTES_LOGGED_OUT_INAUTH = [
    {
        element: <Login />,
        path: "/",
    },
];

export const ROUTES_LOGGED_IN = [
    {
        element: <Reservations />,
        path: "/",
    },
];

export const ROUTES_LOGGED_OUT_AUTH = [
    {
        element: <PasswordReset />,
        path: "/reset-password",
    },
];
