import { Box, CircularProgress, Typography, capitalize } from "@mui/material";
import { ReactElement, useContext } from "react";
import { useTranslation } from "react-i18next";

import { Form } from "common/components/form/Form";
import { TextInput } from "common/components/form/TextInput";
import { UnsavedChangesWarning } from "common/components/form/unsavedChangesWarning/UnsavedChangesWarning";
import { useUnsavedChangesWarning } from "common/components/form/unsavedChangesWarning/useUnsavedChangesWarning";
import { Button } from "ui/components/Button";
import { globalStyles } from "ui/globalStyles";

import { ReservationsContext } from "../Reservations";

import { useDailyMessage } from "./useDailyMessage";

export function DailyMessage(): ReactElement {
    const { reservationsControl: { exitFormWarning, onExitFormWarningChange } } = useContext(ReservationsContext);
    const { t } = useTranslation();

    const { form, isErrorFetching, isErrorSaving, isFetching, isFormDirty, isLoadingSaving, onExit: handleExit, onSubmit } = useDailyMessage();
    const { onCancel, onExit, onShowExitFormWarningChange, showExitFormWarning } = useUnsavedChangesWarning({
        isFormDirty,
        onExit: handleExit,
    });

    return (
        <Box sx={{ border: globalStyles.border, mt: 2, p: 1 }}>
            <UnsavedChangesWarning
                isOpen={showExitFormWarning || exitFormWarning.show}
                onClose={() => {
                    onShowExitFormWarningChange(false);
                    onExitFormWarningChange({ show: false });
                }}
                onConfirm={onExit}

            />
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                    sx={{ fontWeight: "bold", mb: 1.5 }}
                    variant="h3"
                >
                    {t("modules.reservations.dailyMessage.title").toUpperCase()}
                </Typography>
                {isFetching && <CircularProgress size={20}/>}
            </Box>
            <Form form={form} onSubmit={onSubmit}>
                <TextInput
                    multiline
                    field="context"
                    inputProps={{ style: { fontSize: 14.4 } }}
                    rows={7}
                    sx={{
                        ...globalStyles.multilineTextInput,
                        ...globalStyles.textInputDisabledFormatted,
                        width: "100%",
                    }}
                />
                {(isErrorSaving || isErrorFetching) &&
                    <Box sx={{ display: "flex", justifyContent: "center", mt: 1.5 }}>
                        <Typography sx={{ color: "error.main" }}>
                            {capitalize(t(`modules.reservations.dailyMessage.${ isErrorFetching ? "fetchError" : "saveError" }`))}
                        </Typography>
                    </Box>
                }
                <Box sx={{ display: "flex", justifyContent: "space-between", mt: 1.5 }}>
                    <Button
                        disabled={isErrorFetching}
                        isLoading={isLoadingSaving}
                        sx={{ ...globalStyles.buttonBlack, ...globalStyles.buttonControl, ...globalStyles.buttonControlForm, flexGrow: 1, mr: 3 }}
                        type="submit"
                    >
                        {capitalize(t("modules.reservations.dailyMessage.save"))}
                    </Button>
                    <Button
                        sx={{ ...globalStyles.buttonControl, ...globalStyles.buttonControlForm, width: "35%" }}
                        onClick={onCancel}
                    >
                        {capitalize(t("common.cancel"))}
                    </Button>
                </Box>
            </Form>
        </Box>
    );
}
