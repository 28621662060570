import { httpService } from "common/services";
import { API_URL, AUTH_URL } from "config/constants";
import { CredentialsInterface } from "models/Auth";
import { TokensInterface } from "models/Token";

export async function fetchTokens(data: CredentialsInterface): Promise<TokensInterface> {
    const res = await httpService.request({ data, method: "POST", url: `${ AUTH_URL }token` });
    return res.data as TokensInterface;
}

export async function sendPasswordReset(data: CredentialsInterface): Promise<any> {
    const res = await httpService.request({ data, method: "POST", url: `${ API_URL }user/send-password-reset` });
    return res.data;
}

export async function refreshToken(data: {refreshToken: string}): Promise<TokensInterface> {
    const res = await httpService.request({ data, method: "POST", url: `${ AUTH_URL }refresh-token` });
    return res.data as TokensInterface;
}