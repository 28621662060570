import { Box, CircularProgress, Typography, capitalize } from "@mui/material";
import { ReactElement, useContext } from "react";
import { useTranslation } from "react-i18next";

import { Dialog } from "common/components/Dialog";
import { Checkbox } from "common/components/form/Checkbox";
import { Form } from "common/components/form/Form";
import { TextInput } from "common/components/form/TextInput";
import { Mode } from "models/Mode";
import { ReservationIcons } from "models/Reservation";
import { Button } from "ui/components/Button";
import { globalStyles } from "ui/globalStyles";
import { COLORS } from "ui/variables";

import { ReservationsContext } from "../Reservations";

import { Row } from "./Row";
import { TimeRange } from "./timeRange/TimeRange";
import { useReservationForm } from "./useForm";


export function ReservationForm(): ReactElement {
    const { t } = useTranslation();
    const { reservationsControl: { exitFormWarning, isErrorReservation, isErrorTablesTimeRanges, isFetchingReservation, isFormDirty, mode, onExitFormWarningChange, reservationId } } = useContext(ReservationsContext);

    const { form, formatTablesNumsTextInput, hourPickers, hourPickersValues, isAnyTableSelected, isDeletion, isErrorCreation, isErrorDeletion, isErrorEdit, isLoadingCreation, isLoadingDeletion, isLoadingEdit, isPreview, isStartSelected, isTimeRangeError, onCopy, onDelete, onDeletionChange, onEdit, onExit, onSubmit, selectedTables } = useReservationForm();

    const isEdit = mode === Mode.RESERVATION_EDIT;

    // eslint-disable-next-line
    if (((mode === Mode.BROWSING || mode === Mode.BROWSING_ALL_DAY) && typeof reservationId === "undefined") || mode === Mode.PRINTING) {
        return <></>;
    }

    if (isFetchingReservation || isErrorReservation) {
        return <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            {isFetchingReservation ? <CircularProgress /> : <Typography sx={{ color: "error.main" }}>{capitalize(t("common.fetchError"))}</Typography>}
        </Box>;
    }

    return <Box sx={{ border: isEdit || isDeletion ? `2px solid ${ COLORS.selected }` : globalStyles.border, maxHeight: "100%", mt: 2, overflow: "scroll", p: 1 }}>
        <Dialog
            closeText={capitalize(t("common.no"))}
            confirmText={capitalize(t("common.yes"))}
            content={capitalize(t(!isDeletion ? "common.unsavedChangesWarning" : "modules.reservations.reservationDeletionWarning"))}
            isOpen={exitFormWarning.show}
            onClose={() => {
                onExitFormWarningChange({ show: false });
                setTimeout(() => {
                    onDeletionChange(false);
                }, 1000);
            }}
            onConfirm={() => {
                if (isDeletion) {
                    setTimeout(() => {
                        onDeletionChange(false);
                    }, 1000);
                    return onDelete();
                }
                onExit();
            }}
        />
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
                sx={{ fontWeight: "bold", mb: 1.5 }}
                variant="h3"
            >
                {t(isPreview ? "modules.reservations.reservationDetails" : isEdit || isDeletion ? "modules.reservations.reservationEdition" : "modules.reservations.newReservation").toUpperCase()}
            </Typography>
            {(isEdit || isDeletion) &&
                <Typography
                    sx={{ color: "error.main", cursor: "pointer", fontWeight: "bold" }}
                    variant="h4"
                    onClick={() => {
                        onDeletionChange(true);
                        onExitFormWarningChange({ show: true });
                    }}>
                X - {t("modules.reservations.reservationDeletion")}
                </Typography>}
            {isLoadingDeletion && <CircularProgress size={20}/>}
        </Box>
        <Form form={form} onSubmit={onSubmit}>
            <Row>
                <TextInput
                    disabled={isPreview}
                    field="name"
                    helperText={capitalize(t("modules.reservations.nameAndSurname"))}
                    inputProps={{ style: { fontSize: 14.4 } }}
                    sx={{ "& input.MuiInputBase-input.MuiInput-input": { textTransform: "uppercase" }, flexBasis: "60%", ...globalStyles.textInputDisabledFormatted }}
                    variant="standard"
                />
                <TimeRange
                    hourPickers={hourPickers}
                    hourPickersValues={hourPickersValues}
                    isAnyTableSelected={isAnyTableSelected}
                    isErrorTablesTimeRanges={isErrorTablesTimeRanges}
                    isPreview={isPreview}
                    isStartSelected={isStartSelected}
                    isTimeRangeError={isTimeRangeError}
                />
            </Row>
            <Row>
                <TextInput
                    disabled={isPreview}
                    field="instagram"
                    helperText={capitalize(t("modules.reservations.instagram"))}
                    inputProps={{ style: { fontSize: 14.4 } }}
                    sx={{ flexBasis: "60%", ...globalStyles.textInputDisabledFormatted }}
                    variant="standard"
                />
                <TextInput
                    disabled={isPreview}
                    field="tablesNums"
                    formatValue={formatTablesNumsTextInput}
                    helperText={capitalize(t("modules.reservations.tables"))}
                    inputProps={{ style: { fontSize: 14.4 } }}
                    sx={{ flexBasis: "20%", ...globalStyles.textInputDisabledFormatted }}
                    variant="standard"
                />
                <TextInput
                    disabled={!selectedTables.length || isPreview}
                    field="amountOfPeople"
                    helperText={capitalize(t("modules.reservations.amountOfPeople"))}
                    inputProps={{ style: { fontSize: 14.4 } }}
                    max={100}
                    min={1}
                    sx={{ flexBasis: "10%", ...globalStyles.textInputDisabledFormatted }}
                    type="number"
                    variant="standard"
                />
            </Row>
            <Row sx={{ alignItems: "center" }}>
                <TextInput
                    disabled={isPreview}
                    field="phone"
                    helperText={capitalize(t("modules.reservations.phone"))}
                    inputProps={{ style: { fontSize: 14.4 } }}
                    sx={{ flexBasis: "60%", ...globalStyles.textInputDisabledFormatted }}
                    variant="standard"
                />
                <Box sx={{ display: "flex", flexBasis: "35%", flexDirection: "column" }}>
                    {[ "isFirstTime", "thisTable", "inGarden", "isRegularGuest", "isConfirmed" ].map(field => {
                        const icon: string | undefined = {
                            inGarden: ReservationIcons.IN_GARDEN,
                            isFirstTime: ReservationIcons.IS_FIRST_TIME,
                            isRegularGuest: ReservationIcons.IS_REGULAR_GUEST,
                            thisTable: ReservationIcons.THIS_TABLE,
                        }[field];

                        return (
                            <Checkbox
                                key={field}
                                checkboxSX={globalStyles.checkboxDisabledFormatted}
                                disabled={isPreview}
                                field={field}
                                label={`${ icon ?? "" } ${ capitalize(t(`modules.reservations.${ field }`)) }`}
                                sx={{
                                    "& .MuiButtonBase-root": { py: .5 },
                                    "& .MuiTypography-root": { fontSize: 12.8, fontWeight: "bold" },
                                }}
                            />); },
                    )}
                </Box>
            </Row>
            <Row>
                <TextInput
                    multiline
                    disabled={isPreview}
                    field="message"
                    inputProps={{ style: { fontSize: 14.4 } }}
                    rows={3.5}
                    sx={{
                        ...globalStyles.multilineTextInput,
                        ...globalStyles.textInputDisabledFormatted,
                    }}
                />
            </Row>
            {(isErrorCreation || isErrorEdit || isErrorDeletion) && <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Typography sx={{ color: "error.main" }}>
                    {capitalize(t(isErrorCreation ? "modules.reservations.reservationCreationFail" : isErrorEdit ? "modules.reservations.reservationEditionFail" : "modules.reservations.reservationDeletionFail"))}
                </Typography>
            </Box>}
            <Box sx={{ display: "flex", flexBasis: "100%", justifyContent: "space-between", mt: 1.5 }}>
                {!isPreview &&
                    <Button
                        isLoading={!isEdit ? isLoadingCreation : isLoadingEdit}
                        sx={{ ...globalStyles.buttonBlack, ...globalStyles.buttonControl, ...globalStyles.buttonControlForm, flexGrow: 1, mr: 3, px: 0 }}
                        type="submit">
                        {capitalize(t(isEdit || isDeletion ? "modules.reservations.saveChanges" : "modules.reservations.addReservation"))}
                    </Button>}

                {isPreview &&
                    <>
                        {[ {
                            mr: 1,
                            onClick: onEdit,
                            text: "modules.reservations.editReservation",
                        },
                        {
                            mr: 2,
                            onClick: onCopy,
                            text: "modules.reservations.copyReservation",
                        },
                        ].map(({ mr, onClick, text }) =>
                            <Button key={text}
                                sx={{ ...globalStyles.buttonBlack, ...globalStyles.buttonControl, ...globalStyles.buttonControlForm, flexGrow: 1, mr }}
                                onClick={onClick}
                            >
                                {capitalize(t(text))}
                            </Button>,
                        )}
                    </>
                }

                <Button sx={{ ...globalStyles.buttonControl, ...globalStyles.buttonControlForm, width: isPreview ? "25%" : "35%" }} onClick={() => {
                    if (isFormDirty && !isPreview) {
                        return onExitFormWarningChange({ show: true });
                    }
                    onExit();
                }}>
                    {capitalize(t("common.cancel"))}
                </Button>
            </Box>
        </Form>
    </Box>;
}
