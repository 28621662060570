import { Box, capitalize } from "@mui/material";
import { ReactElement, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";

import { HandlerType } from "models/Date";
import { Mode } from "models/Mode";
import { Button } from "ui/components/Button";
import { globalStyles } from "ui/globalStyles";

import { DailyMessage } from "./dailyMessage/DailyMessage";
import { DatePanel } from "./datePanel/DatePanel";
import { ReservationForm } from "./form/Form";
import { ReservationsContext } from "./Reservations";


export function Controller(): ReactElement {
    const { t } = useTranslation();
    const ref = useRef<HTMLDivElement>();
    const { datePanelControl: { onHandlerTypeChange }, reservationsControl: { mode, onModeChange, reservationId } } = useContext(ReservationsContext);

    // eslint-disable-next-line
    const showAddReservationButton = (mode !== Mode.DAILY_MESSAGE_EDIT && ([Mode.BROWSING, Mode.BROWSING_ALL_DAY].some(m => m === mode) && typeof reservationId === 'undefined')) || mode === Mode.PRINTING

    return (
        <Box
            ref={ref}
            sx={globalStyles.sidePanelWrapper}
            onClick={(e) => {
                if (e.target === ref.current) {
                    onHandlerTypeChange(HandlerType.BUTTONS);
                }
            }}>
            <DatePanel />

            {showAddReservationButton &&
                <Button
                    sx={{ ...globalStyles.buttonBlack, ...globalStyles.buttonControl, height: 60, mt: 2, width: "100%" }}
                    onClick={() => onModeChange(Mode.RESERVATION_CREATION)}
                >
                    {capitalize(t("modules.reservations.addReservation"))}
                </Button>
            }
            {
                mode === Mode.DAILY_MESSAGE_EDIT
                    ? <DailyMessage/>
                    : <ReservationForm/>
            }
        </Box>
    );
}