import { useDebounce } from "@uidotdev/usehooks";
import { Moment } from "moment";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";

import { DEBOUNCE_DEFAULT_VALUE } from "config/constants";
import { Report, ReportAggregationPeriod, ReportTimeRange, ReportPredefinedTimeRange } from "models/Report";

import * as repository from "../../repositories/report";

export interface UseReportReturn {
    count: number
    isError: boolean,
    isFetching: boolean,
    onPageChange: (page: number) => void,
    onTimeRangeChange: (timeRange: Partial<ReportTimeRange>) => void,
    page: number,
    report: Report | undefined,
    timeRange: ReportTimeRange
}

export function useReport(): UseReportReturn {
    const [ timeRange, setTimeRange ] = useState<ReportTimeRange>({
        customRange: {
            from: null,
            to: null,
        },
        from: null,
        isCustom: false,
        predefinedTimeRangeType: ReportPredefinedTimeRange.NONE,
        to: null,
    });
    const [ page, setPage ] = useState<number>(1);

    const from = useDebounce(getReportTimeRange().from, DEBOUNCE_DEFAULT_VALUE);
    const to = useDebounce(getReportTimeRange().to, DEBOUNCE_DEFAULT_VALUE);

    const fromFormatted = getDateFormatted(from);
    const toFormatted = getDateFormatted(to);

    const { data, isError, isFetching } = useQuery({
        queryFn: () => repository.getReport({ aggregation: ReportAggregationPeriod.DAY, from: fromFormatted, to: toFormatted }),
        queryKey: [ "report", fromFormatted, toFormatted ],
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        setPage(1);
    }, [ from, to ]);

    const count = data?.length ?? 0;
    const report = data?.slice((page - 1) * 10, page * 10);

    function onTimeRangeChange(timeRange: Partial<ReportTimeRange>): void {
        setTimeRange((prevState) => ({ ...prevState, ...timeRange }));
    }

    function onPageChange(page: number): void {
        setPage(page);
    }

    function getReportTimeRange(): {from: Moment | null, to: Moment | null} {
        if (!timeRange.isCustom) {
            return { from: timeRange.from, to: timeRange.to };
        }

        const to = timeRange.customRange.from?.isAfter(timeRange.customRange.to) ? null : timeRange.customRange.to;
        return { from: timeRange.customRange.from, to };
    }

    function getDateFormatted(date: Moment | null): string | null {
        if (!date || !date.isValid()) {
            return null;
        }
        return date.format("YYYY-MM-DD");
    }

    return {
        count,
        isError,
        isFetching,
        onPageChange,
        onTimeRangeChange,
        page,
        report,
        timeRange,
    };
}