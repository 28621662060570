import { Box, capitalize } from "@mui/material";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

import * as controller from "modules/auth/controllers/auth";
import { Button } from "ui/components/Button";
import { globalStyles } from "ui/globalStyles";


export function MainMenu(): ReactElement {
    const { t } = useTranslation();

    return (
        <Box sx={{ "@media print": { display: "none" }, alignItems: "center", display: "flex" }}>
            <Button
                sx={globalStyles.topMenuButton}
                onClick={controller.logout}
            >
                {capitalize(t("common.logout"))}
            </Button>
        </Box>
    );
}