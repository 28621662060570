import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, UseFormReturn } from "react-hook-form";
import { useMutation } from "react-query";

import { RequestStatus } from "common/services/HttpService";
import { PasswordResetInterface } from "models/Auth";

import * as controller from "../../controllers/auth";
import * as repository from "../../repositories/auth";
import { passwordResetValidationSchema } from "../../validators/passwordResetValidationSchema";

interface UseLoginReturn extends RequestStatus {
    form: UseFormReturn<PasswordResetInterface>;
    onSubmit: (data: PasswordResetInterface) => void;
}

export function usePasswordReset(): UseLoginReturn {
    const token: string | null = new URLSearchParams(window.location.search).get("token");
    const { isError, isLoading, isSuccess, mutate } = useMutation({
        mutationFn: (formData: PasswordResetInterface) => repository.resetPassword(formData, token ?? ""),
        onSuccess: () => {
            setTimeout(() => {
                controller.reloadApp();
            }, 1000);
        },
    });

    const form = useForm({
        defaultValues: {
            password: "",
            passwordMatch: "",
        },
        resolver: yupResolver(passwordResetValidationSchema),
    });

    function onSubmit(data: PasswordResetInterface): void {
        mutate(data);
    }

    return {
        form,
        isError,
        isLoading,
        isSuccess,
        onSubmit,
    };
}
