import { Box, SxProps } from "@mui/material";
import { PropsWithChildren, ReactElement } from "react";

interface RowProps {
    sx?: SxProps,
}


export function Row({ children, sx }: PropsWithChildren<RowProps>): ReactElement {
    return (
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1.5, position: "relative", ...sx }}>
            {children}
        </Box>);
}
