import { Typography, capitalize } from "@mui/material";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

import { TextInput } from "common/components/form/TextInput";
import { AuthFormProps } from "models/Auth";
import { globalStyles } from "ui/globalStyles";

import * as Styled from "../styles/Auth.styled";

import { ActionButtons } from "./ActionButtons";

export function LoginForm({ isError, isLoading, onClick }: AuthFormProps): ReactElement {
    const { t } = useTranslation();

    return (
        <>
            <Styled.AuthContent>
                <TextInput
                    field="user"
                    helperText={t("modules.auth.user")}
                    inputProps={globalStyles.authTextInputProps}
                    inputlabelstyles={globalStyles.authTextInputLabelStyles}
                    sx={{ mt: 2.5 }}
                    variant="standard"
                />
                <TextInput
                    field="password"
                    helperText={t("modules.auth.password")}
                    inputProps={globalStyles.authTextInputProps}
                    inputlabelstyles={globalStyles.authTextInputLabelStyles}
                    sx={{ mt: 1 }}
                    type="password"
                    variant="standard"
                />
            </Styled.AuthContent>

            {isError && <Typography sx={{ color: "error.main" }}>{t("modules.auth.loginError")}</Typography>}

            <ActionButtons
                buttons={[
                    { onClick, text: capitalize(t("modules.auth.forgotPassword")), type: "button" },
                    { isLoading, sx: globalStyles.buttonBlack, text: capitalize(t("modules.auth.login")), type: "submit" },
                ]}
            />
        </>
    );
}
