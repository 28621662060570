import { Box, SxProps, Typography } from "@mui/material";
import { Moment } from "moment";
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";


import { DatePicker } from "./DatePicker";

type DateRange = {
    from: Moment | null
    to: Moment | null
}

interface DateRangePickerProps {
    defaultValues?: DateRange
    onDateRangeChange: (dateRange: DateRange) => void,
    sx?: SxProps
}

export function DateRangePicker({ defaultValues, onDateRangeChange, sx }: DateRangePickerProps): ReactElement {
    const { t } = useTranslation();
    const [ dateRange, setDateRange ] = useState<DateRange>({ from: defaultValues?.from ?? null, to: defaultValues?.to ?? null });

    useEffect(() => {
        handleValidate();
    }, [ dateRange.from ]);

    useEffect(() => {
        onDateRangeChange(dateRange);
    }, [ dateRange ]);

    function handleValidate(): void {
        if (dateRange.from?.isAfter(dateRange.to)) {
            handleSetDateRange("to", null);
        }
    }

    function handleSetDateRange(type: "from" | "to", value: Moment | null): void {
        setDateRange(prev => ({ ...prev, [type]: value }));
    }

    return (
        <Box sx={{ alignItems: "center", display: "flex", justifyContent: "space-between", ...sx }}>
            <DatePicker
                maxDate={dateRange.to ?? undefined}
                sx={{ flexGrow: 1, mr: 2 }}
                value={dateRange.from}
                onChange={(value) => handleSetDateRange("from", value)}
            />
            <Typography
                sx={{ fontWeight: "bold" }}
                variant="h3"
            >
                {t("common.hyphen")}
            </Typography>
            <DatePicker
                minDate={dateRange.from ?? undefined}
                sx={{ flexGrow: 1, ml: 2 }}
                value={dateRange.to}
                onBlur={handleValidate}
                onChange={(value) => handleSetDateRange("to", value)}
            />
        </Box>
    );
}