import axios_, { AxiosInstance } from "axios";
import { QueryClient } from "react-query";

import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from "config/token";
import * as controller from "modules/auth/controllers/auth";


import { storageService } from ".";

export interface RequestStatus {
    isError?: boolean
    isLoading?: boolean,
    isSuccess?: boolean
}

export class HttpService {
    public queryClient: QueryClient;

    public request: AxiosInstance;

    constructor() {
        this.queryClient = new QueryClient();
        this.request = this.initializeAxiosInstance();
    }

    private initializeAxiosInstance(): AxiosInstance {
        const token: string | null = storageService.getItem(ACCESS_TOKEN_KEY);
        const axios: AxiosInstance = axios_.create({ headers: { Authorization: token ? `Bearer ${ token }` : undefined } });

        axios.interceptors.response.use(undefined, async (error: any) => {
            const originalConfig: any = error.config;
            const isUnauthorized: boolean = error.response.status === 401;

            // if unauthorized, refresh access token and redo request
            if (isUnauthorized && !originalConfig._retry) {
                originalConfig._retry = true;
                const refreshToken = storageService.getItem(REFRESH_TOKEN_KEY);
                if (refreshToken) {
                    await controller.refreshAccess(refreshToken);
                    return axios(originalConfig);
                }
            }

            // if refresh access token request is unauthorized or unauthorized despite valid access token - logout
            if (isUnauthorized && !originalConfig.url.includes("reset-password")) {
                return controller.logout();
            }

            return Promise.reject(error);
        });

        return axios;
    }
}