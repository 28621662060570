import { yupResolver } from "@hookform/resolvers/yup";
import moment, { Moment } from "moment";
import { useContext, useEffect, useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";

import { ValuesForHourPicker } from "models/Date";
import { Mode } from "models/Mode";
import { reservationDefaultValues, ReservationInput, TimeRange } from "models/Reservation";
import { Status, TableState } from "models/Table";

import { generateHoursForPicker, getInitStartDate, getUtcDateFormatted } from "../../helpers/date";
import * as repository from "../../repositories/reservations";
import { reservationFormValidationSchema } from "../../validators/reservationFormValidationSchema";
import { ReservationsContext } from "../Reservations";

import { HourPickers } from "./timeRange/TimeRange";

interface UseReservationFormReturn {
    form: UseFormReturn<ReservationInput>;
    formatTablesNumsTextInput: (value: string) => string,
    hourPickers: HourPickers,
    hourPickersValues: {end: ValuesForHourPicker, start: ValuesForHourPicker},
    isAnyTableSelected: boolean,
    isDeletion: boolean,
    isErrorCreation: boolean;
    isErrorDeletion: boolean,
    isErrorEdit: boolean,
    isLoadingCreation: boolean,
    isLoadingDeletion: boolean,
    isLoadingEdit: boolean,
    isPreview: boolean,
    isStartSelected: boolean,
    isTimeRangeError: boolean
    onCopy: () => void,
    onDelete: () => void,
    onDeletionChange: (state: boolean) => void,
    onEdit: () => void,
    onExit: () => void,
    onSubmit: (data: ReservationInput) => void;
    selectedTables: Array<TableState>
}

export function useReservationForm(): UseReservationFormReturn {
    const queryClient = useQueryClient();
    const { datePanelControl: { onSelectedEndDateChange, onSelectedStartDateChange, selectedStartDate }, reservationsControl: { exitFormWarning, mode, onExitFormWarningChange, onFormDirtyChange, onModeChange, onReservationIdChange, onSelectedTablesChange, onTablesStateChange, reservation, reservationId, tablesState, tablesTimeRanges } } = useContext(ReservationsContext);
    const { isError: isErrorCreation, isLoading: isLoadingCreation, mutate: mutateCreation, reset: resetCreation } = useMutation({
        mutationFn: repository.postReservation,
        onSuccess: onExit,
    });
    const { isError: isErrorEdit, isLoading: isLoadingEdit, mutate: mutateEdit, reset: resetEdit } = useMutation({
        mutationFn: (data: ReservationInput) => repository.editReservation(data, reservation?.id),
        onSuccess: onExit,
    });
    const { isError: isErrorDeletion, isLoading: isLoadingDeletion, mutate: mutateDeletion, reset: resetDeletion } = useMutation({
        mutationFn: () => repository.deleteReservation(reservation?.id),
        onSuccess: onExit,
    });

    const form = useForm({
        defaultValues: reservationDefaultValues,
        resolver: yupResolver(reservationFormValidationSchema),
    });

    const start: string = form.watch().start;
    const end: string = form.watch().end;
    const instagram: string | null | undefined = form.watch().instagram;

    const hourPickers: HourPickers = {
        /* eslint-disable */
        start: { onChange: (date: Moment) => onHourChange(date, "start") },
        end: { onChange: (date: Moment) => onHourChange(date, "end") },
        /* eslint-enable */
    };

    const [ hourPickersValues, setHourPickersValues ] = useState<{end: ValuesForHourPicker, start: ValuesForHourPicker}>({
        end: getHourPickerEndHours(),
        start: getHourPickerStartHours(),
    });
    const [ isDeletion, setIsDeletion ] = useState<boolean>(false);

    const selectedTables: Array<TableState> = tablesState?.filter(({ status }) => status === Status.SELECTED) ?? [];
    const isAnyTableSelected: boolean = selectedTables.length > 0;
    const selectedTableNumsFromTextInput: string = form.watch().tablesNums ?? "";
    const freeTables: Array<TableState> = tablesState?.filter(({ status }) => status !== Status.BOOKED) ?? [];

    // eslint-disable-next-line
    const isPreview: boolean = [Mode.RESERVATION_PREVIEW, Mode.RESERVATION_PREVIEW_FROM_SEARCH, Mode.BROWSING_ALL_DAY].some(m => mode === m) || (mode === Mode.BROWSING && typeof reservation !== "undefined");
    const isCopy: boolean = mode === Mode.RESERVATION_CREATION && typeof reservation !== "undefined";

    const isFormDirty: boolean = !!Object.keys(form.formState.dirtyFields).filter(key => key !== "tablesNums").length;
    const isTimeRangeError: boolean = !!form.formState.errors.start;

    let timeout: NodeJS.Timeout;

    useEffect(() => {
        if (start && end) {
            for (const field of [ "start", "end" ] as const) {
                form.clearErrors(field);
            }
        }
    }, [ start, end ]);

    useEffect(() => {
        if (instagram) {
            form.trigger("name");
        }
    }, [ instagram ]);

    useEffect(() => {
        onFormDirtyChange(isFormDirty);
    }, [ isFormDirty ]);

    useEffect(() => {
        if (mode === Mode.RESERVATION_CREATION) {
            form.reset();
            if (isCopy) {
                for (const key of [ "name", "instagram", "phone" ]) {
                    form.setValue(key as keyof ReservationInput, reservation?.[key as keyof typeof reservation] as string | null | undefined);
                }
            }
        }
    }, [ mode ]);

    useEffect(() => {
        // eslint-disable-next-line
        if (!isPreview || typeof reservation === "undefined" || (isPreview && !!Object.keys(form.formState.dirtyFields).filter(key => key !== "tablesNums").length)) {
            return;
        }
        form.setValue("tables", reservation.tables.map(({ id }) => id));
        form.setValue("tablesNums", reservation.tables.map(({ number }) => number).sort((a, b) => a - b).join(", "));
        for (const [ key, value ] of Object.entries(reservation).filter(([ key ]) =>
            ![ "createdAt", "id", "updatedAt", "tables" ].includes(key),
        )) {
            form.setValue(key as keyof ReservationInput, value);
        }
        for (const key of [ "start", "end" ] as const) {
            onHourChange(moment(reservation[key]), key, false);
        }
    }, [ reservation, mode ]);

    useEffect(() => {
        if (!tablesState || isPreview) {
            return;
        }

        clearTimeout(timeout);
        timeout = setTimeout(() => {
            if (!selectedTableNumsFromTextInput) {
                return onSelectedTablesChange([]);
            }

            const freeTablesNums: Array<number> = freeTables.map(({ tableNum }) => tableNum);
            const validTablesNumsFromTextInput: Array<number> = [ ...new Set(selectedTableNumsFromTextInput.split(",").map(num => +num.trim()).filter(tableNum => freeTablesNums.includes(tableNum)).sort((a, b) => a - b)) ];
            onSelectedTablesChange(validTablesNumsFromTextInput);
            form.setValue("tablesNums", validTablesNumsFromTextInput.join(", "), { shouldDirty: true });
            form.setValue("tables", freeTables.filter(({ tableNum }) => validTablesNumsFromTextInput.includes(tableNum)).map(({ id }) => id));
        }, 1000);

        return () => {
            clearTimeout(timeout);
        };
    }, [ selectedTableNumsFromTextInput, mode ]);

    useEffect(() => {
        if (isPreview) {
            return;
        }
        const tableNums = tablesState?.filter(({ status }) => status === Status.SELECTED).map(({ tableNum }) => tableNum).sort((a, b) => a - b).join(", ") ?? "";
        form.setValue("tablesNums", tableNums, { shouldDirty: true, shouldValidate: !!tableNums });
    }, [ tablesState ]);

    useEffect(() => {
        if (isPreview) {
            return;
        }
        // keep in sync with selectedStartDate
        const selectedStartDateWithoutHours = { date: selectedStartDate.get("date"), month: selectedStartDate.get("month"), year: selectedStartDate.get("year") };

        if (start) {
            form.setValue("start", getUtcDateFormatted(selectedStartDate));
        }
        if (end) {
            form.setValue("end", getUtcDateFormatted(moment(end).clone().set(selectedStartDateWithoutHours).add(!moment(end).hours() ? 1 : 0, "day")));
        }
    }, [ selectedStartDate ]);

    useEffect(() => {
        function resetHours(): void {
            for (const type of [ "start", "end" ] as const) {
                form.resetField(type);
            }
        }

        setHourPickersValues({ end: getHourPickerEndHours(), start: getHourPickerStartHours() });

        if (isPreview) {
            return;
        }

        const areSelectedHoursValid: boolean | undefined = tablesTimeRanges?.some(timeRange => moment(timeRange.start).isSameOrBefore(moment(start)) && moment(timeRange.end).isAfter(moment(start)) && (end ? moment(timeRange.end).isSameOrAfter(moment(end)) : true));

        if (typeof areSelectedHoursValid === "undefined" || !moment(tablesTimeRanges?.[0].start).startOf("day").isSame(moment(start).startOf("day"))) {
            return;
        }

        if (!areSelectedHoursValid) {
            resetHours();
        }
    }, [ tablesTimeRanges, mode, selectedStartDate, start ]);

    useEffect(() => {
        setHourPickersValues({ ...hourPickersValues, end: getHourPickerEndHours() });
        if (moment(start).isAfter(moment(end)) || moment(start).isSame(moment(end)) || !form.watch().start) {
            form.setValue("end", "");
        }
    }, [ start ]);

    function onHourChange(date: Moment, type: "start" | "end", shouldDirty = true): void {
        form.setValue(type, getUtcDateFormatted(date), { shouldDirty });
        if (type === "start") {
            return onSelectedStartDateChange(date);
        }
        onSelectedEndDateChange(date);
    }

    function getHourPickerStartHours(): ValuesForHourPicker {
        return generateHoursForPicker(selectedStartDate).map(item => ({
            ...item,
            disabled: !tablesTimeRanges?.some(({ end, start }) => item.date.isBetween(start, end) || item.date.isSame(start)),
        }));
    }

    function getHourPickerEndHours(): ValuesForHourPicker {
        const selectedStartHour: Moment = moment(start);
        const timeRange: TimeRange | undefined = tablesTimeRanges?.find(({ end, start }) => selectedStartHour.isBetween(start, end) || selectedStartHour.isSame(start));
        return generateHoursForPicker(selectedStartDate, 49).map(item =>
            ({
                ...item,
                disabled: !item.date.isAfter(start) || item.date.isAfter(timeRange?.end),
            }));
    }

    function formatTablesNumsTextInput(value: string): string {
        const sanitizedValue: string = value
            .replace(/ {2,}/g, " ")
            .replace(/,{2,}/g, ",")
            .replace(/,\s*,/g, ",");

        if (/^[0-9, ]*$/.test(sanitizedValue)) {
            return sanitizedValue;
        }
        return form.watch().tablesNums;
    }

    function markBookedTablesByReservation(): void {
        if (typeof reservationId !== "undefined") {
            onTablesStateChange(tablesState?.map(tableState => ({ ...tableState, status: reservation?.tables.map(({ number }) => number).includes(tableState.tableNum) ? Status.SELECTED : tableState.status })) ?? []);
        }
    }

    function onEdit(): void {
        markBookedTablesByReservation();
        onModeChange(Mode.RESERVATION_EDIT);
    }

    function onCopy(): void {
        const initStartDate: Moment = getInitStartDate();
        markBookedTablesByReservation();
        form.resetField("end");
        onSelectedStartDateChange(initStartDate);
        onSelectedEndDateChange(initStartDate.clone().add(30, "m"));
        onModeChange(Mode.RESERVATION_CREATION);
        onFormDirtyChange(true);
    }

    function onSubmit(data: ReservationInput): void {
        if (mode === Mode.RESERVATION_CREATION) {
            return mutateCreation(data);
        }
        mutateEdit(data);
    }

    function onDelete(): void {
        mutateDeletion();
    }

    function onDeletionChange(state: boolean): void {
        setIsDeletion(state);
    }

    function onExit(): void {
        function clearMap(): void {
            onTablesStateChange(tablesState?.map(tableState => ({ ...tableState, reservations: [], status: Status.INACTIVE })) ?? []);
        }

        form.reset();
        resetCreation();
        resetEdit();
        resetDeletion();
        onSelectedEndDateChange(undefined);
        onReservationIdChange({ id: undefined, shouldRefetch: false });
        if (exitFormWarning.show) {
            onExitFormWarningChange({ modeToBeSet: Mode.BROWSING, show: false });
        }

        if (exitFormWarning.modeToBeSet === Mode.SEARCHING || exitFormWarning.modeToBeSet === Mode.REPORT_BROWSING) {
            clearMap();
            return onModeChange(exitFormWarning.modeToBeSet);
        }

        if (exitFormWarning.modeToBeSet === Mode.BROWSING_ALL_DAY) {
            onTablesStateChange(tablesState?.map(tableState => ({ ...tableState, status: Status.INACTIVE })) ?? []);
            return onModeChange(Mode.BROWSING_ALL_DAY);
        }

        queryClient.removeQueries({ queryKey: "tables" });
        queryClient.removeQueries({ queryKey: "reservation" });
        queryClient.removeQueries({ queryKey: "tablesTimeRanges" });
        if (mode === Mode.BROWSING_ALL_DAY) {
            return;
        }
        if (mode === Mode.RESERVATION_PREVIEW_FROM_SEARCH) {
            clearMap();
            return onModeChange(Mode.SEARCHING);
        }
        onModeChange(exitFormWarning.modeToBeSet);
    }

    return {
        form,
        formatTablesNumsTextInput,
        hourPickers,
        hourPickersValues,
        isAnyTableSelected,
        isDeletion,
        isErrorCreation,
        isErrorDeletion,
        isErrorEdit,
        isLoadingCreation,
        isLoadingDeletion,
        isLoadingEdit,
        isPreview,
        isStartSelected: !!start,
        isTimeRangeError,
        onCopy,
        onDelete,
        onDeletionChange,
        onEdit,
        onExit,
        onSubmit,
        selectedTables,
    };
}