import { LoginValues, CredentialsInterface } from "models/Auth";

export function loginValuesTransformer(
    data: Partial<LoginValues>,
    isEmail: boolean,
): CredentialsInterface {
    return Object.keys(data)
        .filter((key) => isEmail ? key === "email" : key !== "email")
        .reduce((acc, next) => {
            const key = next as keyof typeof data;
            return { ...acc, [key === "user" ? "email" : key]: data[key] };
        }, {} as CredentialsInterface);
}
