import { capitalize } from "@mui/material";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

import { Dialog } from "../../Dialog";

export interface UnsavedChangesWarningProps {
    isOpen: boolean
    onClose: () => void
    onConfirm: () => void
}

export function UnsavedChangesWarning({ isOpen, onClose, onConfirm }: UnsavedChangesWarningProps): ReactElement {
    const { t } = useTranslation();

    return (
        <Dialog
            closeText={capitalize(t("common.no"))}
            confirmText={capitalize(t("common.yes"))}
            content={capitalize(t("common.unsavedChangesWarning"))}
            isOpen={isOpen}
            onClose={onClose}
            onConfirm={onConfirm}
        />
    );
}
