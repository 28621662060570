import { Box } from "@mui/material";
import { ReactElement } from "react";

import { DailyMessage } from "./DailyMessage";
import { Messages } from "./Messages";
import { Notes } from "./Notes";
import ReservationsList from "./ReservationsList";


export function Printable(): ReactElement {
    return (
        <Box sx={{ "@media print": { display: "block" }, display: "none", fontWeight: "bold", ml: 2, mt: 2 }}>
            <Notes />
            <DailyMessage/>
            <Messages />
            <ReservationsList/>
        </Box>
    );
}