import { useContext } from "react";

import { Mode } from "models/Mode";
import { Status, TableState } from "models/Table";

import { ReservationsContext } from "../Reservations";


interface UseMapReturn {
    onReservationClick: (id: string) => void,
    onTableClick: (tableState: TableState) => void,
}

export function useMap(): UseMapReturn {
    const { reservationsControl: { isFormDirty, mode, onExitFormWarningChange, onModeChange, onReservationIdChange, onTablesStateChange, tablesState } } = useContext(ReservationsContext);

    function onTableClick({ reservations, status, tableNum }: TableState): void {
        if (mode === Mode.SEARCHING || mode === Mode.REPORT_BROWSING) {
            return;
        }

        if (mode === Mode.RESERVATION_CREATION || mode === Mode.RESERVATION_EDIT) {
            if (status === Status.BOOKED) {
                return;
            }
            return onTablesStateChange([ { status: status === Status.SELECTED ? Status.INACTIVE : Status.SELECTED, tableNum } ]);
        }

        if (status === Status.INACTIVE) {
            if (mode === Mode.BROWSING_ALL_DAY) {
                return;
            }
            if (mode === Mode.DAILY_MESSAGE_EDIT && isFormDirty) {
                return onExitFormWarningChange({ modeToBeSet: Mode.RESERVATION_CREATION, show: true, tableState: { reservations, status, tableNum } });
            }
            onTablesStateChange(tablesState?.map(tableState => ({ ...tableState, status: tableState.tableNum === tableNum ? Status.SELECTED : tableState.reservations.length ? Status.BOOKED : Status.INACTIVE })) ?? []);
            return onModeChange(Mode.RESERVATION_CREATION);
        }

        if (status === Status.BOOKED) {
            if (mode === Mode.BROWSING_ALL_DAY) {
                return;
            }
            if (mode === Mode.DAILY_MESSAGE_EDIT && isFormDirty) {
                return onExitFormWarningChange({ modeToBeSet: Mode.RESERVATION_PREVIEW, reservationId: reservations[0].id, show: true });
            }
            onTablesStateChange(tablesState?.map(tableState => ({ ...tableState, status: tableState.status === Status.SELECTED ? tableState.reservations.length ? Status.BOOKED : Status.INACTIVE : tableState.status })) ?? []);
            onReservationIdChange({ id: reservations[0].id, shouldRefetch: true });
            onModeChange(Mode.RESERVATION_PREVIEW);
        }
    }

    function onReservationClick(id: string): void {
        if ([ Mode.BROWSING_ALL_DAY, Mode.BROWSING, Mode.DAILY_MESSAGE_EDIT ].some(m => m === mode)) {
            if (mode === Mode.DAILY_MESSAGE_EDIT) {
                if (!isFormDirty) {
                    onModeChange(Mode.RESERVATION_PREVIEW);
                } else {
                    return onExitFormWarningChange({ modeToBeSet: Mode.RESERVATION_PREVIEW, reservationId: id, show: true });
                }
            }
            onReservationIdChange({ id, shouldRefetch: true });
        }
    }

    return { onReservationClick, onTableClick };
}