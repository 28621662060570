import { Box, Typography } from "@mui/material";
import moment from "moment";
import { ReactElement, createContext } from "react";

import { LOCALE } from "config/constants";

import { UseDatePanelReturn, useDatePanel } from "./datePanel/useDatePanel";
import { Map } from "./map/Map";
import { Printable } from "./printable/Printable";
import { SidePanel } from "./SidePanel";
import { TopControls } from "./topControls/TopControls";
import { UseReservationsReturn, useReservations } from "./useReservations";


export function Reservations(): ReactElement {
    const datePanelControl = useDatePanel();
    const reservationsControl = useReservations({
        queryData: {
            end: datePanelControl.end,
            start: datePanelControl.start,
        },
    });

    return (
        <ReservationsContext.Provider value={{ datePanelControl, reservationsControl }}>
            <TopControls/>
            <Box sx={{ "@media print": { display: "flex" }, alignItems: "flex-end", display: "none", position: "absolute", right: 24, top: 6 }}>
                <Typography
                    sx={{ fontSize: 24, fontWeight: "bold" }}
                >
                    {moment(datePanelControl.selectedStartDate).locale(LOCALE).format("ddd DD.MM.YYYY")}
                </Typography>
            </Box>
            <Box sx={{ "@media print": { height: "unset" }, display: "flex", height: "100%", minHeight: 0 }}>
                <Map/>
                <SidePanel/>
            </Box>
            <Printable/>
        </ReservationsContext.Provider>
    );
}

export const ReservationsContext = createContext({}) as unknown as React.Context<{datePanelControl: UseDatePanelReturn, reservationsControl: UseReservationsReturn}>;