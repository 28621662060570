export const COLORS = {
    black: "#000",
    booked: "#f088a3",
    error: "#d32f2f",
    inactive: "none",
    lightGray: "rgba(224, 224, 224, 1)",
    main: "#FCF1E5",
    placeholder: "#333",
    selected: "#BC9D72",
    selectedLight: "#F4E5D4",
    white: "#fff",
};