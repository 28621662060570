import { ReactElement } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { AutoComplete as AutoCompleteUI, AutoCompleteProps as AutoCompletePropsUI } from "ui/components/AutoComplete";


interface AutoCompleteProps<T> extends AutoCompletePropsUI<T> {
    field: string
}

export function AutoComplete<T>(props: AutoCompleteProps<T>): ReactElement {
    const { control } = useFormContext();

    return (
        <Controller
            control={control}
            name={props.field}
            render={({ field, fieldState: { error } }) =>
                <AutoCompleteUI
                    {...props}
                    controllerField={field}
                    error={error}
                />
            }
        />
    );
}