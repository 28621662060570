import { Box, FormControlLabel, SxProps, Theme, Typography } from "@mui/material";
import { ReactElement } from "react";
import { Controller, useFormContext } from "react-hook-form";

import { Checkbox as CheckboxUI, CheckboxProps as CheckboxUIProps } from "ui/components/Checkbox";
import { COLORS } from "ui/variables";

interface CheckboxProps extends CheckboxUIProps {
    checkboxSX?: SxProps<Theme>,
    field: string;
    label?: string;
    sx?: SxProps<Theme>;
}

export function Checkbox({ checkboxSX, disabled, field, label, sx }: CheckboxProps): ReactElement {
    const { control } = useFormContext();

    return (
        <Box sx={sx}>
            <FormControlLabel
                control={
                    <Controller
                        control={control}
                        name={field}
                        render={({ field }) =>
                            <CheckboxUI
                                controllerField={field}
                                disabled={disabled}
                                sx={checkboxSX}
                                value={field.value}
                            />
                        }
                    />
                }
                disabled={disabled}
                label={<Typography sx={{ color: COLORS.black }}>{label}</Typography>}
                sx={{ userSelect: "none" }}
            />
        </Box>
    );
}