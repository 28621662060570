import { Typography } from "@mui/material";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";


export function Notes(): ReactElement {
    const { t } = useTranslation();

    return (
        <Typography
            sx={{ fontWeight: "bold" }}
            variant="h3"
        >
            {`${ t("modules.reservations.dailyMessage.title") }:`.toUpperCase()}
        </Typography>
    );
}