import { ReactElement } from "react";

import { Form } from "common/components/form/Form";


import { AuthWrapper } from "../AuthWrapper";

import { LoginForm } from "./LoginForm";
import { PasswordForgottenForm } from "./PasswordForgottenForm";
import { useLogin } from "./useLogin";

export function Login(): ReactElement {
    const { form, isErrorLogin, isErrorSendPasswordReset, isLoadingLogin, isLoadingSendPasswordReset, isPasswordForgotten, isSuccessSendPasswordReset, onPasswordForgotten, onSubmit } = useLogin();

    function handleIsPasswordForgotten() {
        onPasswordForgotten(!isPasswordForgotten);
    }

    return (
        <AuthWrapper>
            <Form form={form} onSubmit={onSubmit}>
                {!isPasswordForgotten ?
                    <LoginForm isError={isErrorLogin} isLoading={isLoadingLogin} onClick={handleIsPasswordForgotten} />
                    :
                    <PasswordForgottenForm isError={isErrorSendPasswordReset} isLoading={isLoadingSendPasswordReset} isSuccess={isSuccessSendPasswordReset} onClick={handleIsPasswordForgotten} />
                }
            </Form>
        </AuthWrapper>
    );
}
