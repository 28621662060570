import { ReactElement, useContext } from "react";


import { Mode } from "models/Mode";

import { Controller } from "./Controller";
import { Report } from "./report/Report";
import { ReservationsContext } from "./Reservations";
import { Search } from "./search/Search";

export function SidePanel(): ReactElement {
    const { reservationsControl: { mode, previousMode } } = useContext(ReservationsContext);

    function renderContent(): ReactElement {
        /* eslint-disable */
        if (mode === Mode.SEARCHING || (mode === Mode.PRINTING && previousMode === Mode.SEARCHING)) {
            return <Search/>;
        }
        if (mode === Mode.REPORT_BROWSING || (mode === Mode.PRINTING && previousMode === Mode.REPORT_BROWSING)) {
            return <Report/>;
        }
        /* eslint-enable */
        return <Controller/>;
    }

    return renderContent();
}