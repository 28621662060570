import { Moment } from "moment";

export enum HandlerType {
    DATE,
    HOUR,
    BUTTONS
}

export type ValueForHourPicker = {date: Moment, disabled: boolean, formatted: string}

export type ValuesForHourPicker = Array<ValueForHourPicker>

export interface HourPickerInterface {
    disabled?: boolean,
    field?: string
    onChange: (date: Moment) => void,
    values?: ValuesForHourPicker
}