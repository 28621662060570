import { DateCalendar as DateCalendarMui } from "@mui/x-date-pickers";
import { Moment } from "moment";
import { ReactElement } from "react";

import { globalStyles } from "../../globalStyles";

import { getDayFormatted } from "./helpers";

interface DateCalendarProps {
    onDateChange: (date: Moment | null) => void
    value: Moment
}

export function DateCalendar({ onDateChange, value }: DateCalendarProps): ReactElement {
    return (
        <DateCalendarMui
            slots={{ day: getDayFormatted }}
            sx={{
                "& .MuiPickersFadeTransitionGroup-root": { fontSize: 14.4 },
                ...globalStyles.calendarDay,
                ...globalStyles.calendarLayout,
            }}
            value={value}
            onChange={onDateChange}
        />
    );
}