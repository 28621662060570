import { Dialog as DialogMui, DialogActions, DialogContent, DialogContentText, Box } from "@mui/material";
import { ReactElement } from "react";

import { Button } from "ui/components/Button";
import { globalStyles } from "ui/globalStyles";
import { COLORS } from "ui/variables";


interface DialogProps {
    closeText: string,
    confirmText: string,
    content: string,
    isOpen: boolean,
    onClose: () => void,
    onConfirm: () => void,
}

export function Dialog({ closeText, confirmText, content, isOpen, onClose, onConfirm }: DialogProps): ReactElement {
    return (
        <DialogMui open={isOpen} onClose={onClose}>
            <Box sx={{ alignItems: "center", bgcolor: COLORS.black, display: "flex", minHeight: "350px" }}>
                <Box sx={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
                    <DialogContent sx={{ flexGrow: 0 }}>
                        <DialogContentText sx={{ color: COLORS.white, fontSize: 28.8, fontWeight: "bold", textAlign: "center" }}>
                            {content}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions sx={{ display: "flex", justifyContent: "space-between", mt: 3, width: "55%" }}>
                        <Button sx={{ ...globalStyles.buttonControl, ...globalStyles.buttonWhite, minWidth: 130 }} onClick={onConfirm}>
                            {confirmText}
                        </Button>
                        <Button sx={{ ...globalStyles.buttonControl, ...globalStyles.buttonWhite, minWidth: 130 }} onClick={onClose}>
                            {closeText}
                        </Button>
                    </DialogActions>
                </Box>
            </Box>
        </DialogMui>);
}
