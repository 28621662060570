// eslint-disable-next-line
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import pl from "locales/pl.json";

// eslint-disable-next-line
i18n.use(initReactI18next).init({
    fallbackLng: "pl",
    interpolation: { escapeValue: false },
    lng: "pl",
    resources: { pl: { translation: pl } },
});

export default i18n;
