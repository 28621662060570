import { useState } from "react";

interface UseUnsavedChangesWarningInterface {
    isFormDirty: boolean,
    onExit?: () => void
}

interface UseUnsavedChangesWarningReturn {
    onCancel: () => void,
    onExit: () => void,
    onShowExitFormWarningChange: (state: boolean) => void,
    showExitFormWarning: boolean
}

export function useUnsavedChangesWarning({ isFormDirty, onExit }: UseUnsavedChangesWarningInterface): UseUnsavedChangesWarningReturn {
    const [ showExitFormWarning, setShowExitFormWarning ] = useState<boolean>(false);

    function handleShowExitFormWarningChange(state: boolean): void {
        setShowExitFormWarning(state);
    }

    function handleExit(): void {
        setShowExitFormWarning(false);
        onExit?.();
    }

    function handleCancel(): void {
        if (isFormDirty) {
            return handleShowExitFormWarningChange(true);
        }
        handleExit();
    }

    return {
        onCancel: handleCancel,
        onExit: handleExit,
        onShowExitFormWarningChange: handleShowExitFormWarningChange,
        showExitFormWarning,
    };
}
