import { SxProps, TextField as TextFieldMui, TextFieldVariants, Theme } from "@mui/material";
import { ChangeEvent, HTMLInputTypeAttribute, ReactElement } from "react";
import { ControllerRenderProps, FieldError, FieldValues } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { globalStyles } from "ui/globalStyles";

import { COLORS } from "../variables";

type onChangeEvent = ChangeEvent<HTMLInputElement | HTMLTextAreaElement>

export interface TextFieldProps {
    controllerField?: ControllerRenderProps<FieldValues, string>
    disabled?: boolean
    error?: FieldError
    formatValue?: (value: string) => string,
    formatVisualValue?: (value: any) => string | number,
    helperText?: string;
    inputProps?: Record<string, unknown>,
    inputlabelstyles?: Record<string, number | string>,
    label?: string;
    max?: number,
    min?: number,
    multiline?: boolean,
    onChange?: (event: onChangeEvent) => void,
    readOnly?: boolean,
    rows?: number,
    sx?: SxProps<Theme>;
    type?: HTMLInputTypeAttribute;
    value?: string | number,
    variant?: TextFieldVariants;
}

export function TextField(props: TextFieldProps): ReactElement {
    const { t } = useTranslation();
    const { controllerField, formatValue, formatVisualValue, ...restProps } = props;

    return (
        <TextFieldMui
            {...controllerField}
            {...restProps}
            InputLabelProps={{ style: { color: COLORS.placeholder, ...props.inputlabelstyles } }}
            InputProps={{ inputProps: { max: props.max, min: props.min }, readOnly: props.readOnly, ...props.inputProps }}
            error={!!props.error}
            helperText={typeof props.helperText !== "undefined" && `${ props.helperText }${ props.error ? `: ${ t(props.error.message ?? "") }` : "" }`}
            sx={{ ...globalStyles.textInput, ...props.sx }}
            value={formatVisualValue?.(props.controllerField?.value ?? "") ?? props?.controllerField?.value ?? props?.value ?? ""}
            onChange={(event: onChangeEvent) => {
                if (props.type === "number") {
                    const value = Number(event.target.value);
                    if (typeof props.max !== "undefined" && value > props.max) {
                        event.target.value = props.max.toString();
                    }
                    if (typeof props.min !== "undefined" && value < props.min) {
                        event.target.value = props.min.toString();
                    }
                }

                if (formatValue) {
                    props.controllerField?.onChange(formatValue(event.target.value));
                } else {
                    props.controllerField?.onChange(event);
                }
                props.onChange?.(event);
            }}
        />
    );
}
