import { MenuItem, Select, SxProps } from "@mui/material";
import { ReactElement } from "react";
import { ControllerRenderProps, FieldValues } from "react-hook-form";

import { globalStyles } from "../globalStyles";
import { COLORS } from "../variables";

interface DataInterface {
    id?: string;
    name?: string;
    value: string | number | undefined;
}

export interface DropdownProps {
    controllerField?: ControllerRenderProps<FieldValues, string>
    data: Array<DataInterface>
    label?: string
    onChange: (value: DataInterface["value"]) => void
    sx?: SxProps,
    value?: DataInterface["value"]
}

export function Dropdown({ controllerField, data, label, onChange, sx, value }: DropdownProps): ReactElement {
    return (
        <Select
            {...controllerField}
            MenuProps={{
                PaperProps: {
                    sx: {
                        "& .MuiMenuItem-root": {
                            "&.Mui-selected": { bgcolor: COLORS.selected },
                            "&:hover": { bgcolor: COLORS.selectedLight },
                            color: COLORS.black,
                            fontSize: 14.4,
                            fontWeight: 400,
                            py: 2,
                        },
                        bgcolor: COLORS.main,
                        borderRadius: 0,
                    },
                },
            }}
            label={label}
            sx={{
                "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                border: globalStyles.border,
                borderRadius: 0,
                fontSize: 14.4,
                ...sx,
            }}
            value={controllerField?.value ?? value}
            variant="outlined"
            onChange={(e) => onChange(e.target.value)}
        >
            {data.map(({ name, value }) =>
                <MenuItem
                    key={value}
                    value={value}
                >
                    {name}
                </MenuItem>,
            )}
        </Select>
    );
}