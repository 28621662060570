import { v4 as uuidv4 } from "uuid";

import { httpService } from "common/services";
import { API_URL } from "config/constants";
import { GetReportInput, Report, ReportItem } from "models/Report";

export async function getReport(data: GetReportInput): Promise<Report> {
    const res = await httpService.request({ data, method: "POST", url: `${ API_URL }reservations-report` });
    return res.data.map((item: ReportItem) => ({ ...item, id: uuidv4() })) as Report;
}

