
import { Moment } from "moment";
import { useEffect, useState } from "react";

import { HandlerType } from "models/Date";

import { getInitStartDate, getUtcDateFormatted } from "../../helpers/date";

export interface UseDatePanelReturn {
    end: string,
    handlerType: HandlerType,
    onHandlerTypeChange: (newHandlerType: HandlerType) => void,
    onSelectedEndDateChange: (date?: Moment) => void,
    onSelectedStartDateChange: (date: Moment) => void,
    selectedStartDate: Moment,
    start: string
}

export function useDatePanel(): UseDatePanelReturn {
    const [ handlerType, setHandlerType ] = useState<HandlerType>(HandlerType.BUTTONS);
    const [ selectedStartDate, setSelectedStartDate ] = useState<Moment>(getInitStartDate());
    const [ selectedEndDate, setSelectedEndDate ] = useState<Moment | undefined>();

    function onKeyDown(event: KeyboardEvent) {
        if (event.key !== "Escape") {
            return;
        }
        setHandlerType(HandlerType.BUTTONS);
    }

    useEffect(() => {
        window.addEventListener("keydown", onKeyDown);

        return () => window.removeEventListener("keydown", onKeyDown);
    }, []);


    function onHandlerTypeChange(newHandlerType: HandlerType) {
        if (newHandlerType === handlerType) {
            return setHandlerType(HandlerType.BUTTONS);
        }
        setHandlerType(newHandlerType);
    }

    function onSelectedStartDateChange(date: Moment) {
        setSelectedStartDate(date);
    }

    function onSelectedEndDateChange(date?: Moment) {
        setSelectedEndDate(date);
    }

    function getEndDate() {
        if (typeof selectedEndDate === "undefined") {
            return getUtcDateFormatted(selectedStartDate.clone().add(30, "m"));
        }
        if (selectedStartDate.isAfter(selectedEndDate)) {
            const selectedStartDateWithoutHours = { date: selectedStartDate.get("date"), month: selectedStartDate.get("month"), year: selectedStartDate.get("year") };
            return getUtcDateFormatted(selectedEndDate.clone().set(selectedStartDateWithoutHours));
        }
        return getUtcDateFormatted(selectedEndDate);
    }

    return {
        end: getEndDate(),
        handlerType,
        onHandlerTypeChange,
        onSelectedEndDateChange,
        onSelectedStartDateChange,
        selectedStartDate,
        start: getUtcDateFormatted(selectedStartDate),
    };
}