import moment from "moment";
import { useContext } from "react";
import { useQueryClient } from "react-query";

import { Mode } from "models/Mode";
import { Reservation } from "models/Reservation";

import { ReservationsContext } from "../Reservations";

interface UseSearchReturn {
    onReservationClick: (reservation: Reservation) => void,
}

export function useSearch(): UseSearchReturn {
    const queryClient = useQueryClient();
    const { datePanelControl: { onSelectedEndDateChange, onSelectedStartDateChange }, reservationsControl: { onModeChange, onReservationIdChange } } = useContext(ReservationsContext);

    function onReservationClick(reservation: Reservation): void {
        onReservationIdChange({ id: reservation.id, shouldRefetch: true });
        queryClient.removeQueries({ queryKey: "tables" });
        onSelectedStartDateChange(moment(reservation.start));
        onSelectedEndDateChange(moment(reservation.end));
        onModeChange(Mode.RESERVATION_PREVIEW_FROM_SEARCH);
    }

    return { onReservationClick };
}