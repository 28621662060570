import { Box } from "@mui/material";
import { ReactElement } from "react";


import { HandlerType } from "models/Date";


import { Date } from "./Date";


export function Info(): ReactElement {
    return (
        <Box sx={{ display: "flex", height: 100 }}>
            {[ HandlerType.DATE, HandlerType.HOUR ].map(type =>
                <Date
                    key={type}
                    handlerType={type}
                />)}
        </Box>
    );
}