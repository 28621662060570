import "./index.css";

import { ThemeOptions, createTheme } from "@mui/material";

import { COLORS } from "./variables";

const lineHeight = 1.3;

export const theme: ThemeOptions = createTheme({
    palette: {
        primary: {
            contrastText: "#242105",
            main: COLORS.black,
        },
    },
    typography: {
        body1: {
            fontSize: 11.2,
            lineHeight,
        },
        body2: {
            fontSize: 9,
            lineHeight,
        },
        caption: {
            fontSize: 9.6,
            lineHeight,
        },
        fontFamily: "Poppins",
        fontSize: 14,
        h1: {
            fontSize: 28.8,
            fontWeight: 900,
            lineHeight,
        },
        h2: {
            fontSize: 28.8,
            lineHeight,
        },
        h3: {
            fontSize: 14.4,
            lineHeight,
        },
        h4: {
            fontSize: 12.8,
            lineHeight,
        },
        h5: {
            fontSize: 11.2,
            lineHeight,
        },
    },
});
