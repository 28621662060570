import moment from "moment";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";

import { DailyMessage } from "models/DailyMessage";
import { BrowsingAllDayTime, Mode } from "models/Mode";
import { Reservation, TimeRange } from "models/Reservation";
import { Status, TableState } from "models/Table";

import { getUtcDateFormatted } from "../helpers/date";
import { getReservationsSortedByStartDate } from "../helpers/reservations";
import * as dailyMessageRepository from "../repositories/dailyMessage";
import * as tablesRepository from "../repositories/tables";

interface UseTablesInterface {
    browsingAllDayTime: BrowsingAllDayTime
    mode: Mode,
    onModeChange: (mode: Mode) => void,
    previousMode?: Mode,
    queryData: TimeRange,
    reservation?: Reservation,
    reservationId?: string
    selectedStartDate: string
}


export interface UseTablesReturn {
    dailyMessagePrintable: DailyMessage | null | undefined,
    isErrorDailyMessagePrintable: boolean,
    isErrorTables: boolean,
    isErrorTablesTimeRanges: boolean,
    isLoadingTables: boolean,
    onSelectedTablesChange: (selectedTablesIds: Array<number>) => void,
    onTablesStateChange: (tablesState: Array<{status: Status, tableNum: number}>) => void,
    selectedTableIds: Array<string>,
    tablesState?: Array<TableState>,
    tablesTimeRanges?: Array<TimeRange>
}

export function useTables({ browsingAllDayTime, mode, onModeChange, previousMode, queryData, reservation, reservationId }: UseTablesInterface): UseTablesReturn {
    let refetchTablesInterval: NodeJS.Timer;

    const [ isLoadingTables, setIsLoadingTables ] = useState<boolean>(true);
    const [ isPollingTables, setIsPollingTables ] = useState<boolean>(false);
    const [ tablesState, setTablesState ] = useState<Array<TableState> | undefined>();

    const [ tablesTimeRanges, setTablesTimeRanges ] = useState<Array<TimeRange> | undefined>();

    const [ dailyMessagePrintable, setDailyMessagePrintable ] = useState<DailyMessage | null | undefined>();

    const selectedTableIds = (tablesState?.filter(({ status }) => status === Status.SELECTED).map(({ id }) => id) ?? []).sort();

    const isBrowsingAllDay = mode === Mode.BROWSING_ALL_DAY;
    const isBrowsingAllDayMorning = browsingAllDayTime === BrowsingAllDayTime.MORNING;
    const isSearching = mode === Mode.SEARCHING;
    const isReportBrowsing = mode === Mode.REPORT_BROWSING;
    const isPreviewFromSearching = mode === Mode.RESERVATION_PREVIEW_FROM_SEARCH;
    const isPrinting = mode === Mode.PRINTING;
    const isDailyMessageEdit = mode === Mode.DAILY_MESSAGE_EDIT;

    const tablesQueryEnabled = !(isSearching || isReportBrowsing);

    const { data: dataTables, isError: isErrorTables, isFetching: isFetchingTables, refetch: refetchTables } = useQuery({
        enabled: tablesQueryEnabled,
        queryFn: () => tablesRepository.fetchTables({
            end: getDataTablesEndDate(),
            start: getDataTablesStartDate(),
        }),
        queryKey: [ "tables", browsingAllDayTime, isBrowsingAllDay, isSearching, isReportBrowsing, isPreviewFromSearching, isPrinting, isDailyMessageEdit ],
        refetchOnWindowFocus: false,
    });

    const { data: dataTablesTimeRanges, isError: isErrorTablesTimeRanges } = useQuery({
        enabled: !isDailyMessageEdit,
        queryFn: () => tablesRepository.getTablesTimeRanges({
            excludedReservation: mode === Mode.RESERVATION_EDIT ? reservationId : undefined,
            mode,
            start: getDateFormatted({ date: queryData.start, hour: 9 }),
            tableIds: selectedTableIds,
        }),
        queryKey: [ "tablesTimeRanges", selectedTableIds, moment(queryData.start).startOf("day"), mode ],
        refetchInterval: 20000,
        refetchOnWindowFocus: false,
    });

    const { data: dataDailyMessagePrintable, isError: isErrorDailyMessagePrintable, isFetching: isFetchingDailyMessagePrintable } = useQuery({
        enabled: isPrinting,
        queryFn: () => dailyMessageRepository.getDailyMessage(moment(queryData.start).format("YYYY-MM-DD")),
        queryKey: [ "dailyMessage", queryData.start ],
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        if (dataTables) {
            setTablesState(getTablesInitState());
        }
    }, [ dataTables ]);

    useEffect(() => {
        if (tablesQueryEnabled) {
            refetchTables();
        }
    }, [ queryData.start, queryData.end ]);

    useEffect(() => {
        if (tablesQueryEnabled && !isPrinting) {
            refetchTablesInterval = setInterval(() => {
                setIsPollingTables(true);
                refetchTables();
            }, 30000);
        }

        return () => {
            clearInterval(refetchTablesInterval);
        };
    }, [ mode ]);

    useEffect(() => {
        if (isPollingTables) {
            return setIsPollingTables(false);
        }
        if (isFetchingTables) {
            return setIsLoadingTables(true);
        }
        setTimeout(() => {
            setIsLoadingTables(false);
        }, 1);
    }, [ isFetchingTables ]);

    useEffect(() => {
        if (dataTablesTimeRanges) {
            setTablesTimeRanges(dataTablesTimeRanges);
        }
    }, [ dataTablesTimeRanges ]);

    useEffect(() => {
        setDailyMessagePrintable(dataDailyMessagePrintable);
    }, [ dataDailyMessagePrintable ]);

    useEffect(() => {
        if (!selectedTableIds.length) {
            setTablesTimeRanges(undefined);
        }
    }, [ selectedTableIds.length ]);

    useEffect(() => {
        if (isPrinting && ![ isFetchingTables, isLoadingTables, isErrorTables, isFetchingDailyMessagePrintable, isErrorDailyMessagePrintable ].some(state => !!state)) {
            window.print();
        }

        window.addEventListener("afterprint", () => onPrintFinish());

        return () => window.removeEventListener("afterprint", () => onPrintFinish());
    }, [ mode, isLoadingTables, isFetchingTables, isErrorTables ]);


    function getTablesInitState(): Array<TableState> {
        return (dataTables ?? []).map(({ id, number, reservations }) => {
            const actualTableState: TableState | undefined = tablesState?.find(({ tableNum }) => tableNum === number);

            function getStatus(): Status {
                if (mode === Mode.RESERVATION_PREVIEW_FROM_SEARCH && reservation?.tables.map(table => table.number).includes(number)) {
                    return Status.SELECTED;
                }

                if (isBrowsingAllDay || isPrinting) {
                    return Status.INACTIVE;
                }

                if (typeof actualTableState === "undefined" || actualTableState.status !== Status.SELECTED || [ Mode.BROWSING, Mode.DAILY_MESSAGE_EDIT ].some(m => m === mode)) {
                    return (reservations ?? []).length ? Status.BOOKED : Status.INACTIVE;
                }

                // eslint-disable-next-line
                if (!(reservations ?? []).length || typeof reservation !== "undefined" || (mode === Mode.RESERVATION_PREVIEW && actualTableState.status === Status.SELECTED)) {
                    return Status.SELECTED;
                }

                return Status.BOOKED;
            }

            return {
                id,
                reservations: !isPrinting ? reservations ?? [] : getReservationsSortedByStartDate(reservations ?? []).slice(0, 3),
                status: getStatus(),
                tableNum: number,
            };
        });
    }

    function onSelectedTablesChange(selectedTables: Array<number>): void {
        setTablesState(prevState => prevState?.map(table => {
            const actualStatus = table.status;
            if (!selectedTables.length) {
                return { ...table, status: actualStatus === Status.SELECTED ? Status.INACTIVE : actualStatus };
            }
            const newStatus = table.status === Status.SELECTED ? Status.INACTIVE : table.status;
            return { ...table, status: selectedTables.includes(table.tableNum) ? Status.SELECTED : newStatus };
        },
        ));
    }

    function onTablesStateChange(newTablesState: Array<{ status: Status, tableNum: number }>) {
        setTablesState(tablesState?.map(table => {
            const updatedTable = newTablesState.find(({ tableNum }) => table?.tableNum === tableNum);
            if (updatedTable) {
                return { ...table, ...updatedTable };
            }
            return table;
        }));
    }

    function onPrintFinish(): void {
        if (typeof previousMode !== "undefined" && (previousMode === Mode.BROWSING || previousMode === Mode.BROWSING_ALL_DAY)) {
            return onModeChange(previousMode);
        }
        if ([ Mode.SEARCHING, Mode.REPORT_BROWSING ].some(m => m === previousMode)) {
            if (typeof previousMode === "undefined") {
                return;
            }
            onTablesStateChange(tablesState?.map(tableState => ({ ...tableState, reservations: [], status: Status.INACTIVE })) ?? []);
            return onModeChange(previousMode);
        }
        onModeChange(Mode.BROWSING);
    }

    function getDateFormatted({ date, hour }: { date: string, hour?: number}): string {
        return getUtcDateFormatted(moment(date).clone().set({ hour, minute: 0 }));
    }

    function getDataTablesStartDate(): string {
        const morning: string = getDateFormatted({ date: queryData.start, hour: 9 });
        const evening: string = getDateFormatted({ date: queryData.start, hour: 17 });

        if (isBrowsingAllDay) {
            return isBrowsingAllDayMorning ? morning : evening;
        }
        if (isPrinting) {
            return morning;
        }
        return queryData.start;
    }

    function getDataTablesEndDate(): string {
        const morning: string = getDateFormatted({ date: queryData.start, hour: 17 });
        const evening: string = getUtcDateFormatted(moment(queryData.start).clone().add(1, "day").startOf("day"));

        if (isBrowsingAllDay) {
            return isBrowsingAllDayMorning ? morning : evening;
        }
        if (isPrinting) {
            return evening;
        }
        return queryData.end;
    }

    return {
        dailyMessagePrintable,
        isErrorDailyMessagePrintable,
        isErrorTables,
        isErrorTablesTimeRanges,
        isLoadingTables,
        onSelectedTablesChange,
        onTablesStateChange,
        selectedTableIds,
        tablesState,
        tablesTimeRanges,
    };
}