import { ReactElement } from "react";

export function SearchIcon(): ReactElement {
    return (
        <svg data-name="Group 1259" height="18.825" id="Group_1259" viewBox="0 0 36.592 37.649" width="18.296" xmlns="http://www.w3.org/2000/svg">
            <g data-name="Ellipse 78" fill="none" id="Ellipse_78" strokeWidth="3">
                <circle cx="15.317" cy="15.317" r="15.317" stroke="none"/>
                <circle cx="15.317" cy="15.317" fill="none" r="13.817"/>
            </g>
            <line data-name="Line 27" fill="none" id="Line_27" strokeLinecap="round" strokeWidth="3" transform="translate(25.412 25.412)" x2="9.062" y2="10.119"/>
        </svg>
    );
}
