import { COLORS } from "ui/variables";

import { Reservation } from "./Reservation";

export enum Status {
    BOOKED,
    INACTIVE,
    SELECTED
}

export const HIGHLIGHT_COLOR = {
    [Status.BOOKED]: COLORS.booked,
    [Status.INACTIVE]: COLORS.inactive,
    [Status.SELECTED]: COLORS.selected,
};

export interface Table {
    id: string,
    number: number
    reservations?: Array<Reservation>
}

export interface TableState {
    id: string,
    reservations: Array<Reservation>
    status: Status,
    tableNum: number
}