import { Box, TableBody, Typography, capitalize } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import { ReactElement, useContext } from "react";
import { useTranslation } from "react-i18next";

import { Reservation } from "models/Reservation";
import { globalStyles } from "ui/globalStyles";

import { getHoursFormatted } from "../../helpers/format";
import { ReservationsContext } from "../Reservations";

const HEADERS = [ "modules.reservations.hours", "modules.reservations.nameAndSurname", "modules.reservations.amountOfPeople", "modules.reservations.phone", "modules.reservations.instagram", "modules.reservations.tables", "modules.reservations.isConfirmed" ];
const ROWS_DATA = [ "timeRange", "name", "amountOfPeople", "phone", "instagram", "tables", "isConfirmed" ];

export default function ReservationsList(): ReactElement {
    const { t } = useTranslation();
    const { reservationsControl: { tablesState } } = useContext(ReservationsContext);

    const reservations = tablesState?.reduce((reservationsAcc, next) =>
        [ ...reservationsAcc, ...next.reservations.reduce((acc, next) => {
            if (reservationsAcc.some(reservation => reservation.id === next.id)) {
                return acc;
            }
            return [ ...acc, { ...next, tables: tablesState.filter(({ reservations }) => reservations.some(({ id }) => id === next.id)).map(({ id, tableNum }) => ({ id, number: tableNum })) } ];
        }, [] as Array<Reservation>) ]
    , [] as Array<Reservation>)
        .sort((a, b) => {
            const startDiff: number = moment(a.start).diff(b.start);
            if (startDiff === 0) {
                return moment(a.end).diff(b.end);
            }
            return startDiff;
        });

    function getTableCellCommonStyles(isBold: boolean, isName?: boolean): Record<string, string | number> {
        return { borderBottom: "none", fontSize: 14, fontWeight: isBold ? "bold" : "normal", maxWidth: "300px", pl: 0, pr: isName ? 2 : 0 };
    }

    if (!reservations?.length) {
        return <></>;
    }

    return (
        <Box sx={{ mt: 6, pageBreakBefore: "always", width: "calc(100% - 40px)" }}>
            <Typography sx={{ fontSize: 24 }}>
                {capitalize(`${ t("modules.reservations.reservationsList") }:`)}
            </Typography>
            <TableContainer component={Paper} sx={{ border: globalStyles.border, borderRadius: 0, boxShadow: "none", mt: 1, px: 2 }}>
                <Table>
                    <TableHead sx={{ borderBottom: globalStyles.border }}>
                        <TableRow>
                            {HEADERS.map((header) =>
                                <TableCell key={header} align="left" sx={getTableCellCommonStyles(true)}>
                                    {capitalize(t(header))}
                                </TableCell>,
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reservations.map(reservation =>
                            <TableRow key={reservation.id}>
                                {ROWS_DATA.map((rowData, index) => {
                                    function getContent(): string | number | null | undefined {
                                        const value: string | number | boolean | null | undefined = reservation[rowData as keyof typeof reservation] as string | number | boolean | null | undefined;

                                        if (rowData === "tables") {
                                            return reservation.tables.map(({ number }) => number).join(", ");
                                        }
                                        if (rowData === "timeRange") {
                                            return getHoursFormatted(reservation.start, reservation.end);
                                        }
                                        if (typeof value === "boolean") {
                                            return t(`common.${ value ? "yes" : "no" }`);
                                        }
                                        return value;
                                    }

                                    return <TableCell key={rowData} align="left" sx={getTableCellCommonStyles(!index, rowData === "name")}>
                                        {getContent()}
                                    </TableCell>;
                                })}
                            </TableRow>,
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}