import { Typography } from "@mui/material";
import { ReactElement, useContext } from "react";
import { useTranslation } from "react-i18next";

import { LOCALE } from "config/constants";
import { HandlerType } from "models/Date";
import { Button } from "ui/components/Button";
import { globalStyles } from "ui/globalStyles";
import { COLORS } from "ui/variables";

import { ReservationsContext } from "../../Reservations";


interface DateProps {
    handlerType: HandlerType,
}


export function Date({ handlerType }: DateProps): ReactElement {
    const { t } = useTranslation();
    const { datePanelControl: { handlerType: selectedHandlerType, onHandlerTypeChange, selectedStartDate } } = useContext(ReservationsContext);

    const isDayFormat = handlerType === HandlerType.DATE;
    const isSelected = handlerType === selectedHandlerType;

    const borderLeft = !isDayFormat ? "none" : globalStyles.border;

    return (
        <Button sx={{
            "& > *":
                { userSelect: "none" },
            "&:hover": {
                bgcolor: COLORS.selected,
                border: globalStyles.border,
                borderLeft,

            },
            alignItems: "center",
            bgcolor: isSelected ? COLORS.selected : "unset",
            borderLeft,
            borderRadius: 0,
            boxSizing: "border-box",
            display: "flex",
            flexBasis: isDayFormat ? "70%" : "30%",
            flexDirection: "column",
            justifyContent: "center",
        }}
        onClick={() => onHandlerTypeChange(handlerType)}
        >
            <Typography sx={{ fontWeight: 600 }}>{t(isDayFormat ? "modules.reservations.date" : "modules.reservations.hour").toUpperCase()}</Typography>
            <Typography variant="h2">{selectedStartDate.locale(LOCALE).format(isDayFormat ? "ddd DD.MM.YYYY" : "HH:mm")}</Typography>
        </Button>
    );
}