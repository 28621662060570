import { Box } from "@mui/material";
import { ReactElement } from "react";


import { DateControl } from "./dateControl/DateControl";
import { Info } from "./dateInfo/Info";


export function DatePanel(): ReactElement {
    return (
        <Box sx={{ position: "relative" }}>
            <Info/>
            <DateControl/>
        </Box>
    );
}