import { Typography } from "@mui/material";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

export function NotFound(): ReactElement {
    const { t } = useTranslation();

    return <Typography
        sx={{ ml: 3 }}
        variant="h1"
    >
        {(t("common.404"))}
    </Typography>;
}
