import * as yup from "yup";

import { LoginValues } from "models/Auth";

export function getLoginValidationSchema(
    isPasswordForgotten: boolean,
): yup.ObjectSchema<Partial<LoginValues>> {
    return yup.object({
        email: isPasswordForgotten
            ? yup.string().email("modules.auth.invalidEmail").required("common.required")
            : yup.string().optional(),
        password: !isPasswordForgotten ? yup.string().required("common.required") : yup.string().optional(),
        user: !isPasswordForgotten ? yup.string().email("modules.auth.invalidEmail").required("common.required") : yup.string().optional(),
    });
}
