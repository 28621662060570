import { Box, TextField, Typography, capitalize } from "@mui/material";
import { ChangeEvent, ReactElement, useContext } from "react";
import { useTranslation } from "react-i18next";

import { globalStyles } from "ui/globalStyles";

import { ReservationsContext } from "../Reservations";


export function SearchEngine(): ReactElement {
    const { t } = useTranslation();
    const { reservationsControl: { onSearchChange, search } } = useContext(ReservationsContext);

    return (
        <Box sx={globalStyles.sidePanelControlWrapper}>
            <Typography sx={{ fontWeight: "bold", mt: 1 }} variant="h3">{t("modules.reservations.searchReservation").toUpperCase()}</Typography>
            <TextField
                helperText={capitalize(t("modules.reservations.searchHelperText"))}
                inputProps={{ style: { fontSize: 14.4 } }}
                sx={{ mt: 2, pb: 1, ...globalStyles.textInput }}
                value={search.query}
                variant="standard"
                onChange={(e: ChangeEvent<HTMLInputElement>) => onSearchChange("query", e.target.value)}
            />
        </Box>
    );
}