import { Typography } from "@mui/material";
import { ReactElement, useContext } from "react";

import { ReservationsContext } from "../Reservations";


export function DailyMessage(): ReactElement {
    const { reservationsControl: { dailyMessagePrintable } } = useContext(ReservationsContext);

    if (!dailyMessagePrintable?.context) {
        return <></>;
    }

    return (
        <Typography sx={{ mt: 1 }}>
            {dailyMessagePrintable?.context}
        </Typography>
    );
}