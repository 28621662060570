import { httpService } from "common/services";
import { API_URL } from "config/constants";
import { Mode } from "models/Mode";
import { TimeRange } from "models/Reservation";
import { Table } from "models/Table";


export async function fetchTables(data: TimeRange): Promise<Array<Table> | undefined> {
    const res = await httpService.request({ data, method: "POST", url: `${ API_URL }tables` });
    return res.data as Array<Table>;
}

export async function getTablesTimeRanges(data: { excludedReservation?: string, mode: Mode, start: string, tableIds: Array<string> }): Promise<Array<TimeRange> | undefined> {
    const { mode, ...rest } = data;
    if ([ Mode.BROWSING_ALL_DAY, Mode.SEARCHING, Mode.REPORT_BROWSING, Mode.RESERVATION_PREVIEW_FROM_SEARCH, Mode.BROWSING, Mode.RESERVATION_PREVIEW, Mode.PRINTING ].some(m => mode === m)) {
        return;
    }
    const res = await httpService.request({ data: rest, method: "POST", url: `${ API_URL }tables-time-ranges` });
    return res.data as Array<TimeRange>;
}