import jwtDecode from "jwt-decode";
import moment, { Moment, unix } from "moment";


export class TokenService {
    public isExpired(token: string): boolean {
        try {
            const decoded: Record<string, number> = jwtDecode(token);
            const exp: Moment = unix(decoded.exp);
            return moment().isAfter(exp.clone().subtract(1, "minute"));
        } catch {
            return true;
        }
    }
}
