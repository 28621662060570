interface StorageServiceInterface {
    key: string;
    value: string;
}

export class StorageService {
    public setItem({ key, value }: StorageServiceInterface): void {
        localStorage.setItem(key, value);
    }

    public getItem(key: string): string | null {
        return localStorage.getItem(key);
    }

    public removeItem(key: string): void {
        localStorage.removeItem(key);
    }
}
