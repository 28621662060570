import { Box, Typography, capitalize } from "@mui/material";
import { ReactElement, useContext } from "react";
import { useTranslation } from "react-i18next";

import { BrowsingAllDayTime, Mode } from "models/Mode";
import { Button } from "ui/components/Button";
import { globalStyles } from "ui/globalStyles";
import { COLORS } from "ui/variables";

import { ReservationsContext } from "../Reservations";
import { SearchIcon } from "../search/SearchIcon";

import { useTopControls } from "./useTopControls";


export function TopControls(): ReactElement {
    const { t } = useTranslation();
    const { reservationsControl: { browsingAllDayTime, mode, onModeChange } } = useContext(ReservationsContext);
    const { handleModeChange } = useTopControls();

    const isBrowsingAllDay = mode === Mode.BROWSING_ALL_DAY;
    const isBrowsingAllDayMorning = isBrowsingAllDay && browsingAllDayTime === BrowsingAllDayTime.MORNING;
    const isBrowsingAllDayEvening = isBrowsingAllDay && browsingAllDayTime === BrowsingAllDayTime.EVENING;
    const isSearching = mode === Mode.SEARCHING;
    const isReportBrowsing = mode === Mode.REPORT_BROWSING;
    const isDailyMessageEdit = mode === Mode.DAILY_MESSAGE_EDIT;

    return (
        <Box sx={{ "@media print": { display: "none" }, position: "absolute", right: 141.6, top: 7.5 }}>
            <Button
                sx={{
                    ...globalStyles.buttonBlack,
                    ...globalStyles.topMenuButton,
                    bgcolor: COLORS[isSearching ? "selected" : "black"],
                    color: COLORS[isSearching ? "black" : "white"],
                    justifyContent: "center",
                    minWidth: 135,
                    mr: 2.7,
                    px: 2,
                    svg: { stroke: COLORS[isSearching ? "black" : "white"], transition: "stroke 200ms cubic-bezier(0.4, 0, 0.2,1) 0ms" },
                }}
                onClick={() => {
                    if (isSearching) {
                        return onModeChange(Mode.BROWSING);
                    }
                    handleModeChange(Mode.SEARCHING);
                }}
            >
                <Box
                    sx={{ alignItems: "center", display: "flex", justifyContent: "space-between", width: "100%" }}
                >
                    <SearchIcon/>
                    <Typography sx={{ fontSize: 17.5, fontWeight: "bold" }}>
                        {capitalize(t(isSearching ? "common.close" : "modules.reservations.search"))}
                    </Typography>
                </Box>
            </Button>
            {[
                {
                    activeText: "modules.reservations.hideAllDayMorning",
                    browsingAllDayTime: BrowsingAllDayTime.MORNING,
                    inActiveText: "modules.reservations.showAllDayMorning",
                    isActive: isBrowsingAllDayMorning,
                },
                {
                    activeText: "modules.reservations.hideAllDayEvening",
                    browsingAllDayTime: BrowsingAllDayTime.EVENING,
                    inActiveText: "modules.reservations.showAllDayEvening",
                    isActive: isBrowsingAllDayEvening,

                },
            ].map(({ activeText, browsingAllDayTime, inActiveText, isActive }) =>
                <Button
                    key={browsingAllDayTime}
                    sx={{ ...globalStyles.topMenuButton, bgcolor: isActive ? COLORS.selected : undefined, minWidth: 154, mr: 2.7 }}
                    onClick={() => {
                        if (isActive) {
                            return onModeChange(Mode.BROWSING);
                        }
                        handleModeChange(Mode.BROWSING_ALL_DAY, browsingAllDayTime);
                    }}>
                    {capitalize(t(isActive ? activeText : inActiveText))}
                </Button>,
            )}
            <Button
                sx={{ ...globalStyles.topMenuButton, bgcolor: isReportBrowsing ? COLORS.selected : undefined, mr: 2.7 }}
                onClick={() => {
                    if (isReportBrowsing) {
                        return onModeChange(Mode.BROWSING);
                    }
                    handleModeChange(Mode.REPORT_BROWSING);
                }}
            >
                {capitalize(t("modules.reservations.report.title"))}
            </Button>
            <Button
                sx={{ ...globalStyles.topMenuButton, bgcolor: isDailyMessageEdit ? COLORS.selected : undefined, mr: 2.7 }}
                onClick={() => {
                    if (isDailyMessageEdit) {
                        return onModeChange(Mode.BROWSING);
                    }
                    handleModeChange(Mode.DAILY_MESSAGE_EDIT);
                }}
            >
                {capitalize(t("modules.reservations.dailyMessage.title"))}
            </Button>
            <Button
                sx={globalStyles.topMenuButton}
                onClick={() => handleModeChange(Mode.PRINTING)}
            >
                {capitalize(t("modules.reservations.print"))}
            </Button>
        </Box>

    );
}
