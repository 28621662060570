import { Table } from "./Table";

export enum ReservationIcons {
    IN_GARDEN = "♣︎",
    IS_FIRST_TIME = "★",
    IS_REGULAR_GUEST = "♥",
    THIS_TABLE = "◾"
}

export interface Reservation {
    amountOfPeople: number,
    createdAt: string,
    end: string,
    id: string,
    inGarden: boolean
    instagram?: string | null,
    isConfirmed: boolean
    isFirstTime: boolean,
    isRegularGuest: boolean,
    message: string | null | undefined,
    name?: string | null,
    phone: string | null | undefined,
    showable?: boolean,
    start: string,
    tables: Array<Table>,
    thisTable: boolean,
    updatedAt: string
}

export type ReservationInput = Omit<Reservation, "createdAt" | "id" | "updatedAt" | "tables" | "showable"> & { tables: Array<string>, tablesNums: string }

export interface TimeRange {
    end: string,
    start: string
}

export const reservationDefaultValues: ReservationInput = {
    amountOfPeople: 1,
    end: "",
    inGarden: false,
    instagram: null,
    isConfirmed: false,
    isFirstTime: false,
    isRegularGuest: false,
    message: null,
    name: "",
    phone: null,
    start: "",
    tables: [],
    tablesNums: "",
    thisTable: false,
};