import { Box, Typography, capitalize } from "@mui/material";
import { PropsWithChildren, ReactElement } from "react";
import { useTranslation } from "react-i18next";


export function AuthWrapper({ children }: PropsWithChildren): ReactElement {
    const { t } = useTranslation();

    return (
        <Box sx={{ alignItems: "center", display: "flex", flexDirection: "column", mt: 3, mx: "auto", width: "40vw" }}>
            <Box sx={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
                <Typography variant="h2">{capitalize(t("common.reservationSystem"))}</Typography>
                <Typography sx={{ fontWeight: "bold" }} variant="h2">
                    {t("common.companyName")}
                </Typography>
            </Box>

            <Box sx={{ mt: 3, width: "60%" }}>
                {children}
            </Box>
        </Box>
    );
}
