import { httpService } from "common/services";
import { API_URL } from "config/constants";
import { DailyMessage, DailyMessageInput } from "models/DailyMessage";

export async function postDailyMessage(data: DailyMessageInput): Promise<DailyMessage | undefined | null> {
    const res = await httpService.request({ data, method: "POST", url: `${ API_URL }daily-note` });
    return res.data;
}

export async function getDailyMessage(date: string): Promise<DailyMessage | undefined | null> {
    const res = await httpService.request({ method: "GET", url: `${ API_URL }daily-note/${ date }` });
    return res.data;
}

export async function editDailyMessage(data: DailyMessageInput & {id: string}): Promise<DailyMessage | undefined | null> {
    const res = await httpService.request({ data, method: "PUT", url: `${ API_URL }daily-note/${ data.id }` });
    return res.data;
}
