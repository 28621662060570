import { Box, ListItemText, Typography, capitalize } from "@mui/material";
import moment from "moment";
import { ReactElement, useContext } from "react";
import { useTranslation } from "react-i18next";

import { Reservation, ReservationIcons } from "models/Reservation";
import { List } from "ui/components/list/List";
import { Pagination } from "ui/components/list/Pagination";
import { globalStyles } from "ui/globalStyles";

import { getHoursFormatted } from "../../helpers/format";
import { ReservationsContext } from "../Reservations";

import { SearchEngine } from "./SearchEngine";
import { useSearch } from "./useSearch";


const HEADERS = [ "modules.reservations.personalData", "modules.reservations.date", "modules.reservations.amountOfPeople", "modules.reservations.hours" ];

export function Search(): ReactElement {
    const { t } = useTranslation();
    const { reservationsControl: { isErrorReservation, isFetchingReservations, onSearchChange, reservations, search } } = useContext(ReservationsContext);
    const { onReservationClick } = useSearch();

    function renderRow(reservation: Reservation): ReactElement {
        return (
            <>
                {([ "name", "start", "amountOfPeople" ] as const).map((key, index) => {
                    function getValue(): string | number | null | undefined {
                        const value: string | number | null | undefined = reservation[key];
                        if (key === "start") {
                            return moment(reservation[key]).format("DD.MM.YYYY");
                        }
                        if (key === "name") {
                            return `${ reservation.isFirstTime ? `${ ReservationIcons.IS_FIRST_TIME } ` : "" }${ reservation.thisTable ? `${ ReservationIcons.THIS_TABLE } ` : "" }${ reservation.inGarden ? `${ ReservationIcons.IN_GARDEN } ` : "" }${ reservation.isRegularGuest ? `${ ReservationIcons.IS_REGULAR_GUEST } ` : "" }${ key === "name" ? (value as string).toUpperCase() : value }`;
                        }
                        return value;
                    }

                    return <ListItemText key={index} sx={{ "& .MuiTypography-root": key === "name" ? { textAlign: "left !important" } : {} }}>
                        <Typography sx={{ fontWeight: key === "name" ? "bold" : "normal" }}>
                            {getValue()}
                        </Typography>
                        {key === "name" && reservation.instagram &&
                            <Typography sx={{ fontWeight: "bold" }}>
                            @{reservation.instagram}
                            </Typography>

                        }
                    </ListItemText>; },
                )}
                <ListItemText>
                    <Typography>{getHoursFormatted(reservation.start, reservation.end)}</Typography>
                </ListItemText>
            </>
        );
    }

    return (
        <Box
            sx={globalStyles.sidePanelWrapper}
        >
            <SearchEngine />
            {isErrorReservation ?
                <Typography sx={{ alignSelf: "center", color: "error.main", mt: 3 }}>{capitalize(t("modules.reservations.fetchError"))}</Typography>
                :
                <Box sx={{ border: globalStyles.border, borderTop: "none", flexGrow: 1, maxHeight: "100%", overflow: "scroll" }}>
                    <List
                        data={reservations?.entities}
                        headers={HEADERS}
                        isLoading={isFetchingReservations}
                        renderItem={renderRow}
                        onClick={onReservationClick}
                    />

                    {typeof reservations !== "undefined" &&
                        <Pagination
                            count={reservations.page.count}
                            page={search.page}
                            onPageChange={(page) => onSearchChange("page", page)}
                        />
                    }
                </Box>
            }
        </Box>
    );
}