import { Box } from "@mui/material";
import { PropsWithChildren, ReactElement } from "react";
import { Outlet } from "react-router";


import { MainMenu } from "app/components/MainMenu";

import { Header } from "./Header";


export interface RootProps {
    isLoggedIn: boolean
}

export function Root({ children, isLoggedIn }: PropsWithChildren<RootProps>): ReactElement {
    return (
        <Box sx={{ "@media print": { height: "unset" }, display: "flex", flexDirection: "column", height: "100vh" }}>
            <Header>
                {isLoggedIn && <MainMenu/>}
            </Header>
            {children}
            <Outlet />
        </Box>
    );
}
