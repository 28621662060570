import { SxProps } from "@mui/material";
import { DatePicker as DatePickerMui } from "@mui/x-date-pickers";
import { Moment } from "moment";
import { ReactElement } from "react";

import { globalStyles } from "../../globalStyles";
import { COLORS } from "../../variables";

import { getDayFormatted } from "./helpers";


interface DatePickerProps {
    maxDate?: Moment,
    minDate?: Moment,
    onBlur?: () => void,
    onChange: (value: Moment | null) => void
    sx?: SxProps,
    value: Moment | null
}

export function DatePicker({ maxDate, minDate, onBlur, onChange, sx, value }: DatePickerProps): ReactElement {
    return (
        <DatePickerMui
            maxDate={maxDate}
            minDate={minDate}
            slotProps={{
                field: { clearable: true },
                layout: { sx: globalStyles.calendarLayout },
                popper: {
                    sx: {
                        ...globalStyles.calendarDay,
                        ".MuiPaper-root": {
                            bgcolor: COLORS.main,
                            border: globalStyles.border,
                            borderRadius: 0,
                            mx: -0.25,
                        },
                        ".MuiPickersDay-root": { fontSize: 14.4 },
                    },
                },
                textField: { onBlur },
            }}
            slots={{ day: getDayFormatted }}
            sx={{
                "& .MuiButtonBase-root": { color: COLORS.black },
                "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                "& .clearButton": { fontWeight: "bold", opacity: 1, position: "absolute", right: 35, top: 8.8 },
                "& .clearButton svg": { stroke: COLORS.black, strokeWidth: "2px" },
                "& input": { color: COLORS.black, fontSize: 14.4 },
                border: globalStyles.border,
                ...sx,
            }}
            value={value}
            onChange={onChange}
        />
    );
}