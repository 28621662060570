import { useContext } from "react";
import { useQueryClient } from "react-query";

import { BrowsingAllDayTime, Mode } from "models/Mode";
import { Status } from "models/Table";

import { ReservationsContext } from "../Reservations";

interface UseTopControlsReturn {
    handleModeChange: (modeToBeSet: Mode, browsingAllDayTime?: BrowsingAllDayTime) => void
}


export function useTopControls(): UseTopControlsReturn {
    const queryClient = useQueryClient();
    const { reservationsControl: { isFormDirty, mode, onBrowsingAllDayTimeChange, onExitFormWarningChange, onModeChange, onPreviousModeChange, onReservationIdChange, onTablesStateChange, tablesState } } = useContext(ReservationsContext);

    function clearMap(previousMode: Mode, modeToBeSet: Mode): void {
        onTablesStateChange(tablesState?.map(tableState => ({ ...tableState, reservations: modeToBeSet === Mode.PRINTING && previousMode === Mode.BROWSING_ALL_DAY ? tableState.reservations : [], status: Status.INACTIVE })) ?? []);
    }

    function handleModeChange(modeToBeSet: Mode, browsingAllDayTime?: BrowsingAllDayTime): void {
        onPreviousModeChange(mode);
        if (modeToBeSet === Mode.BROWSING_ALL_DAY && typeof browsingAllDayTime !== "undefined") {
            onBrowsingAllDayTimeChange(browsingAllDayTime);
        }

        if (isFormDirty && [ Mode.RESERVATION_CREATION, Mode.RESERVATION_EDIT, Mode.DAILY_MESSAGE_EDIT ].some(m => m === mode)) {
            return onExitFormWarningChange({ modeToBeSet, show: true });
        }

        if (modeToBeSet !== Mode.DAILY_MESSAGE_EDIT) {
            clearMap(mode, modeToBeSet);
        } else {
            queryClient.removeQueries({ queryKey: "tables" });
        }

        onModeChange(modeToBeSet);
        if ([ Mode.BROWSING_ALL_DAY, Mode.SEARCHING, Mode.REPORT_BROWSING, Mode.PRINTING, Mode.DAILY_MESSAGE_EDIT ].some(mode => mode === modeToBeSet)) {
            onReservationIdChange({ id: undefined, shouldRefetch: false });
        }
    }

    return { handleModeChange };
}
