import { useEffect, useState } from "react";

import { AuthStatus } from "models/Auth";

import * as controller from "../controllers/auth";

export function useAuthStatus(): AuthStatus | undefined {
    const [ authStatus, setAuthStatus ] = useState<AuthStatus | undefined>();
    const tempToken: string | null = new URLSearchParams(window.location.search).get("token");

    useEffect(() => {
        async function handleAuthStatus() {
            setAuthStatus(await controller.getAuthStatus(tempToken));
        }

        handleAuthStatus();
    }, [ tempToken ]);

    return authStatus;
}