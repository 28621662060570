import { yupResolver } from "@hookform/resolvers/yup";
import { useContext, useEffect } from "react";
import { UseFormReturn, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { DailyMessageInput } from "models/DailyMessage";
import { Mode } from "models/Mode";
import { Status } from "models/Table";

import * as repository from "../../repositories/dailyMessage";
import { dailyMessageFormValidationSchema } from "../../validators/dailyMessageFormValidationSchema";
import { ReservationsContext } from "../Reservations";

interface UseDailyMessageReturn {
    form: UseFormReturn<Omit<DailyMessageInput, "date">>,
    isErrorFetching: boolean,
    isErrorSaving: boolean,
    isFetching: boolean
    isFormDirty: boolean,
    isLoadingSaving: boolean,
    onExit: () => void,
    onSubmit: (data: Omit<DailyMessageInput, "date">) => void
}

export function useDailyMessage(): UseDailyMessageReturn {
    const { datePanelControl: { selectedStartDate }, reservationsControl: { exitFormWarning, onExitFormWarningChange, onFormDirtyChange, onModeChange, onReservationIdChange, onTablesStateChange, tablesState } } = useContext(ReservationsContext);
    const queryClient = useQueryClient();

    const form = useForm({
        defaultValues: { context: "" },
        resolver: yupResolver(dailyMessageFormValidationSchema),
    });

    const date = selectedStartDate.format("YYYY-MM-DD");

    const { data: dailyMessage, isError: isErrorFetching, isFetching } = useQuery({
        queryFn: () => repository.getDailyMessage(date),
        queryKey: [ "dailyMessage", date ],
        refetchOnWindowFocus: false,
    });

    const { isError: isErrorCreation, isLoading: isLoadingCreation, mutate: mutateCreation } = useMutation({
        mutationFn: repository.postDailyMessage,
        onSuccess: onExit,
    });

    const { isError: isErrorEdit, isLoading: isLoadingEdit, mutate: mutateEdit } = useMutation({
        mutationFn: repository.editDailyMessage,
        onSuccess: onExit,
    });

    const isFormDirty = !!Object.keys(form.formState.dirtyFields).length;
    const isEdit = !!dailyMessage;

    useEffect(() => {
        form.setValue("context", dailyMessage?.context ?? "");
    }, [ dailyMessage ]);

    useEffect(() => {
        onFormDirtyChange(isFormDirty);
    }, [ isFormDirty ]);

    function onSubmit(data: Omit<DailyMessageInput, "date">): void {
        const context = data.context as string;

        if (!isEdit) {
            return mutateCreation({ context, date });
        }
        mutateEdit({ context, date, id: dailyMessage.id });
    }

    function onExit(): void {
        if (exitFormWarning.show) {
            if (exitFormWarning.modeToBeSet === Mode.RESERVATION_PREVIEW) {
                onReservationIdChange({ id: exitFormWarning.reservationId, shouldRefetch: true });
            }
            if (exitFormWarning.modeToBeSet === Mode.RESERVATION_CREATION) {
                const { tableNum } = exitFormWarning.tableState ?? {};
                onTablesStateChange(tablesState?.map(tableState => ({ ...tableState, status: tableState.tableNum === tableNum ? Status.SELECTED : tableState.reservations.length ? Status.BOOKED : Status.INACTIVE })) ?? []);
            }
            onModeChange(exitFormWarning.modeToBeSet);
        } else {
            onModeChange(Mode.BROWSING);
        }

        queryClient.removeQueries({ queryKey: "dailyMessage" });
        queryClient.removeQueries({ queryKey: "tables" });
        onExitFormWarningChange({ modeToBeSet: Mode.BROWSING, show: false });
    }

    return {
        form,
        isErrorFetching,
        isErrorSaving: isErrorCreation || isErrorEdit,
        isFetching,
        isFormDirty,
        isLoadingSaving: isLoadingCreation || isLoadingEdit,
        onExit,
        onSubmit,
    };
}
