import { Box, Pagination as PaginationMui, PaginationItem } from "@mui/material";
import { ReactElement } from "react";

import { COLORS } from "../../variables";


interface PaginationProps {
    count: number
    onPageChange?: (page: number) => void
    page: number
}

export function Pagination({ count, onPageChange, page }: PaginationProps): ReactElement {
    return (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
            <PaginationMui
                count={Math.ceil(count / 10)}
                page={page}
                renderItem={(item) => <PaginationItem {...item} page={(item.page ?? 0) < 10 ? `0${ item.page }` : item.page} />}
                sx={{
                    "& .Mui-selected": { backgroundColor: "transparent !important", fontWeight: 700, mt: "-1px", textDecoration: `underline ${ COLORS.black } 2px` },
                    "& button": { fontSize: 12, fontWeight: 600 },
                    "& svg": {
                        stroke: COLORS.black,
                        strokeWidth: "2px",
                    },
                }}
                onChange={(_, page) => onPageChange?.(page)}
            />
        </Box>
    );
}