import { Box } from "@mui/material";
import { PropsWithChildren, ReactElement } from "react";

import { Logo } from "./Logo";

export function Header({ children }: PropsWithChildren): ReactElement {
    return (
        <Box sx={{ alignItems: "center", display: "flex", height: 50, justifyContent: "space-between", minHeight: 50, pr: 1.4 }}>
            <Box sx={{ ml: 3.8 }}>
                <Logo />
            </Box>
            {children}
        </Box>
    );
}
