import { Moment } from "moment";

export enum ReportAggregationPeriod {
    DAY = "DAY"
}

export enum ReportPredefinedTimeRange {
    NONE,
    PREV_MONTH,
    PREV_WEEK
}

export type ReportTimeRange = {
    customRange: {
        from: Moment | null
        to: Moment | null
    }
    from: Moment | null
    isCustom: boolean,
    predefinedTimeRangeType: ReportPredefinedTimeRange,
    to: Moment | null
}

export type GetReportInput = {
    aggregation: string,
    from: string | null,
    to: string | null
}

export type ReportItem = {
    date: string,
    id: string,
    people: string
    reservations: string
}

export type Report = Array<ReportItem>