import { ReactElement } from "react";

import { Form } from "common/components/form/Form";

import { AuthWrapper } from "../AuthWrapper";

import { PasswordResetForm } from "./PasswordResetForm";
import { usePasswordReset } from "./usePasswordReset";


export function PasswordReset(): ReactElement {
    const { form, isError, isLoading, isSuccess, onSubmit } = usePasswordReset();


    return (
        <AuthWrapper>
            <Form form={form} onSubmit={onSubmit}>
                <PasswordResetForm isError={isError} isLoading={isLoading} isSuccess={isSuccess} />
            </Form>
        </AuthWrapper>
    );
}
