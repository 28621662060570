import { RouteObject, createBrowserRouter } from "react-router-dom";

import { AuthStatus } from "models/Auth";
import { Login } from "modules/auth/components/login/Login";

import { NotFound } from "../components/layout/NotFound";
import { Root } from "../components/layout/Root";

import { ROUTES_LOGGED_IN, ROUTES_LOGGED_OUT_AUTH, ROUTES_LOGGED_OUT_INAUTH } from "./routes";

function getRoutesConfig(authStatus: AuthStatus): RouteObject[] {
    const isLoggedIn: boolean = authStatus === AuthStatus.LOGGED_IN;
    const routes = {
        [AuthStatus.LOGGED_IN]: ROUTES_LOGGED_IN,
        [AuthStatus.LOGGED_OUT_AUTH]: ROUTES_LOGGED_OUT_AUTH,
        [AuthStatus.LOGGED_OUT_INAUTH]: ROUTES_LOGGED_OUT_INAUTH,
    }[authStatus];

    return [ {
        children: routes,
        element: <Root isLoggedIn={isLoggedIn} />,
        errorElement: <Root isLoggedIn={isLoggedIn}>{isLoggedIn ? <NotFound/> : <Login/>}</Root>,
    } ];
}

export function getRouter(authStatus: AuthStatus) {
    return createBrowserRouter(getRoutesConfig(authStatus));
}

